/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { Button, Card, Col, Form, Image, Input, Row, Space, Tree, Typography, Select, message, Dropdown, Modal, InputNumber } from 'antd'
import { CloseOutlined, SearchOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { DmsUpload, FormModal } from '../../../../components'
import { ApiService } from '../../../../services/ApiService'
import { Docs, Excel, Folder, Images, Pdf, Ppt } from '../../../../components/Folder';
import moment from 'moment'
import EmptyFolderImage from '../../../../assets/images/empty-folder.png'
import EmptyDocumentImage from '../../../../assets/images/empty-document.png'
import { role } from '../../../../utils/authData';
import { useDispatch, useSelector } from 'react-redux'
import {
    changeInputValue,
    addFolderModal,
    closeFolderModal,
    addParentFolderModal,
    shareFolderModal,
    closeShareFolderModal,
    uploadModal,
    closeUploadModal,
    addLocationModal,
    closeLocationdModal,
    renameFolderModal,
    renameFileModal,
    closeRenameModal,
    selectTree,
    selectFile,
    clearSearchReducer,
    changeIsFileStatus,
    changeIsSaving,
    changeIsSavingFile,
    searchFileManagerReducer,
    closeParentFolderModal,
    fetchNestedTree
} from "../../../../redux/slice/fileManagerSlice";
import { ModalAddLocation, ModalFolder, ModalParentFolder, ModalRename, ModalShareFolder } from "./Modal/index"

const { DirectoryTree } = Tree;
const { Text } = Typography;
const { confirm } = Modal;
const fileUrl = process.env.REACT_APP_FILE_URL;

const MyFolder = () => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const user = useSelector((state) => state.global.user)
    const modalUploadTitle = useSelector((state) => state.fileManager.modalUploadTitle)
    const isModalVisibleFormFolder = useSelector((state) => state.fileManager.isModalVisibleFormFolder)
    const isModalParentFolder = useSelector((state) => state.fileManager.isModalParentFolder)
    const isModalUploadDocument = useSelector((state) => state.fileManager.isModalUploadDocument)
    const isModalAddLocationDocument = useSelector((state) => state.fileManager.isModalAddLocationDocument)
    const isModalRename = useSelector((state) => state.fileManager.isModalRename)
    const isModalShareFolder = useSelector((state) => state.fileManager.isModalShareFolder)
    const isUpdateFolder = useSelector((state) => state.fileManager.isUpdateFolder)
    const fileEkstension = useSelector((state) => state.fileManager.fileEkstension)
    const fileNameUrl = useSelector((state) => state.fileManager.fileNameUrl)
    const fileId = useSelector((state) => state.fileManager.fileId)
    const parentId = useSelector((state) => state.fileManager.parentId)
    const isFile = useSelector((state) => state.fileManager.isFile)
    const resultSearch = useSelector((state) => state.fileManager.resultSearch)
    const isSavingFile = useSelector((state) => state.fileManager.isSavingFile)
    const loadedSearch = useSelector((state) => state.fileManager.loadedSearch)
    const loaded = useSelector((state) => state.fileManager.loaded)
    const stringValue = useSelector((state) => state.fileManager.stringValue)
    const resultFile = useSelector((state) => state.fileManager.resultFile)
    const nestedTree = useSelector((state) => state.fileManager.nestedTree)

    const [folder, setFolder] = useState([])
    const [inTree, setInTree] = useState([])
    const [attachmentFile, setAttachmentFile] = useState([])
    const [archiveLocation, setArchiveLocation] = useState([])
    const [division, setDivision] = useState([])
    const [uploading, setUploading] = useState(false)
    const [showModalMove, setShowModalMove] = useState(false)
    const [warning, setWarning] = useState(false);
    const specialChar = /[!@#$%^&*+=[\]{};':"\\|.<>/?]+/;

    // const fetchNestedTree = () => {
    //     // dispatch(changeLoaded(false))
    //     ApiService.getList('my-folder').then((res) => {
    //         console.log(res.data)
    //         // dispatch(changeNestedTree(res.data))
    //     })
    // }

    const fetchFolder = () => {
        ApiService.getObject('folder', user.id).then((res) => {
            const v = res.data
            let arr = []

            v.forEach((el) => {
                arr.push({ value: el.id, label: el.name })
            })

            setFolder(arr)
            // fetchNestedTree()
        })
    }

    const fetchInFolder = (v) => {
        let params = {
            role: role,
            user_id: user.id
        }
        ApiService.getObject('infolder', v, params).then((res) => {
            setInTree(res.data)
        })
    }

    const fetchArchiveLocation = () => {
        ApiService.getList('archive-location').then((res) => {
            const v = res.data
            let arr = []

            v.forEach((el) => {
                arr.push({ value: el.id, label: el.name })
            })

            setArchiveLocation(arr)
        })
    }

    const fetchDivision = () => {
        ApiService.getList('divisions').then((res) => {
            const v = res.data
            let arr = []

            v.forEach((el) => {
                arr.push({ value: el.division_id, label: el.division_name })
            })

            setDivision(arr)
        })
    }

    useEffect(() => {
        dispatch(fetchNestedTree())
        fetchFolder()
        fetchArchiveLocation()
        fetchDivision()

        // eslint-disable-next-line
    }, []);

    const onSelect = (selectedKeys, info) => {
        const v = info.node
        form.resetFields(['search_file']);
        form.setFieldsValue({ name: v.name })
        dispatch(selectTree(v))

        if (v.type === 'FOLDER') {
            fetchInFolder(v.root_id)
        }
    }

    const downloadFile = () => {
        let payload = {
            user_id: user.id,
            file_id: fileId,
            description: 'Access File',
            filename: fileNameUrl,
            document_from: 'WEB'
        }

        ApiService.store('file-log', payload).then((res) => {
            message.success(res.message)
            dispatch(changeIsSavingFile(false))
            window.open(`${fileUrl}/${fileNameUrl}`)
        }).catch(() => {
            message.warning('Failed save data')
        })
    }

    const searchFileManager = async () => {
        form.resetFields(['name']);
        ApiService.store('my-search-file', { search: stringValue }).then((res) => {
            dispatch(searchFileManagerReducer(res.data))
        })
    }

    const clearSearch = () => {
        dispatch(clearSearchReducer())
        form.resetFields(['search_file']);
    }

    const fileUploaded = (v) => {
        if (v.message === 'Success') {
            setAttachmentFile(v.response)
        } else {
            setAttachmentFile([])
        }
    }

    const uploadDms = (v) => {
        (dispatch(changeIsSavingFile(true)))
        let payload = {
            title: v.title,
            attachment_file: attachmentFile,
            document_origin: 'DMS',
            user_id: user.user_id,
            description: 'Upload File',
            document_from: 'WEB',
            pdf_split: v.pdf_split,
            archive_location_id: v.archive_location_id,
            folder_id: v.folder_id,
        }

        ApiService.store('document-smart', payload).then((res) => {
            message.success("Success")
            dispatch(changeIsSavingFile(false))
            window.location.reload();
        }).catch(() => {
            message.warning("Failed")
            dispatch(changeIsSavingFile(false))
        })
    }

    const deleteFile = () => {
        let params = {
            user: user.id,
            file_id: fileId,
            filename: fileNameUrl,
            document_from: 'WEB'
        }

        ApiService.destroy('document', fileId, params).then(() => {
            message.success('Success Delete File')
            fetchNestedTree()
            fetchFolder()
            fetchInFolder(parentId)
            dispatch(changeIsFileStatus(false))
        })
    }

    const showDelete = (v) => {
        let params = {
            filename: v.name,
            original_filename: v.original_filename,
            document_from: 'WEB'
        }

        confirm({
            title: 'Are you sure delete this document?',
            icon: <ExclamationCircleFilled />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                ApiService.destroy('document', v.file_origins_id, params).then(() => {
                    message.success('Success Delete File')
                    fetchNestedTree(false)
                    fetchFolder()
                    fetchInFolder(v.parent)
                    dispatch(changeIsFileStatus(false))
                }).catch((err) => {
                    message.warning('Failed Delete File')
                })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const showDeleteFolder = (v) => {
        confirm({
            title: 'Are you sure delete this folder?',
            icon: <ExclamationCircleFilled />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                ApiService.destroy('folder-parent', v.file_origins_id).then((res) => {
                    message.success(res.message)
                    fetchNestedTree(false)
                    fetchFolder()
                }).catch((err) => {
                    message.warning(err.response.data.message)
                })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const accesDoc = (v) => {
        window.open(`${fileUrl}/${v.name}`)
    }

    const onChangeTitle = (v) => {
        if (specialChar.test(v)) {
            setWarning(true)
            Modal.warning({
                title: 'Error',
                content: 'Title tidak boleh mengandung special karakter kecuali -  _',
            });
        } else {
            setWarning(false)
        }
    }

    const itemsFolder = (v) => {
        return (
            <div style={{ backgroundColor: 'white', padding: 5, borderRadius: 5, border: '1px solid #e8e8e8', width: 150 }}>
                {/* <Row className="mb-1">
                    <a href="#" className="a-link" onClick={() => showModalSmartUpload(true, v)} >
                        Upload
                    </a>
                </Row> */}
                {
                    <Row className="mb-1">
                        <a href="#" className="a-link" onClick={() => dispatch(addParentFolderModal(v.file_origins_id))} >
                            Add Folder
                        </a>
                    </Row>
                }
                {
                    <Row className="mb-1">
                        <a href="#" className="a-link" onClick={() => dispatch(shareFolderModal(v))} >
                            Share Folder
                        </a>
                    </Row>
                }
                {
                    <Row className="mb-1">
                        <a href="#" className="a-link" onClick={() => dispatch(renameFolderModal(v))} >
                            Rename
                        </a>
                    </Row>
                }
                {
                    <Row className="mb-1">
                        <a href="#" className="a-link" onClick={() => showDeleteFolder(v)} >
                            Delete
                        </a>
                    </Row>
                }
            </div>
        )
    }

    const items = (v) => {
        return (
            <div style={{ backgroundColor: 'white', padding: 5, borderRadius: 5, border: '1px solid #e8e8e8', width: 150 }}>
                {
                    <Row className="mb-1">
                        <a href="#" className="a-link" onClick={() => accesDoc(v)} >
                            Open New Tab
                        </a>
                    </Row>
                }
                {/* {
                    docAccess('DOWNLOAD_DOC') ?
                        <Row className="mb-1">
                            <a href="#" className="a-link" onClick={() => downloadFile({ name: v.name, original_filename: v.original_filename, id: v.file_origins_id })} >
                                Download
                            </a>
                        </Row> : null
                } */}
                {
                    <Row className="mb-1">
                        <a href="#" className="a-link" onClick={() => showDelete(v)} >
                            Delete
                        </a>
                    </Row>
                }
                {/* {
                    docAccess('MOVE_DOC') ?
                        <Row className="mb-1">
                            <a href="#" className="a-link" onClick={() => onShowMove(v)} >
                                Move
                            </a>
                        </Row> : null
                } */}
                {
                    <Row className="mb-1">
                        <a href="#" className="a-link" onClick={() => dispatch(renameFileModal(v))} >
                            Rename
                        </a>
                    </Row>
                }
            </div>
        )
    }

    const onFinishRename = (v) => {
        if (isUpdateFolder) {
            ApiService.update('rename-folder', v.id, { name: v.original_filename })
                .then((res) => {
                    message.success("Success Rename Folder")
                    dispatch(closeRenameModal())
                    fetchNestedTree(false)
                }).catch((err) => {
                    message.warning("Failed Rename Folder")
                })
        } else {
            ApiService.update('rename-document', v.id, { name: `${v.original_filename}${fileEkstension}` })
                .then((res) => {
                    message.success("Success Rename File")
                    dispatch(closeRenameModal())
                    fetchNestedTree(false)
                }).catch((err) => {
                    message.warning("Failed Rename File")
                })
        }
    }

    const onFinishMove = (v) => {
        ApiService.update('move-document', v.id, { folder_id: v.folder_id })
            .then((res) => {
                message.success(res.message)
                setShowModalMove(false)
                fetchNestedTree(false)
            }).catch((err) => {
                message.warning(err.response.data.message)
            })
    }

    const onFinishFolder = (el) => {
        let payload = {
            name: el.name,
            root_folder: el.root_folder,
            status: 1
        }

        Object.assign(payload, { created_by: user.id, updated_by: user.id })
        dispatch(changeIsSaving(true))
        ApiService.store(`folder`, payload).then((res) => {
            message.success("Success Add Folder", 2)
            dispatch(closeFolderModal())
            dispatch(changeIsSaving(false))
            fetchFolder()
        }).catch((err) => {
            message.warning('Failed add new Folder')
            dispatch(changeIsSaving(false))
        })
    }

    const onFinishFolderParent = (el) => {
        let payload = {
            root_id: el.root_id,
            name: el.name,
        }

        dispatch(changeIsSaving(true))
        ApiService.store(`folder-parent`, payload).then((res) => {
            message.success("Success Add Folder", 2)
            dispatch(closeParentFolderModal())
            dispatch(changeIsSaving(false))
            fetchFolder()
        }).catch((err) => {
            message.warning('Failed add new Folder')
            dispatch(changeIsSaving(false))
        })
    }

    const onFinishLocation = (v) => {
        let payload = {
            name: v.name.toUpperCase(),
            code: v.name.toUpperCase(),
            status: 1
        }
        ApiService.store(`archive-location`, payload)
            .then((res) => {
                message.success('Success', 2)
                dispatch(closeLocationdModal())
                fetchArchiveLocation()
            }).catch((err) => {
                message.warning('Failed')
            })
    }

    const onFinishShare = (v) => {
        let payload = {
            file_manager_id: v.file_manager_id,
            folder_id: v.folder_id,
            share_to_division: v.share_to_division.join()
        }

        ApiService.store(`folder-share`, payload)
            .then((res) => {
                message.success('Success Share', 2)
                dispatch(closeShareFolderModal())
                fetchFolder()
            }).catch((err) => {
                message.warning('Failed Share')
            })
    }

    return (
        <div>
            <Card style={{ borderRadius: 10 }} title={
                <Row justify='space-between'>
                    <Col>
                        <Text>My Folder</Text>
                    </Col>
                    <Col>
                        <Button type='primary' onClick={() => dispatch(uploadModal())}>+ Upload Document</Button>
                    </Col>
                </Row>
            }>
                <Row justify='space-between'>
                    <Col span={8}>
                        <Card title={
                            <div style={{ float: 'left' }}>
                                <Button onClick={() => dispatch(addFolderModal())} type='primary'>Add Folder</Button>
                            </div>
                        }>
                            {
                                loaded ?
                                    <DirectoryTree
                                        onSelect={onSelect}
                                        treeData={nestedTree}
                                        titleRender={(node) => {
                                            const { title, type } = node;
                                            return (
                                                <>
                                                    {
                                                        type === 'FILE' ?
                                                            <Dropdown overlay={items(node)} trigger={["contextMenu"]}>
                                                                <span>{title}</span>
                                                            </Dropdown>
                                                            :
                                                            type === 'FOLDER' && title !== 'My Folder' ?
                                                                <Dropdown overlay={itemsFolder(node)} trigger={["contextMenu"]}>
                                                                    <span>{title}</span>
                                                                </Dropdown>
                                                                : <span>{title}</span>
                                                    }
                                                </>
                                            )
                                        }}
                                    />
                                    : null
                            }
                        </Card>
                    </Col>
                    <Col span={15}>
                        <Card>
                            <div style={{ marginBottom: '15px' }}>
                                <Form form={form}>
                                    <Row justify='start'>
                                        <Col span={24}>
                                            <Form.Item name="search_file">
                                                <Input placeholder='Search My Folder...' onChange={(v) => dispatch(changeInputValue(v.target.value))} style={{ width: '100%', borderRadius: '10px' }} suffix=
                                                    {
                                                        <Space>
                                                            <Button type='text' icon={<CloseOutlined />} size="small" onClick={clearSearch} />
                                                            <Button onClick={searchFileManager} htmlType="submit" type="primary" shape="circle" icon={<SearchOutlined />} />
                                                        </Space>
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>

                            {
                                !isFile ?
                                    !resultSearch ?
                                        inTree.length !== 0 ?
                                            <Row>
                                                {
                                                    inTree.map((el) => (
                                                        <Col span={6}>
                                                            {
                                                                el.type === 'FOLDER' ?
                                                                    <Folder width={50} buttonHeight={70} onClick={() => fetchInFolder(el.root_id)} title={el.name} />
                                                                    :
                                                                    el.name.includes(".pdf") ?
                                                                        <Pdf width={50} buttonHeight={70} onClick={() => dispatch(selectFile({ name: el.name, id: el.file_origins_id }))} title={el.original_filename ?? `${el.name.split('/')[1].substring(0, 10)}...`} />
                                                                        :
                                                                        el.name.includes(".pptx") || el.name.includes(".ppt") ?
                                                                            <Ppt width={50} buttonHeight={70} onClick={() => dispatch(selectFile({ name: el.name, id: el.file_origins_id }))} title={el.original_filename ?? `${el.name.split('/')[1].substring(0, 10)}...`} />
                                                                            :
                                                                            el.name.includes(".docx") || el.name.includes(".doc") ?
                                                                                <Docs width={50} buttonHeight={70} onClick={() => dispatch(selectFile({ name: el.name, id: el.file_origins_id }))} title={el.original_filename ?? `${el.name.split('/')[1].substring(0, 10)}...`} />
                                                                                :
                                                                                el.name.includes(".xlsx") || el.name.includes(".xls") ?
                                                                                    <Excel width={50} buttonHeight={70} onClick={() => dispatch(selectFile({ name: el.name, id: el.file_origins_id }))} title={el.original_filename ?? `${el.name.split('/')[1].substring(0, 10)}...`} />
                                                                                    :
                                                                                    <Images width={50} buttonHeight={70} onClick={() => dispatch(selectFile({ name: el.name, id: el.file_origins_id }))} title={el.original_filename ?? `${el.name.split('/')[1].substring(0, 10)}...`} />
                                                            }
                                                        </Col>
                                                    ))
                                                }
                                            </Row>
                                            :
                                            <div className='text-center'>
                                                <Image src={EmptyFolderImage} preview={false} width={150} />
                                                <p style={{ fontSize: 18, fontWeight: 'bold', color: 'black' }}>File not found</p>
                                            </div>
                                        :
                                        loadedSearch ?
                                            resultFile.length !== 0 ?
                                                <table className='table-custom'>
                                                    <thead>
                                                        <tr>
                                                            <th>Kind</th>
                                                            <th>Filename</th>
                                                            <th>Page</th>
                                                            <th>Created At</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            resultFile.map((el, key) => (
                                                                <tr key={key} onClick={() => window.open(`${fileUrl}/${el?.filename_document}`)}>
                                                                    <td>
                                                                        {
                                                                            el?.filename_document?.includes(".pdf") ?
                                                                                <Pdf width={30} buttonHeight={50} onClick={() => window.open(`${fileUrl}/${el?.filename_document}`)} />
                                                                                :
                                                                                el?.filename_document?.includes(".doc") || el?.filename_document?.includes(".docx") ?
                                                                                    <Docs width={30} buttonHeight={50} onClick={() => window.open(`${fileUrl}/${el?.filename_document}`)} /> :
                                                                                    el?.filename_document?.includes(".xls") || el?.filename_document?.includes(".xlsx") ?
                                                                                        <Excel width={30} buttonHeight={50} onClick={() => window.open(`${fileUrl}/${el?.filename_document}`)} /> :
                                                                                        el?.filename_document?.includes(".ppt") || el?.filename_document?.includes(".pptx") ?
                                                                                            <Ppt width={30} buttonHeight={50} onClick={() => window.open(`${fileUrl}/${el?.filename_document}`)} />
                                                                                            :
                                                                                            <Images width={30} buttonHeight={50}
                                                                                                onClick={() => window.open(`${fileUrl}/${el?.filename_document}`)} />
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {el?.title}
                                                                    </td>
                                                                    <td>
                                                                        {el?.page}
                                                                    </td>
                                                                    <td>
                                                                        {moment(el?.created_at).format('dddd, DD MMMM YYYY')}
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                                :
                                                <div className='text-center'>
                                                    <Image src={EmptyDocumentImage} preview={false} width={150} />
                                                    <p style={{ fontSize: 18, fontWeight: 'bold', color: 'black' }}>Document not found</p>
                                                </div>
                                            :
                                            null
                                    :
                                    <div>
                                        <Row justify='end' style={{ marginBottom: 20 }}>
                                            <Space>
                                                {
                                                    fileNameUrl.includes(".docx") || fileNameUrl.includes(".doc") ||
                                                        fileNameUrl.includes(".xlsx") || fileNameUrl.includes(".xls") ||
                                                        fileNameUrl.includes(".pptx") || fileNameUrl.includes(".ppt") ?
                                                        null :
                                                        <Button type="primary" onClick={downloadFile}>Download</Button>
                                                }
                                                <Button type="primary" danger onClick={deleteFile}>Delete</Button>
                                            </Space>
                                        </Row>

                                        {
                                            fileNameUrl.includes('.pdf') || fileNameUrl.includes('.jpg') || fileNameUrl.includes(".jpeg") || fileNameUrl.includes('.png') ?
                                                <Row>
                                                    <iframe src={`${fileUrl}/${fileNameUrl}`} width="730" height="950" title="Document Review" />
                                                </Row>
                                                :
                                                <Card style={{ borderRadius: 10 }}>
                                                    <Row justify='center' className='mb-3'>
                                                        <div className='text-center'>
                                                            <h1 style={{ fontSize: 22, fontWeight: 600 }}>Dokumen Tidak dapat Ditampilkan</h1>
                                                            <p>Download file untuk melihat dokumen</p>
                                                        </div>
                                                    </Row>
                                                    <Row justify='center'>
                                                        <div>
                                                            <Button type='primary' onClick={downloadFile}>Download</Button>
                                                        </div>
                                                    </Row>
                                                </Card>
                                        }
                                    </div>
                            }
                        </Card>
                    </Col>
                </Row >
            </Card >

            {
                isModalUploadDocument ?
                    <FormModal title={modalUploadTitle} disabled={warning} submitForm={(v) => uploadDms(v)} width={800} showModal={isModalUploadDocument} onCloseModal={() => dispatch(closeUploadModal())} saving={isSavingFile || uploading}>
                        <Row justify="space-between">
                            <Col span={11}>
                                <Form.Item label="Title" name="title" onChange={(v) => onChangeTitle(v.target.value)} required rules={[{ required: true }]}>
                                    <Input placeholder="Title" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item label="PDF Split" name="pdf_split">
                                    <InputNumber placeholder="PDF Split" style={{ width: '100%' }} min={0} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify='space-between'>
                            <Col span={11}>
                                <Form.Item label="Select Folder" name="folder_id" required rules={[{ required: true }]}>
                                    <Select
                                        showSearch
                                        placeholder="Select Folder"
                                        options={folder}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Row justify="space-between">
                                    <Col span={13}>
                                        <Form.Item label="Select Document Location" name="archive_location_id" required rules={[{ required: true }]}>
                                            <Select
                                                showSearch
                                                placeholder="Select Destination"
                                                options={archiveLocation}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={9}>
                                        <Button onClick={() => dispatch(addLocationModal())} style={{ marginTop: 30 }} type='primary'>Add Location</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Form.Item>
                            <DmsUpload path='file-attachment' multiple={false} dragger onUploaded={(v, key) => fileUploaded(v)} isUploading={(v) => setUploading(v)} />
                        </Form.Item>
                    </FormModal >
                    : null
            }

            {
                isModalVisibleFormFolder ?
                    <ModalFolder onFinishFolder={(v) => onFinishFolder(v)} division_list={division} />
                    : null
            }

            {
                isModalParentFolder ?
                    <ModalParentFolder onFinishFolderParent={(v) => onFinishFolderParent(v)} division_list={division} />
                    : null
            }

            {
                isModalAddLocationDocument ?
                    <ModalAddLocation onFinishLocation={(v) => onFinishLocation(v)} />
                    : null
            }

            {
                isModalRename ?
                    <ModalRename onFinishRename={(v) => onFinishRename(v)} />
                    : null
            }

            {
                isModalShareFolder ?
                    <ModalShareFolder onFinishShare={(v) => onFinishShare(v)} division_list={division} />
                    : null
            }

            {
                showModalMove ?
                    <FormModal form={form} title="Move Document" submitForm={(v) => onFinishMove(v)} width={400} showModal={showModalMove} onCloseModal={() => setShowModalMove(false)} saving={isSavingFile}>
                        <Form.Item name="id" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Destination" name="folder_id">
                            <Select
                                showSearch
                                placeholder="Select Folder"
                                options={folder}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </FormModal> : null
            }
        </div >
    )
}

export default MyFolder