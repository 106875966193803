import React from 'react';
import { Layout, Button } from 'antd';
import { PlusOutlined, FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom';
import { Breadcrumbs } from './Breadcrumb';

const { Header } = Layout

export const Page = (props) => {
    const { breadcrumbs = null } = props
    return (
        <>
            <Header
                className="site-page-header"
                onBack={props.btnBack ? () => window.history.back() : null}
                title={props.title}
                subTitle={breadcrumbs ? <Breadcrumbs items={breadcrumbs} /> : null}
                extra={[
                    (props.createLink) ?
                        <>
                            <Link to={props.createLink}>
                                <Button key="1" type="primary"><PlusOutlined /> Add {props.title}</Button>
                            </Link>
                            <Button key="2" className="border-success"><FileExcelOutlined /> Export To Excel</Button>
                        </>
                        : null,
                    (props.onAdd) ?
                        <>
                            <Button key="1" onClick={() => props.onAdd()} type="primary"><PlusOutlined /> Add {props.title}</Button>
                            <Button key="2" className="border-success"><FileExcelOutlined /> Export To Excel</Button>
                        </>
                        : null,

                    (props.exportToPdf) ?
                        <>
                            <Button key="3" className="border-error"><FilePdfOutlined /> Export To Pdf</Button>
                        </>
                        : null
                ]}
            >
                {props.children}
            </Header>
        </>
    );
}
