import React, { useEffect, useRef } from 'react';
import { message } from 'antd'
import { Spinner } from '../../components';
import { getQuery } from '../../utils/helpers';
import { AuthService } from '../../services/AuthService';

const SSO = () => {
    const token = useRef('');

    const fetchEmployees = () => {
        AuthService.authSSO(token.current).then().catch(() => {
            message.destroy()
            message.error('invalid credentials', 2)

            setTimeout(() => {
                window.location.href = "/login"
            }, 1000)
        })
    }

    useEffect(() => {
        token.current = getQuery('token')
        fetchEmployees();
    }, []);

    return (
        <div>
            <Spinner />
        </div>
    );
}

export default SSO;
