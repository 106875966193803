import React, { useState, useEffect } from 'react'
import { Card, Form, Input, message, Space } from 'antd'
import { DataTable, DeleteButton, EditButton, FormModal, PageHeader, ShowButton, Spinner } from '../../../components'
import { ApiService } from '../../../services/ApiService'
import { currentUser } from '../../../utils/authData'
import { useHistory } from 'react-router-dom'

const FileManagerSetup = () => {
    const history = useHistory()
    const [loaded, setLoaded] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [showModalFileManager, setShowModalFileManager] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [defaultValues, setDefaultValues] = useState({});
    const user = currentUser;

    const fetchFileManagerList = () => {
        ApiService.getList('list-file-manager').then((res) => {
            setFileList(res.data)
            setLoaded(true)
        })
    }

    const add = () => {
        setShowModalFileManager(true)
        setDefaultValues({
            file_manager_id: 0,
            name: '',
        })
        setModalTitle('Add New Parent File Manager')
    }

    const edit = (v) => {
        setModalTitle('Edit File Manager')
        setDefaultValues({
            file_manager_id: v.file_manager_id,
            name: v.name,
        })
        setShowModalFileManager(true)
    }

    const destroy = (v) => {
        ApiService.destroy('list-file-manager', v, { user: user.id }).then((res) => {
            message.success(res.message, 2)
            fetchFileManagerList()
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const onFinish = (el) => {
        let payload = {
            name: el.name
        }

        if (el.file_manager_id === 0) {
            Object.assign(payload, { created_by: user.id, updated_by: user.id })
            ApiService.store(`list-file-manager`, payload).then((res) => {
                message.success(res.message, 2)
                setShowModalFileManager(false)
                fetchFileManagerList()
            }).catch((err) => {
                message.warning('Failed save File Parent')
            })
        } else {
            Object.assign(payload, { updated_by: user.id })
            ApiService.update(`list-file-manager`, el.file_manager_id, payload).then((res) => {
                message.success(res.message, 2)
                setShowModalFileManager(false)
                fetchFileManagerList()
            }).catch((err) => {
                message.warning('Failed save File Parent')
            })
        }
    }

    useEffect(() => {
        fetchFileManagerList()

    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'File Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Aksi',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <ShowButton onShow={() => history.push(`file-manager/detail/${row.file_manager_id}`)} />
                        <EditButton onEdit={() => edit(row)} />
                        <DeleteButton onConfirm={() => destroy(row.file_manager_id)} />
                    </Space>
                </div>
            )
        },
    ];

    return (
        <div>
            <PageHeader title="Setup File Manager" breadcrumbs={[['Master'], ['Setup File Manager']]} onAdd={add} />
            <Card style={{ borderRadius: 10 }}>
                {
                    (loaded) ?
                        fileList ?
                            <DataTable size="small" columns={columns} sources={fileList} bordered />
                            : null
                        : <Spinner />
                }
            </Card>

            {
                showModalFileManager ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => onFinish(v)} width={600} showModal={showModalFileManager} onCloseModal={() => setShowModalFileManager(false)}>
                        <Form.Item name="file_manager_id" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Nama" name="name" required rules={[{ required: true }]}>
                            <Input placeholder="Nama" />
                        </Form.Item>
                    </FormModal>
                    : null
            }
        </div >
    )
}

export default FileManagerSetup