import React from 'react';
import { Card, Tabs } from 'antd'
import { FieldTimeOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { DataTable, ShowButton } from '../../../components'

const { TabPane } = Tabs;

const TabData = (props) => {
    const sourceData = [
        ...props.data.map((el) => {
            return {
                id: el.request_id,
                document_type_id: el.document_type_id,
                document_number: el.document_number,
                status: el.status,
                name: el.name,
                directorate_name: el.directorate_name,
            }
        })
    ]

    const columnData = [{
        title: 'No',
        dataIndex: 'no',
        align: 'center',
        key: 'name',
    }, {
        title: 'Document Number',
        dataIndex: 'document_number',
        key: 'document_number'
    }, {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
    }, {
        title: 'Directorate Name',
        dataIndex: 'directorate_name',
        key: 'directorate_name'
    }, {
        title: 'Action',
        width: '10%',
        render: (row) => (
            <div className="text-center">
                {
                    row.document_type_id === 2 ?
                        <ShowButton link to={'/approval-center-memo/' + row.id} /> :
                        <ShowButton link to={'/approval-center-outgoing/' + row.id} />
                }
            </div>
        )
    }
    ];

    return (
        <Card>
            <Tabs defaultActiveKey="waiting">
                <TabPane tab={<span><FieldTimeOutlined style={{ color: '#4ca3ff' }} /> Waiting Approval</span>} key="waiting">
                    <DataTable size="small" columns={columnData} sources={sourceData.filter(el => el.status === 'WAITING')} bordered />
                </TabPane>
                <TabPane tab={<span><CheckCircleOutlined style={{ color: 'green' }} /> Approved</span>} key="approved">
                    <DataTable size="small" columns={columnData} sources={sourceData.filter(el => el.status === 'APPROVED')} bordered />
                </TabPane>
                <TabPane tab={<span><CloseCircleOutlined style={{ color: 'red' }} /> Rejected</span>} key="rejected">
                    <DataTable size="small" columns={columnData} sources={sourceData.filter(el => el.status === 'REJECTED')} bordered />
                </TabPane>
            </Tabs>
        </Card>
    );
}

export default TabData;
