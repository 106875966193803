import axios from 'axios'
import { decrypt } from '../utils/helpers'
import { handleResponse } from '../utils/response'
import moment from 'moment'
import { logout } from './AuthService'
require('dotenv').config()

const host = process.env.REACT_APP_API_URL
const version = process.env.REACT_APP_API_VERSION
const clientKey = process.env.REACT_APP_CLIENT_KEY
const token = localStorage.getItem('accessToken') ? JSON.parse(decrypt(localStorage.getItem('accessToken'))) : []

function setLastRequest() {
    let lastRequest = localStorage.getItem('lastRequest')
    const currentTime = moment(new Date())
    if (lastRequest) {
        lastRequest = moment(new Date(lastRequest))
        var diff = moment.duration(currentTime.diff(lastRequest));
        var minutes = diff.asMinutes()
        if (minutes > 120) {
            logout()
        } else {
            localStorage.setItem('lastRequest', currentTime);
        }
    } else {
        localStorage.setItem('lastRequest', currentTime);
    }
}

const auth = async (payload) => {
    setLastRequest()
    return await axios.post(`${host}/${version}/auth/login`, payload, {
        headers: {
            'client-key': clientKey
        }
    }).then(handleResponse)
}

const getList = async (path, params) => {
    setLastRequest()
    return await axios.get(`${host}/${version}/${path}`, {
        params,
        headers: {
            'client-key': clientKey,
            'authorization': `Bearer ${token}`
        }
    }).then(handleResponse)
}

const getObject = async (path, id, params) => {
    setLastRequest()
    return await axios.get(`${host}/${version}/${path}/${id}`, {
        params,
        headers: {
            'client-key': clientKey,
            'authorization': `Bearer ${token}`
        }
    }).then(handleResponse)
}

const store = async (path, payload, params) => {
    setLastRequest()
    return await axios.post(`${host}/${version}/${path}`, payload, {
        params,
        headers: {
            'client-key': clientKey,
            'authorization': `Bearer ${token}`
        }
    }).then(handleResponse)
}

const update = async (path, id, payload, params) => {
    setLastRequest()
    return await axios.put(`${host}/${version}/${path}/${id}`, payload, {
        params,
        headers: {
            'client-key': clientKey,
            'authorization': `Bearer ${token}`
        }
    }).then(handleResponse)
}

const destroy = async (path, id, params) => {
    setLastRequest()
    return await axios.delete(`${host}/${version}/${path}/${id}`, {
        params,
        headers: {
            'client-key': clientKey,
            'authorization': `Bearer ${token}`
        }
    }).then(handleResponse)
}

export const ApiService = { auth, getList, getObject, store, update, destroy }