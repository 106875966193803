import { useEffect, useState } from 'react';
import { useAppState } from './shared/AppProvider';
import { Badge, Divider, Drawer, Layout, List, Menu, Switch } from 'antd';
import {
    homeMenu, documentWorkflowMenu, documentManagementMenu, manageDocumentSharedMenu, masterMenu, /*,organizationMenu */
    approvalCenterMenu,
    documentAuditTrailMenu
} from '../routes/MenuArray';
import smailMobile from '../assets/icon/ic-smail-mobile.svg'
import smail from '../assets/icon/logo-smail.svg'
import Inner from './styles/Sidebar';
import { Link } from 'react-router-dom';
import { role } from '../utils/authData';

const { SubMenu } = Menu;
const { Sider } = Layout;

let rootSubMenuKeys = [];
const getKey = (name, index) => {
    const string = `${name}-${index}`;
    let key = string.replace(' ', '-');
    return key.charAt(0).toLowerCase() + key.slice(1);
};
const Sidebar = ({
    sidebarTheme,
    sidebarMode,
    sidebarIcons,
    collapsed,
    router
}) => {
    const [state, dispatch] = useAppState();
    const [openKeys, setOpenKeys] = useState([]);
    const { pathname } = router;
    const badgeTemplate = badge => <Badge count={badge.value} />;

    useEffect(() => {
    }, []);

    const onOpenChange = openKeys => {
        const latestOpenKey = openKeys.slice(-1);
        if (rootSubMenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys([...latestOpenKey]);
        } else {
            setOpenKeys(latestOpenKey ? [...latestOpenKey] : []);
        }
    };

    const generateMenu = (menu) => (
        menu.map((route, index) => {
            const hasChildren = route.children ? true : false;
            if (!hasChildren) {
                return (
                    <Menu.Item
                        key={getKey(route.name, index)}
                        className={
                            pathname === route.path ? 'ant-menu-item-selected' : ''
                        }
                        onClick={() => {
                            setOpenKeys([getKey(route.name, index)]);
                        }}
                    >
                        <Link to={route.path} prefetch>
                            {sidebarIcons && (
                                <span className="anticon">{route.icon}</span>
                            )}
                            <span style={{ color: "#fff", fontWeight: 400 }} className="mr-auto">{(route.name)}</span>
                            {route.badge && badgeTemplate(route.badge)}
                        </Link>
                    </Menu.Item>
                );
            } else {
                return (
                    <SubMenu
                        key={getKey(route.name, index)}
                        title={
                            <span>
                                {sidebarIcons && (
                                    <span className="anticon">{route.icon}</span>
                                )}
                                <span style={{ color: "#fff", fontWeight: 400 }}>{(route.name)}</span>
                                {route.badge && badgeTemplate(route.badge)}
                            </span>
                        }
                    >
                        {route.children.map((subitem, index) => (
                            <Menu.Item
                                key={getKey(subitem.name, index)}
                                className={
                                    pathname === subitem.path ? 'ant-menu-item-selected' : ''
                                }
                            >
                                <Link to={`${subitem.path ? subitem.path : ''}`} prefetch>
                                    <span>
                                        <span className="mr-auto">
                                            {sidebarIcons && (
                                                <span className="anticon">{subitem.icon}</span>
                                            )}
                                            <span style={{ color: "#fff", fontWeight: 400 }}>{(subitem.name)}</span>
                                        </span>
                                        {subitem.badge && badgeTemplate(subitem.badge)}
                                    </span>
                                </Link>
                            </Menu.Item>
                        ))}
                    </SubMenu>
                );
            }
        })
    )

    const menu = (
        <>
            {
                !state.collapsed ?
                    <div className='mt-3 mb-1'>
                        <Link style={{ margin: "0px 47px" }} to="/">
                            <img className='logo' src={smail} alt="smail" width={"60%"} />
                        </Link>
                    </div> :
                    <div className='mt-3 mb-2'>
                        <Link style={{ margin: "0px 15px" }} to="/">
                            <img className='logo' src={smailMobile} alt="smail-mobile" width={"60%"} />
                        </Link>
                    </div>
            }
            <Menu
                theme={sidebarTheme}
                className="border-0 scroll-y sidebar-scroll"
                style={{ flex: 1, height: '100%', overflowY: 'auto' }}
                mode={sidebarMode}
                openKeys={openKeys}
                onOpenChange={onOpenChange}
            >
                {generateMenu(homeMenu)}
                {generateMenu(approvalCenterMenu)}
                {generateMenu(documentWorkflowMenu)}
                {generateMenu(documentManagementMenu)}
                {generateMenu(documentAuditTrailMenu)}
                {generateMenu(manageDocumentSharedMenu)}
                {/* {
                    role.includes('SUPERADMIN') ?
                        generateMenu(organizationMenu) : null
                } */}
                {
                    role.includes('SUPERADMIN') ?
                        generateMenu(masterMenu) : null
                }
            </Menu>
            <Divider
                className={`m-0`}
                style={{
                    display: `${sidebarTheme === 'dark' ? 'none' : ''}`
                }}
            />
        </>
    );

    return (
        <>
            <Inner>
                {
                    !state.mobile && (
                        <Sider
                            width={240}
                            className={`bg-${sidebarTheme}`}
                            theme={sidebarTheme}
                            collapsed={collapsed}
                        >
                            {menu}
                        </Sider>
                    )
                }
                <Drawer
                    closable={false}
                    width={240}
                    placement="left"
                    onClose={() => dispatch({ type: 'mobileDrawer' })}
                    visible={state.mobileDrawer}
                    className="chat-drawer"
                >
                    {menu}
                </Drawer>

                <Drawer
                    title="Settings"
                    placement="right"
                    closable={true}
                    width={300}
                    onClose={() => dispatch({ type: 'options' })}
                    visible={state.optionDrawer}
                >
                    <List.Item
                        actions={[
                            <Switch size="small" checked={!!state.darkSidebar} disabled={state.weakColor} onChange={checked => dispatch({ type: 'sidebarTheme' })} />
                        ]}
                    >
                        <span css={`-webkit-box-flex: 1; -webkit-flex: 1 0; -ms-flex: 1 0; flex: 1 0;`} >
                            Dark sidebar menu
                        </span>
                    </List.Item>

                    <List.Item
                        actions={[
                            <Switch size="small" checked={!!state.collapsed} onChange={checked => dispatch({ type: 'collapse' })} />
                        ]}
                    >
                        <span css={`-webkit-box-flex: 1; -webkit-flex: 1 0; -ms-flex: 1 0; flex: 1 0;`} >
                            Collapsed sidebar menu
                        </span>
                    </List.Item>
                </Drawer>
            </Inner>
        </>
    );
};

export default Sidebar;
