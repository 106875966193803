import { useEffect, useState } from 'react'
import { DataTable, DeleteButton, EditButton, FormModal, PageHeader, Spinner } from '../../../components'
import { Card, Form, Input, Space, message } from 'antd'
import { ApiService } from '../../../services/ApiService'

const Index = () => {
    const [schemaData, setSchemaData] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [modalTitle, setModalTitle] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [isSaving, setIsSaving] = useState(false)

    const fetchApproval = (v) => {
        if (v) {
            setLoaded(false)
        }
        ApiService.getList("approval-scheme").then((res) => {
            setSchemaData(res.data)
            if (v) {
                setLoaded(true)
            }
        })
    }

    useEffect(() => {
        fetchApproval(true)
    }, [])



    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => edit(row)} />
                        <DeleteButton onConfirm={() => destroy(row.approval_scheme_id)} />
                    </Space>
                </div>
            ),
            key: 'action',
            align: 'center'
        },
    ];

    const add = () => {
        setModalTitle('Add Approval Scheme')
        setDefaultValues({
            approval_scheme_id: 0,
            description: '',
            code: '',
        })
        setIsModalVisible(true)
    }

    const edit = (v) => {
        setModalTitle('Edit Approval Scheme')
        setDefaultValues({
            approval_scheme_id: v.approval_scheme_id,
            description: v.description,
            code: v.code,
        })
        setIsModalVisible(true)
    }

    const destroy = (id) => {
        ApiService.destroy("approval-scheme", id).then((res) => {
            message.success("Success delete approval scheme", 2)
            fetchApproval(false)
        }).catch((err) => {
            message.warning("Failed delete approval scheme", 2)
        })
    }

    const onFinish = (el) => {
        let payload = {
            description: el.description,
            code: el.code,
        }

        if (el.approval_scheme_id === 0) {
            setIsSaving(true)
            ApiService.store(`approval-scheme`, payload)
                .then((res) => {
                    message.success("Success create approval scheme", 2)
                    setIsModalVisible(false)
                    setIsSaving(false)
                    fetchApproval(true)
                }).catch((err) => {
                    message.warning("Failed create approval scheme")
                    setIsSaving(false)
                })
        } else {
            setIsSaving(true)
            ApiService.update(`approval-scheme`, el.approval_scheme_id, payload)
                .then((res) => {
                    message.success("Success update approval_scheme", 2)
                    setIsModalVisible(false)
                    setIsSaving(false)
                    fetchApproval(true)
                }).catch((err) => {
                    message.warning("Failed update approval_scheme")
                    setIsSaving(false)
                })
        }
    }

    return (
        <div>
            <PageHeader title="Approval Scheme" breadcrumbs={[['Approval Scheme']]} onAdd={add} />
            <Card style={{ borderRadius: 10 }}>
                {
                    loaded ?
                        <DataTable sources={schemaData} columns={columns} bordered size="small" />
                        : <Spinner />
                }

                {
                    (isModalVisible) ?
                        <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => onFinish(v)} width={600} showModal={isModalVisible} onCloseModal={() => setIsModalVisible(false)} saving={isSaving}>
                            <Form.Item name="approval_scheme_id" hidden>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Description" name="description" required rules={[{ required: true }]}>
                                <Input placeholder="Description" />
                            </Form.Item>
                            <Form.Item label="Code" name="code" required rules={[{ required: true }]}>
                                <Input placeholder="Code" />
                            </Form.Item>
                        </FormModal> :
                        null
                }
            </Card>
        </div>
    )
}

export default Index