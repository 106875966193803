import { Avatar, Layout, Menu } from 'antd';
import { BarChart, Bell } from 'react-feather';
import { Link, useHistory } from 'react-router-dom';
import DashHeader from './styles/Header';
import { useAppState } from './shared/AppProvider';
import { currentUser } from '../utils/authData';
import { logout } from '../services/AuthService';

const { SubMenu } = Menu;
const { Header } = Layout;

const MainHeader = () => {
    const history = useHistory();
    const [state, dispatch] = useAppState();

    return (
        <DashHeader>
            <Header>
                {state.mobile && (
                    <Link href="#!"
                        onClick={() => dispatch({ type: 'mobileDrawer' })}
                        className="trigger">
                        <BarChart size={20} strokeWidth={1} />
                    </Link>
                )}
                <span className="mr-auto" />

                <Menu mode="horizontal">
                    <SubMenu
                        key="bell-notification"
                        title={
                            <Bell size={20} strokeWidth={1} />
                        }
                    >
                        <div className='dropdown-notification-box'>
                            <Menu.Item style={{ height: 'auto', backgroundColor: 'transparent' }}>
                            </Menu.Item>
                        </div>
                    </SubMenu>

                    <SubMenu key="avatar" title={
                        <Avatar size={40}>
                            {(currentUser?.name.substring(0, 2)).toUpperCase()}
                        </Avatar>
                    }>
                        <Menu.Item key="profile_edit" onClick={() => history.push("/profile")}>Profile</Menu.Item>
                        <Menu.Divider />
                        <Menu.Item key="logout" onClick={() => logout()}>
                            Log Out
                        </Menu.Item>
                    </SubMenu>
                </Menu>
            </Header >
        </DashHeader >
    );
};

export default MainHeader;
