import { configureStore } from "@reduxjs/toolkit";
import fileManagerReducer from "./slice/fileManagerSlice";
import globalReducer from "./slice/globalSlice";

const store = configureStore({
    reducer: {
        global: globalReducer,
        fileManager: fileManagerReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

export default store;