import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { checkExtensiton } from "../../utils/checkExtension";
import { GenerateFolder } from "../../utils/folder/folder";
import { generateTreeArr, getNestedChildren } from "../../utils/getNestedChild";
import { ApiService } from "../../services/ApiService";

export const fetchNestedTree = createAsyncThunk(
    'myTask/fetchNestedTree',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await ApiService.getList('my-folder');
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }

    }
)

export const fetchFolderShare = createAsyncThunk(
    'myTask/fetchFolderShare',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await ApiService.getList(`folder-share/${payload}`);
            return response.data
        } catch (error) {
            return rejectWithValue(error)
        }

    }
)

const fileManagerSlice = createSlice({
    name: 'fileManager',
    initialState: {
        fileNamerUrl: "",
        fileId: 0,
        parentId: 0,
        stringValue: "",
        defaultValues: {},
        parentFolderValues: {},
        shareFolderValues: {},
        renameValues: {},
        fileEkstension: "",
        modalTitleFormFolder: "",
        modalUploadTitle: "",
        modalTitleAddLocationDocument: "",
        modalRenameTitle: "",
        isModalVisibleFormFolder: false,
        isModalParentFolder: false,
        isModalShareFolder: false,
        isModalUploadDocument: false,
        isModalAddLocationDocument: false,
        isModalRename: false,
        isUpdateFolder: false,
        isFile: false,
        isSaving: false,
        isSavingFile: false,
        resultSearch: false,
        loadedSearch: false,
        loaded: false,
        loadedShare: false,
        resultFile: [],
        nestedTree: [],
        folderShare: [],
    },
    reducers: {
        changeInputValue: (state, action) => {
            state.stringValue = action.payload
        },
        addFolderModal: (state, action) => {
            state.defaultValues = {
                id: 0,
                name: ''
            };
            state.modalTitleFormFolder = "Add Folder"
            state.isModalVisibleFormFolder = true
        },
        closeFolderModal: (state, action) => {
            state.isModalVisibleFormFolder = false
        },
        addParentFolderModal: (state, action) => {
            state.isModalParentFolder = true
            state.parentFolderValues = {
                root_id: action.payload,
                name: ""
            }
        },
        closeParentFolderModal: (state, action) => {
            state.isModalParentFolder = false
        },
        shareFolderModal: (state, action) => {
            state.isModalShareFolder = true
            state.shareFolderValues = {
                file_manager_id: action.payload.id,
                folder_id: action.payload.file_origins_id,
            }
        },
        closeShareFolderModal: (state, action) => {
            state.isModalShareFolder = false
        },
        uploadModal: (state, action) => {
            state.isModalUploadDocument = true
            state.modalUploadTitle = "Upload Document"
        },
        closeUploadModal: (state, action) => {
            state.isModalUploadDocument = false
        },
        addLocationModal: (state, action) => {
            state.isModalAddLocationDocument = true
            state.modalTitleAddLocationDocument = "Add Archive Location"
        },
        closeLocationdModal: (state, action) => {
            state.isModalAddLocationDocument = false
        },
        renameFolderModal: (state, action) => {
            const data = action.payload
            state.renameValues = {
                id: data.file_origins_id,
                original_filename: data.original_filename
            }
            state.isModalRename = true
            state.modalRenameTitle = "Rename Folder"
            state.isUpdateFolder = true
        },
        renameFileModal: (state, action) => {
            const data = action.payload
            const ext = checkExtensiton(data.name)
            state.fileEkstension = ext
            state.renameValues = {
                id: data.file_origins_id,
                original_filename: data.original_filename.split('.')[0]
            }
            state.isModalRename = true
            state.modalRenameTitle = "Rename File"
            state.isUpdateFolder = false
        },
        closeRenameModal: (state, action) => {
            state.isModalRename = false
        },
        selectTree: (state, action) => {
            const data = action.payload
            state.resultSearch = false
            state.fileId = data.file_origins_id

            if (data.type === "FOLDER") {
                state.isFile = false
            }
            if (data.type === "FILE") {
                state.isFile = true
                state.fileNameUrl = data.name
                state.parentId = data.parent
            }
        },
        selectFile: (state, action) => {
            const { name, id } = action.payload
            state.isFile = true
            state.fileNameUrl = name
            state.fileId = id
        },
        finishSeach: (state, action) => {
            state.isFile = false
            state.resultSearch = true
        },
        clearSearchReducer: (state, action) => {
            state.isFile = false
            state.resultSearch = false
        },
        changeIsFileStatus: (state, action) => {
            state.isFile = action.payload
        },
        changeIsSaving: (state, action) => {
            state.isSaving = action.payload
        },
        changeIsSavingFile: (state, action) => {
            state.isSavingFile = action.payload
        },
        changeLoadedSearch: (state, action) => {
            state.loadedSearch = action.payload
        },
        searchFileManagerReducer: (state, action) => {
            state.resultFile = []
            const data = action.payload
            state.loadedSearch = false
            if (data.length > 0) {
                data.forEach((el) => {
                    state.resultFile.push(el)
                })
            } else {
                state.resultFile = []
            }
            state.isFile = false
            state.resultSearch = true
            state.loadedSearch = true
        },
        changeLoaded: (state, action) => {
            state.loaded = action.payload
        },
        changeNestedTree: (state, action) => {
            const tree = action.payload
            const data = GenerateFolder(tree)

            let folder = []
            let file = []
            data.forEach((el) => {
                if (el.type === "FOLDER") {
                    folder.push(el)
                } else {
                    file.push(el)
                }
            })
            const generateTree = getNestedChildren(folder, tree)
            const treeResult = generateTree[0].children.filter((el) => el.parent === 4 && el.type === "FOLDER")
            state.nestedTree = treeResult
            state.loaded = true
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchNestedTree.pending, (state) => {
            state.loaded = false
        })
        builder.addCase(fetchNestedTree.fulfilled, (state, action) => {
            const tree = action.payload
            const data = GenerateFolder(tree)

            // let folder = []
            // let file = []
            // data.forEach((el) => {
            //     if (el.type === "FOLDER") {
            //         folder.push(el)
            //     } else {
            //         file.push(el)
            //     }
            // })
            const generateTree = generateTreeArr(data)
            // const treeResult = generateTree[0].children.filter((el) => el.parent === 4 && el.type === "FOLDER")
            state.nestedTree = generateTree
            state.loaded = true
        })
        builder.addCase(fetchNestedTree.rejected, (state) => {
            state.loaded = false
        })
        builder.addCase(fetchFolderShare.pending, (state) => {
            state.loadedShare = false
        })
        builder.addCase(fetchFolderShare.fulfilled, (state, action) => {
            const tree = action.payload
            const data = GenerateFolder(tree)

            // let folder = []
            // let file = []
            // data.forEach((el) => {
            //     if (el.type === "FOLDER") {
            //         folder.push(el)
            //     } else {
            //         file.push(el)
            //     }
            // })
            const generateTree = generateTreeArr(data)
            // const treeResult = generateTree[0].children.filter((el) => el.parent === 4 && el.type === "FOLDER")
            state.folderShare = generateTree
            state.loadedShare = true
        })
        builder.addCase(fetchFolderShare.rejected, (state) => {
            state.loadedShare = false
        })
    }
})

export const {
    changeInputValue,
    addFolderModal,
    closeFolderModal,
    addParentFolderModal,
    closeParentFolderModal,
    shareFolderModal,
    closeShareFolderModal,
    uploadModal,
    closeUploadModal,
    addLocationModal,
    closeLocationdModal,
    renameFolderModal,
    renameFileModal,
    closeRenameModal,
    selectTree,
    selectFile,
    finishSeach,
    clearSearchReducer,
    changeIsFileStatus,
    changeIsSaving,
    changeIsSavingFile,
    changeLoadedSearch,
    searchFileManagerReducer,
    changeLoaded,
    changeNestedTree,
} = fileManagerSlice.actions
export default fileManagerSlice.reducer