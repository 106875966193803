export const checkExtensiton = (text) => {
    if (text.includes(".pdf")) {
        return ".pdf"
    }
    if (text.includes(".png")) {
        return ".png"
    }
    if (text.includes(".jpg")) {
        return ".jpg"
    }
    if (text.includes(".jpeg")) {
        return ".jpeg"
    }
}