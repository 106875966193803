import React, { useState, useEffect } from 'react'
import { Card, Tag } from 'antd'
import { DataTable, PageHeader, Spinner } from './../../../components'
import { ApiService } from '../../../services/ApiService'

const Division = () => {
    const [loaded, setLoaded] = useState(true)
    const [directorate, setDirectorate] = useState([])
    // const [modalTitle, setModalTitle] = useState(null)
    // const [isModalVisible, setIsModalVisible] = useState(false)
    // const [defaultValues, setDefaultValues] = useState({})
    // const [isSaving, setIsSaving] = useState(false)
    // const [status, setStatus] = useState(false)
    // const user = currentUser

    const fetchDirectorate = () => {
        setLoaded(false)
        ApiService.getList('department').then((res) => {
            setDirectorate(res.data)
            setLoaded(true)
        }).catch(() => {
        })
    }

    // const add = () => {
    //     setModalTitle('Tambah Division')
    //     setDefaultValues({
    //         id: 0,
    //         parent_id: '',
    //         name: '',
    //         code: '',
    //         status: true
    //     })
    //     setIsModalVisible(true)
    //     setStatus(true)
    // }

    // const edit = (v) => {
    //     setModalTitle('Edit Division')
    //     setDefaultValues({
    //         id: v.id,
    //         parent_id: v.parent_id,
    //         name: v.name,
    //         code: v.code,
    //         status: parseInt(v.status),
    //     })
    //     setIsModalVisible(true)
    //     setStatus(v.status)
    // }

    // const destroy = (id) => {
    //     ApiService.destroy('department', id, { user: user.id }).then((res) => {
    //         message.success(res.message, 2)
    //         fetchDirectorate()
    //     }).catch((err) => {
    //         message.warning(err.message, 2)
    //     })
    // }

    // const onFinish = (el) => {
    //     let payload = {
    //         parent_id: el.parent_id,
    //         name: el.name.toUpperCase(),
    //         code: el.code.toUpperCase(),
    //         status: status ? 1 : 0
    //     }

    //     if (el.id === 0) {
    //         Object.assign(payload, { created_by: user.id, updated_by: user.id })
    //         setIsSaving(true)
    //         ApiService.store(`department`, payload)
    //             .then((res) => {
    //                 message.success(res.message, 2)
    //                 setIsModalVisible(false)
    //                 setIsSaving(false)
    //                 fetchDirectorate()
    //             }).catch((err) => {
    //                 if (err.response.data.message === 'err name') {
    //                     message.warning('Nama sudah digunakan, mohon input ulang!')
    //                     setIsSaving(false)
    //                 } else {
    //                     message.warning('Code sudah digunakan, mohon input ulang!')
    //                     setIsSaving(false)
    //                 }
    //             })
    //     } else {
    //         Object.assign(payload, { updated_by: user.id })
    //         setIsSaving(true)
    //         ApiService.update(`department`, el.id, payload)
    //             .then((res) => {
    //                 message.success(res.message, 2)
    //                 setIsModalVisible(false)
    //                 setIsSaving(false)
    //                 fetchDirectorate()
    //             }).catch((err) => {
    //                 if (err.response.data.message === 'err name') {
    //                     message.warning('Nama sudah digunakan, mohon input ulang!')
    //                     setIsSaving(false)
    //                 } else {
    //                     message.warning('Code sudah digunakan, mohon input ulang!')
    //                     setIsSaving(false)
    //                 }
    //             })
    //     }
    // }

    useEffect(() => {
        fetchDirectorate()
    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Division Name',
            dataIndex: 'name',
            key: 'name',
            align: 'center'
        },
        {
            title: 'Status',
            key: 'status',
            render: (row) => (
                parseInt(row.status) === 1 ? <Tag color="green">Active</Tag> : <Tag color="red">Not Active</Tag>
            ),
            align: 'center'
        },
        // {
        //     title: 'Aksi',
        //     width: 120,
        //     render: (row) => (
        //         <div className="text-center">
        //             <Space>
        //                 <EditButton onEdit={() => edit(row)} />
        //                 <DeleteButton onConfirm={() => destroy(row.id)} />
        //             </Space>
        //         </div>
        //     )
        // },
    ];

    return (
        <div>
            <PageHeader title="Divisi" breadcrumbs={[['Organisasi'], ['Divisi']]} />
            <Card style={{ borderRadius: 10 }}>
                <>
                    {
                        loaded ?
                            <DataTable size="small" columns={columns} sources={directorate} bordered />
                            : <Spinner />
                    }
                </>

                {/* {
                    (isModalVisible) ?
                        <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => onFinish(v)} width={600} showModal={isModalVisible} onCloseModal={() => setIsModalVisible(false)} saving={isSaving}>
                            <Form.Item name="id" hidden>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Induk Division" name="parent_id">
                                <Select placeholder="Induk Division">
                                    {
                                        directorate.map((e, key) => (
                                            <Option key={key} value={e.id}>{e.name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label="Nama" name="name" required rules={[{ required: true }]}>
                                <Input placeholder="Nama" />
                            </Form.Item>
                            <Form.Item label="Kode" name="code">
                                <Input placeholder="Kode" />
                            </Form.Item>
                            <Form.Item label="Status" name="status" rules={[{ required: true }]}>
                                <Switch checkedChildren="Ya" unCheckedChildren="Tidak" onChange={(v) => setStatus(v)} defaultChecked={defaultValues.status} />
                            </Form.Item>
                            <Text>Inputan dengan tanda (<span style={{ color: 'red' }}>*</span>) wajib diisi</Text>
                        </FormModal> :
                        null
                } */}
            </Card>
        </div>
    )
}

export default Division
