import { useState, useEffect } from 'react'
import { DataTable, DeleteButton, EditButton, PageHeader, ShowButton, Spinner } from '../../../components'
import { Card, Space, message } from 'antd'
import { useHistory } from 'react-router-dom'
import { ApiService } from '../../../services/ApiService'
import { currentUser } from '../../../utils/authData'

const Index = () => {
    const history = useHistory()
    const [internalMemoData, setInternalMemoData] = useState([])
    const [isApprove, setIsApprove] = useState(false)
    const [loaded, setLoaded] = useState(false)

    const fetchInternalMemo = (v) => {
        if (v) {
            setLoaded(false)
        }
        ApiService.getList("internal-memo").then((res) => {
            setInternalMemoData(res.data)

            if (v) {
                setLoaded(true)
            }
        })
    }

    const fetchApprovalScheme = () => {
        setLoaded(false)
        ApiService.getList(`document-scheme/${2}/${currentUser.division_id}`).then((res) => {
            if (res.data.length > 0) {
                setIsApprove(true)
            } else {
                setIsApprove(false)
            }

            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchInternalMemo(true)
        fetchApprovalScheme()

    }, [])

    const destroy = (v) => {
        ApiService.destroy("internal-memo", v).then((res) => {
            message.success("Success Delete Internal Memo")
            fetchInternalMemo(false)
        }).catch((err) => {
            message.warning("Failed Delete Internal Memo")
        })
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Memo Internal Id',
            dataIndex: 'workflow_internal_memo_id',
            key: 'workflow_internal_memo_id'
        },
        {
            title: 'Document Number',
            dataIndex: 'document_number',
            key: 'document_number',
        },
        {
            title: 'Document Type',
            render: (row) => (
                row?.document_type?.document_type_name
            ),
            key: 'document-type'
        },
        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <ShowButton onShow={() => history.push(`/document-workflow/internal-memo/detail/${row.workflow_internal_memo_id}`)} />
                        <EditButton onEdit={() => history.push(`/document-workflow/internal-memo/add/${row.workflow_internal_memo_id}`)} />
                        <DeleteButton onConfirm={() => destroy(row.workflow_internal_memo_id)} />
                    </Space>
                </div>
            ),
            key: 'action',
            align: 'center'
        },
    ];

    return (
        <div>
            <PageHeader title="Internal Memo" breadcrumbs={[['Internal Memo']]} disabled={!isApprove} disableTitle="Approval Not Available" onAdd={() => history.push("/document-workflow/internal-memo/add/0")} />
            <Card style={{ borderRadius: 10 }}>
                {
                    loaded ?
                        <DataTable sources={internalMemoData} columns={columns} bordered size="small" />
                        : <Spinner />
                }
            </Card>
        </div>
    )
}

export default Index