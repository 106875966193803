import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Layout } from 'antd'
import { GlobalStyles } from './components/styles/GlobalStyles'
import AppContent from './views/AppContent'
import Login from './views/Auth/Login'
import Forgot from './views/Auth/Forgot'
import SignUp from './views/Auth/SignUp'
import SSO from './views/sso'
import './assets/styles.less'
import './assets/main.css'
import './assets/custom-style.scss'
import 'suneditor/dist/css/suneditor.min.css'

function App() {
    const [authenticated, setAuthenticated] = useState(false)

    useEffect(() => {
        let currentUser = localStorage.getItem('currentUser')

        if (!currentUser) {
            setAuthenticated(false)
            if (window.location.pathname !== "/login" && window.location.pathname !== "/sso") {
                window.location.href = "/login?redirect_to=" + window.location.pathname

                // window.location.href = 'https://portal.sentralapps.com'
            }
        } else {
            setAuthenticated(true)
            if (window.location.pathname === "/login" || window.location.pathname === "/sso") {
                window.location.href = "/"
            }
        }
    }, []);

    return (
        <>
            <GlobalStyles />
            <div>
                <Router>
                    <Layout theme="light">
                        <Layout style={{ minHeight: '100vh' }}>
                            {
                                authenticated ?
                                    <AppContent />
                                    :
                                    <Switch>
                                        <Route exact path="/login" component={Login} />
                                        <Route exact path="/forgot-password" component={Forgot} />
                                        <Route exact path="/sign-up" component={SignUp} />
                                        <Route exact path="/sso" component={SSO} />
                                    </Switch>
                            }
                        </Layout>
                    </Layout>
                </Router>
            </div>
        </>
    );
}

export default App;
