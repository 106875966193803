import { useEffect, useState } from 'react'
import { DataTable, EditButton, PageHeader, Spinner } from '../../../components'
import { Card, Select, Space } from 'antd'
import { ApiService } from '../../../services/ApiService'
import { setSessionStorage, getSessionStorage } from '../../../utils/sessionStorage';
import { useHistory } from 'react-router-dom'

const { Option } = Select

const Index = () => {
    const history = useHistory()
    const [schemaData, setSchemaData] = useState([])
    const [loaded, setLoaded] = useState(true)
    const [directorates, setDirectorates] = useState([])
    const [selectedDirectorate, setSelectedDirectorate] = useState(null)

    const fetchDirectorates = () => {
        ApiService.getList('directorate').then((res) => {
            setDirectorates(res.data)
        })
    }

    const initialDirectorate = () => {
        if (getSessionStorage('approvalDirectorate')) {
            return parseInt(getSessionStorage('approvalDirectorate'))
        }
        return null
    }

    const fetchApproval = (directorateId) => {
        ApiService.getList(`approver-scheme/${directorateId}`).then((res) => {
            setSchemaData(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchDirectorates()
        const defaultDivision = getSessionStorage('approvalDirectorate')
        if (defaultDivision) {
            fetchApproval(defaultDivision)
            setSessionStorage('approvalDirectorate', defaultDivision)
            setSelectedDirectorate(defaultDivision)
        }
    }, [])

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Scheme',
            dataIndex: 'description',
            key: 'description',
            width: '35%'
        },
        {
            title: 'Approver',
            render: (row) => (
                <ol style={{ paddingLeft: '1em', marginBottom: 0 }}>
                    {
                        row.approver.map((el, key) => (
                            <li key={'approver_' + key}>{el.user ? el.user.name : null}</li>
                        ))
                    }
                </ol>
            )
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => history.push(`/approval-approver/detail/${row.approval_scheme_id}/${selectedDirectorate}`)} />
                    </Space>
                </div>
            ),
            key: 'action',
            align: 'center'
        },
    ];

    const changeDirectorate = (v) => {
        setLoaded(false)
        fetchApproval(v)
        setSessionStorage('approvalDirectorate', v)
        setSelectedDirectorate(v)
    }

    return (
        <div>
            <PageHeader title="Approval Approver" breadcrumbs={[['Approval Approver']]} />
            <Card style={{ borderRadius: 10 }}>
                <div className="full-width">
                    <span className="mb-3" style={{ float: 'right' }}>
                        <Space>
                            <Select defaultValue={initialDirectorate} placeholder="Select Directorate" style={{ width: 250 }} onChange={changeDirectorate} loading={directorates.length === 0}>
                                {
                                    directorates.map((el, key) => (
                                        <Option key={'directorate_' + key} value={el.directorate_id}>{el.directorate_name}</Option>
                                    ))
                                }
                            </Select>
                        </Space>
                    </span>
                </div>

                {
                    loaded ?
                        <DataTable sources={schemaData} columns={columns} bordered size="small" />
                        : <Spinner />
                }
            </Card>
        </div>
    )
}

export default Index