import { createSlice } from "@reduxjs/toolkit";
import { currentUser } from "../../utils/authData";

const globalSlice = createSlice({
    name: 'global',
    initialState: {
        user: currentUser
    }
})


export default globalSlice.reducer