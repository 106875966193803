import React, { useState, useEffect } from 'react'
import { Card, Form, Input, message, Space } from 'antd'
import { EditButton, DeleteButton, DataTable, PageHeader, Spinner, FormModal } from './../../../components'
import { ApiService } from '../../../services/ApiService'

const LokasiArsip = () => {
    const [loaded, setLoaded] = useState(true)
    const [archiveLocation, setArchiveLocation] = useState([])
    const [modalTitle, setModalTitle] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [isSaving, setIsSaving] = useState(false)

    const fetchArchiveLocation = () => {
        setLoaded(false);
        ApiService.getList('archive-location').then((res) => {
            setArchiveLocation(res.data)
            setLoaded(true)
        }).catch((err) => {
        })
    }

    const add = () => {
        setModalTitle('Add Archive Location')
        setDefaultValues({
            id: 0,
            name: '',
            code: '',
        })
        setIsModalVisible(true)
    }

    const edit = (v) => {
        setModalTitle('Edit Archive Location')
        setDefaultValues({
            id: v.id,
            name: v.name,
            code: v.code,
        })
        setIsModalVisible(true)
    }

    const destroy = (id) => {
        ApiService.destroy('archive-location', id).then((res) => {
            message.success(res.message, 2)
            fetchArchiveLocation()
        }).catch((err) => {
            message.warning(err.response.data.message, 2)
        })
    }

    const onFinish = (el) => {
        let payload = {
            name: el.name.toUpperCase(),
            code: el.code.toUpperCase(),
        }
        if (el.id === 0) {
            setIsSaving(true)
            ApiService.store(`archive-location`, payload)
                .then((res) => {
                    message.success(res.message, 2)
                    setIsModalVisible(false)
                    setIsSaving(false)
                    fetchArchiveLocation()
                }).catch((err) => {
                    message.warning(err.response.data.message)
                    setIsSaving(false)
                })
        } else {
            setIsSaving(true)
            ApiService.update(`archive-location`, el.id, payload)
                .then((res) => {
                    message.success(res.message, 2)
                    setIsModalVisible(false)
                    setIsSaving(false)
                    fetchArchiveLocation()
                }).catch((err) => {
                    message.warning(err.response.data.message)
                    setIsSaving(false)
                })
        }
    }

    useEffect(() => {
        fetchArchiveLocation()

    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Archive Location',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code'
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => edit(row)} />
                        <DeleteButton onConfirm={() => destroy(row.id)} />
                    </Space>
                </div>
            ),
            key: 'action'
        },
    ];

    return (
        <div>
            <PageHeader title="Archive Location" breadcrumbs={[['Master'], ['Archive Location']]} onAdd={add} />
            <Card style={{ borderRadius: 10 }}>

                {
                    (loaded) ?
                        <DataTable size="small" columns={columns} sources={archiveLocation} bordered />
                        : <Spinner />
                }

                {
                    (isModalVisible) ?
                        <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => onFinish(v)} width={600} showModal={isModalVisible} onCloseModal={() => setIsModalVisible(false)} saving={isSaving}>
                            <Form.Item name="id" hidden>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Archive Location" name="name" required rules={[{ required: true }]}>
                                <Input placeholder="Archive Location" />
                            </Form.Item>
                            <Form.Item label="Code" name="code" required rules={[{ required: true }]}>
                                <Input placeholder="Code" />
                            </Form.Item>
                        </FormModal> :
                        null
                }
            </Card>
        </div>
    )
}

export default LokasiArsip
