import { Button, Image, Typography } from 'antd';
import IcFolder from '../assets/images/ic_folder.png'
import IcPdf from '../assets/images/ic_pdf.png'
import IcWord from '../assets/images/ic_word.png'
import IcExcel from '../assets/images/ic_xls.png'
import IcPpt from '../assets/images/ic_ppt.png'
import IcImage from '../assets/images/ic_images.png'

const { Text } = Typography

const Folder = (props) => {
    return (
        <div>
            <Button onClick={props.onClick} type='link' size="large" style={{ width: props.buttonWidth, height: props.buttonHeight, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Image src={IcFolder} preview={false} width={props.width} />
                <Text style={{ margin: 0, fontSize: 12 }}>
                    {
                        props.title.length > 10 ? `${props.title.substring(0, 10)}...` : props.title
                    }
                </Text>
            </Button>
        </div>
    )
}

const Pdf = (props) => {
    return (
        <div>
            {
                props.title ?
                    <Button onClick={props.onClick} type='link' size="large" style={{ width: props.buttonWidth, height: props.buttonHeight, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <Image src={IcPdf} preview={false} width={props.width} />
                        <Text style={{ margin: 0, fontSize: 12 }}>
                            {
                                props.title.length > 10 ? `${props.title.substring(0, 10)}...` : props.title
                            }
                        </Text>
                    </Button>
                    :
                    <Button onClick={props.onClick} type='link' size="large" style={{ width: props.buttonWidth, height: props.buttonHeight, alignItems: 'center', justifyContent: 'center', zIndex: 1 }}>
                        <Image src={IcPdf} preview={false} width={props.width} />
                    </Button>
            }
        </div>
    )
}

const Docs = (props) => {
    return (
        <div>
            {
                props.title ?
                    <Button onClick={props.onClick} type='link' size="large" style={{ width: props.buttonWidth, height: props.buttonHeight, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <Image src={IcWord} preview={false} width={50} />
                        <Text style={{ margin: 0, fontSize: 12 }}>
                            {
                                props.title.length > 10 ? `${props.title.substring(0, 10)}...` : props.title
                            }
                        </Text>
                    </Button>
                    :
                    <Button onClick={props.onClick} type='link' size="large" style={{ width: props.buttonWidth, height: props.buttonHeight, alignItems: 'center', justifyContent: 'center', zIndex: 1 }}>
                        <Image src={IcWord} preview={false} width={props.width} />
                    </Button>
            }
        </div>
    )
}

const Excel = (props) => {
    return (
        <div>
            {
                props.title ?
                    <Button onClick={props.onClick} type='link' size="large" style={{ width: props.buttonWidth, height: props.buttonHeight, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <Image src={IcExcel} preview={false} width={props.width} />
                        <Text style={{ margin: 0, fontSize: 12 }}>
                            {
                                props.title.length > 10 ? `${props.title.substring(0, 10)}...` : props.title
                            }
                        </Text>
                    </Button>
                    :
                    <Button onClick={props.onClick} type='link' size="large" style={{ width: props.buttonWidth, height: props.buttonHeight, alignItems: 'center', justifyContent: 'center', zIndex: 1 }}>
                        <Image src={IcExcel} preview={false} width={props.width} />
                    </Button>
            }
        </div>
    )
}

const Ppt = (props) => {
    return (
        <div>
            {
                props.title ?
                    <Button onClick={props.onClick} type='link' size="large" style={{ width: props.buttonWidth, height: props.buttonHeight, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <Image src={IcPpt} preview={false} width={props.width} />
                        <Text style={{ margin: 0, fontSize: 12 }}>
                            {
                                props.title.length > 10 ? `${props.title.substring(0, 10)}...` : props.title
                            }
                        </Text>
                    </Button>
                    :
                    <Button onClick={props.onClick} type='link' size="large" style={{ width: '120px', height: '70px', alignItems: 'center', justifyContent: 'center', zIndex: 1 }}>
                        <Image src={IcPpt} preview={false} width={50} />
                    </Button>
            }
        </div>
    )
}

const Images = (props) => {
    return (
        <div>
            {
                props.title ?
                    <Button onClick={props.onClick} type='link' size="large" style={{ width: props.buttonWidth, height: props.buttonHeight, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <Image src={IcImage} preview={false} width={props.width} />
                        <Text style={{ margin: 0, fontSize: 12 }}>
                            {
                                props.title.length > 10 ? `${props.title.substring(0, 10)}...` : props.title
                            }
                        </Text>
                    </Button>
                    :
                    <Button onClick={props.onClick} type='link' size="large" style={{ width: props.buttonWidth, height: props.buttonHeight, alignItems: 'center', justifyContent: 'center', zIndex: 1 }}>
                        <Image src={IcImage} preview={false} width={props.width} />
                    </Button>
            }
        </div>
    )
}

export { Folder, Pdf, Docs, Excel, Ppt, Images }