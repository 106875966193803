// import { useState, useEffect } from 'react'
// import { useParams } from 'react-router-dom'
// import { ApiService } from '../../../services/ApiService'
// import { AddButton, DeleteButton, EditButton, PageHeader, Spinner } from '../../../components'
// import { Card, Descriptions, Typography } from 'antd'

// const {Text} = Typography

// const Detail = () => {
//     const { schemeId, directorateId } = useParams()
//     const [formModal, setFormModal] = useState(false)
//     const [modalState, setModalState] = useState('add')
//     const [saving, setSaving] = useState(false)
//     const [scheme, setScheme] = useState(null)
//     const [approvers, setApprovers] = useState([])
//     const [defaultValues, setDefaultValues] = useState({})
//     const [employees, setEmployees] = useState([])
//     const [loaded, setLoaded] = useState(false)

//     const fetchApproval = (schemeId, directorateId) => {
//         ApiService.getList(`approver-scheme-detail/${schemeId}/${directorateId}`).then((res) => {
//             setScheme({
//                 ...res.data.scheme,
//                 directorate: res.data.directorate
//             })
//             setApprovers(res.data.approver)
//             setLoaded(true)
//         })
//     }

//     useEffect(() => {
//         fetchApproval(schemeId, directorateId)
//     }, [schemeId, directorateId])

//     const addApprover = () =>{
//         setModalState('add')
//         setDefaultValues({id:0,level : approvers.length+1, approver: null})
//         setFormModal(true)
//     }

//     const editApprover = (v) => {
//         setModalState('edit')
//         setDefaultValues({id:v.approval_approver_id, level : v.level, approver: v.employee_code})
//         setFormModal(true)
//     }

//     const updateApprover= (v) =>{
//         setSaving(true)
//         // const approverData = {
//         //     approval_schema_id:getQuery('schema'),
//         //     division_id:getQuery('division'),
//         //     employee_code: v.approver,
//         //     level:v.level,
//         // }
//         // SentralModel.save('ApprovalApprover', approverData, v.id).then(()=>{
//         //     message.success('Schema Updated', 2)
//         //     setFormModal(false)
//         //     setSaving(false)
//         //     fetchApprovalData(getQuery('division'),getQuery('schema'))
//         // })
//     }
    
//     const removeApprover = (v) =>{
//         // setLoaded(false)
//         // SentralModel.action('Approval','removeApprover', {id:v.approval_approver_id}, 0).then(() => {
//         //     message.success('Schema Updated', 2)
//         //     fetchApprovalData(getQuery('division'),getQuery('schema'))
//         // })
//     }

//     return (
//         <div>
//             <PageHeader title={"Approval Approver Detail"} breadcrumbs={[['Approval Approver', '/approval-approver'], ["Detail"]]} />
//             <Card style={{ borderRadius: 10 }}>
//                 {
//                     loaded ?
//                         <>
//                             <Descriptions layout="horizontal" column={1} bordered>
//                                 <Descriptions.Item label={<span className="text-bold">Scheme</span>}>{(scheme) ? scheme.description : null}</Descriptions.Item>
//                                 <Descriptions.Item label={<span className="text-bold">Code</span>}>{(scheme) ? scheme.code : null}</Descriptions.Item>
//                                 <Descriptions.Item label={<span className="text-bold">Directorate</span>}>{(scheme) ? scheme.directorate.directorate_name : null}</Descriptions.Item>
//                             </Descriptions>

//                             <Card title={
//                                 <div className="full-width">
//                                     <Text strong style={{float:'left'}}>Approver</Text>
//                                     <AddButton right onAdd={() => addApprover()} title="Add Approver"/>
//                                 </div>
//                             } bordered className="full-width">
//                                 <table className="table-default table-collapse">
//                                     <thead>
//                                         <tr>
//                                             <th style={{width:'7%'}}>Level</th>
//                                             <th>Approver</th>
//                                             <th  style={{width:'7%'}}>Action</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         {
//                                             approvers.map((el, key) => (
//                                                 <tr key={key}>
//                                                     <td className="text-center text-top" style={{width:'5%'}}>{el.level}</td>
//                                                     <td>{el.user.name}</td>
//                                                     <td className="text-center" style={{width:'10%'}}>
//                                                         <EditButton onEdit={() => editApprover(el)} />
//                                                         <DeleteButton onConfirm={() => removeApprover(el)} />
//                                                     </td>
//                                                 </tr>
//                                             ))
//                                         }
                                        
//                                     </tbody>
//                                 </table>
                            
//                             </Card>
//                         </>
//                     : <Spinner />
//                 }
//             </Card>
//         </div>
//     )
// }

// export default Detail