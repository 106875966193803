import { useState, useEffect } from 'react'
import { Button, Card, Col, Form, Image, Row, Space, Tree, Typography, message, Dropdown } from 'antd'
import { ApiService } from '../../../../services/ApiService'
import { Docs, Excel, Folder, Images, Pdf, Ppt } from '../../../../components/Folder';
import EmptyFolderImage from '../../../../assets/images/empty-folder.png'
import { currentUser, role } from '../../../../utils/authData';
import { useDispatch, useSelector } from 'react-redux'
import {
    selectTree,
    selectFile,
    changeIsSavingFile,
    fetchFolderShare
} from "../../../../redux/slice/fileManagerSlice";

const { DirectoryTree } = Tree;
const { Text } = Typography;
const fileUrl = process.env.REACT_APP_FILE_URL;

const FolderShare = () => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const user = useSelector((state) => state.global.user)
    const fileNameUrl = useSelector((state) => state.fileManager.fileNameUrl)
    const fileId = useSelector((state) => state.fileManager.fileId)
    const isFile = useSelector((state) => state.fileManager.isFile)
    const loadedShare = useSelector((state) => state.fileManager.loadedShare)
    const folderShare = useSelector((state) => state.fileManager.folderShare)
    const [inTree, setInTree] = useState([])

    const fetchInFolder = (v) => {
        let params = {
            role: role,
            user_id: user.id
        }
        ApiService.getObject('infolder', v, params).then((res) => {
            setInTree(res.data)
        })
    }

    useEffect(() => {
        dispatch(fetchFolderShare(currentUser.division_id))

        // eslint-disable-next-line
    }, []);

    const onSelect = (selectedKeys, info) => {
        const v = info.node
        form.resetFields(['search_file']);
        form.setFieldsValue({ name: v.name })
        dispatch(selectTree(v))

        if (v.type === 'FOLDER') {
            fetchInFolder(v.root_id)
        }
    }

    const downloadFile = () => {
        let payload = {
            user_id: user.id,
            file_id: fileId,
            description: 'Access File',
            filename: fileNameUrl,
            document_from: 'WEB'
        }

        ApiService.store('file-log', payload).then((res) => {
            message.success(res.message)
            dispatch(changeIsSavingFile(false))
            window.open(`${fileUrl}/${fileNameUrl}`)
        }).catch(() => {
            message.warning('Failed save data')
        })
    }

    const accesDoc = (v) => {
        window.open(`${fileUrl}/${v.name}`)
    }

    const items = (v) => {
        return (
            <div style={{ backgroundColor: 'white', padding: 5, borderRadius: 5, border: '1px solid #e8e8e8', width: 150 }}>
                {
                    <Row className="mb-1">
                        <a href="!#" className="a-link" onClick={() => accesDoc(v)} >
                            Open New Tab
                        </a>
                    </Row>
                }
            </div>
        )
    }

    return (
        <div>
            <Card style={{ borderRadius: 10 }} title={
                <Row justify='start'>
                    <Col>
                        <Text>Folder Share</Text>
                    </Col>
                </Row>
            }>
                <Row justify='space-between'>
                    <Col span={8}>
                        <Card>
                            {
                                loadedShare ?
                                    folderShare.length > 0 ?
                                        <DirectoryTree
                                            onSelect={onSelect}
                                            treeData={folderShare}
                                            titleRender={(node) => {
                                                const { title, type } = node;
                                                return (
                                                    <>
                                                        {
                                                            type === 'FILE' ?
                                                                <Dropdown overlay={items(node)} trigger={["contextMenu"]}>
                                                                    <span>{title}</span>
                                                                </Dropdown>
                                                                : <span>{title}</span>
                                                        }
                                                    </>
                                                )
                                            }}
                                        /> : <p>Folder Not Found</p>
                                    : null
                            }
                        </Card>
                    </Col>
                    <Col span={15}>
                        <Card>
                            {
                                !isFile ?
                                    inTree.length !== 0 ?
                                        <Row>
                                            {
                                                inTree.map((el) => (
                                                    <Col span={6}>
                                                        {
                                                            el.type === 'FOLDER' ?
                                                                <Folder width={50} buttonHeight={70} onClick={() => fetchInFolder(el.root_id)} title={el.name} />
                                                                :
                                                                el.name.includes(".pdf") ?
                                                                    <Pdf width={50} buttonHeight={70} onClick={() => dispatch(selectFile({ name: el.name, id: el.file_origins_id }))} title={el.original_filename ?? `${el.name.split('/')[1].substring(0, 10)}...`} />
                                                                    :
                                                                    el.name.includes(".pptx") || el.name.includes(".ppt") ?
                                                                        <Ppt width={50} buttonHeight={70} onClick={() => dispatch(selectFile({ name: el.name, id: el.file_origins_id }))} title={el.original_filename ?? `${el.name.split('/')[1].substring(0, 10)}...`} />
                                                                        :
                                                                        el.name.includes(".docx") || el.name.includes(".doc") ?
                                                                            <Docs width={50} buttonHeight={70} onClick={() => dispatch(selectFile({ name: el.name, id: el.file_origins_id }))} title={el.original_filename ?? `${el.name.split('/')[1].substring(0, 10)}...`} />
                                                                            :
                                                                            el.name.includes(".xlsx") || el.name.includes(".xls") ?
                                                                                <Excel width={50} buttonHeight={70} onClick={() => dispatch(selectFile({ name: el.name, id: el.file_origins_id }))} title={el.original_filename ?? `${el.name.split('/')[1].substring(0, 10)}...`} />
                                                                                :
                                                                                <Images width={50} buttonHeight={70} onClick={() => dispatch(selectFile({ name: el.name, id: el.file_origins_id }))} title={el.original_filename ?? `${el.name.split('/')[1].substring(0, 10)}...`} />
                                                        }
                                                    </Col>
                                                ))
                                            }
                                        </Row>
                                        :
                                        <div className='text-center'>
                                            <Image src={EmptyFolderImage} preview={false} width={150} />
                                            <p style={{ fontSize: 18, fontWeight: 'bold', color: 'black' }}>Folder or File Not Found</p>
                                        </div>
                                    :
                                    <div>
                                        <Row justify='end' style={{ marginBottom: 20 }}>
                                            <Space>
                                                {
                                                    fileNameUrl.includes(".docx") || fileNameUrl.includes(".doc") ||
                                                        fileNameUrl.includes(".xlsx") || fileNameUrl.includes(".xls") ||
                                                        fileNameUrl.includes(".pptx") || fileNameUrl.includes(".ppt") ?
                                                        null :
                                                        <Button type="primary" onClick={downloadFile}>Download</Button>
                                                }
                                            </Space>
                                        </Row>

                                        {
                                            fileNameUrl.includes('.pdf') || fileNameUrl.includes('.jpg') || fileNameUrl.includes(".jpeg") || fileNameUrl.includes('.png') ?
                                                <Row>
                                                    <iframe src={`${fileUrl}/${fileNameUrl}`} width="730" height="950" title="Document Review" />
                                                </Row>
                                                :
                                                <Card style={{ borderRadius: 10 }}>
                                                    <Row justify='center' className='mb-3'>
                                                        <div className='text-center'>
                                                            <h1 style={{ fontSize: 22, fontWeight: 600 }}>Dokumen Tidak dapat Ditampilkan</h1>
                                                            <p>Download file untuk melihat dokumen</p>
                                                        </div>
                                                    </Row>
                                                    <Row justify='center'>
                                                        <div>
                                                            <Button type='primary' onClick={downloadFile}>Download</Button>
                                                        </div>
                                                    </Row>
                                                </Card>
                                        }
                                    </div>
                            }
                        </Card>
                    </Col>
                </Row >
            </Card >
        </div >
    )
}

export default FolderShare