import { useState, useEffect } from 'react'
import { Card, Col, Form, Row, Select, message, Divider, Button, DatePicker, Input, TimePicker, Checkbox } from 'antd'
import { useParams, useHistory } from 'react-router-dom'
import { ApiService } from '../../../services/ApiService'
import dayjs from 'dayjs'
import { Spinner, PageHeader } from '../../../components'
import SunEditor from 'suneditor-react'
import {
    align,
    font,
    fontColor,
    fontSize,
    formatBlock,
    hiliteColor,
    horizontalRule,
    lineHeight,
    list,
    paragraphStyle,
    table,
    template,
    textStyle,
    image,
    link,
} from 'suneditor/src/plugins'

const { RangePicker } = TimePicker

const Create = () => {
    const { id } = useParams()
    const history = useHistory()
    const [form] = Form.useForm()
    const [loaded, setLoaded] = useState(true)
    const [disableDate, setDisableDate] = useState(true)
    const [listTemplate, setListTemplate] = useState([])
    const [content, setContent] = useState("")

    const fetchOutgoingLetter = () => {
        setLoaded(false)
        ApiService.getObject("outgoing-letter", id).then((res) => {
            form.setFieldsValue({
                document_number: res.data.doc.document_number,
                outgoing_letter_date: dayjs(res.data.doc.outgoing_letter_date),
                recipent: res.data.doc.recipent,
                recipent_job_title: res.data.doc.recipent_job_title,
                recipent_company: res.data.doc.recipent_company,
                recipent_company_address: res.data.doc.recipent_company_address,
                subject: res.data.doc.subject,
                event_date: dayjs(res.data.doc.event_date),
                event_time: [dayjs(res.data.doc.time_start, "HH:mm"), dayjs(res.data.doc.time_end, "HH:mm")],
                event_location: res.data.doc.event_location,
                event_agenda: res.data.doc.event_agenda
            })
            setContent(res.data.doc.body_content)
            setLoaded(true)
        }).catch((err) => {
            message.warning("Error")
        })
    }

    const fetchTemplate = (v) => {
        ApiService.getObject("document-template-by-type", 2).then((res) => {
            const v = res.data
            let arr = []

            v?.forEach((el) => {
                arr.push({ value: el.content, label: el.template_name })
            })

            setListTemplate(arr)
        })
    }

    const fetchDocumentNumber = (v) => {
        ApiService.store("generate-number", { id: 2, date: v }).then((res) => {
            const data = res.data
            let document_number = data

            form.setFieldsValue({
                outgoing_letter_date: dayjs(v),
                document_number_id: v.document_number_id,
                document_number: document_number,
                sequence: v.sequence
            })
        })
    }

    useEffect(() => {
        if (parseInt(id) !== 0) {
            fetchOutgoingLetter()
        }

        fetchTemplate()

        if (parseInt(id) === 0) {
            fetchDocumentNumber(Date.now())
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const onFinish = (v) => {
        let payload = {
            ...v,
            document_type_id: 7,
            body_content: content,
            time_start: dayjs(v.event_time[0].$d).format("HH:mm"),
            time_end: dayjs(v.event_time[1].$d).format("HH:mm")
        }

        if (parseInt(id) === 0) {
            ApiService.store("outgoing-letter", payload).then((res) => {
                message.success("Success Add Outgoing Letter")
                setTimeout(() => {
                    history.push("/document-workflow/outgoing-letter")
                }, 500)
            }).catch((err) => {
                message.warning("Failed Add Outgoing Letter")
            })
        } else {
            ApiService.update("outgoing-letter", id, payload).then((res) => {
                message.success("Success Update Outgoing Letter")
                setTimeout(() => {
                    history.push("/document-workflow/outgoing-letter")
                }, 500)
            }).catch((err) => {
                message.warning("Failed Update Outgoing Letter")
            })

        }
    }

    const onChangeDate = (date, dateString) => {
        fetchDocumentNumber(dayjs(dateString))
    }

    const onChangeBackdate = () => {
        setDisableDate(disableDate ? false : true)
        form.setFieldsValue({
            memo_date: dayjs(Date.now())
        })
    }

    return (
        <div>
            <PageHeader title={`${parseInt(id) === 0 ? "Create" : "Update"} Outgoing Letter`} breadcrumbs={[['Outgoing Letter', '/document-workflow/outgoing-letter'], [parseInt(id) === 0 ? "Create" : "Update"]]} />
            <Card style={{ borderRadius: 10 }}>
                <div style={{ marginBottom: 20 }}>
                    <div>
                        <Row>
                            <Col span={8}>
                                <Select
                                    showSearch
                                    placeholder="Select Document Template"
                                    options={listTemplate}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    onChange={(v) => setContent(v)}
                                    style={{ width: "100%" }}
                                />
                            </Col>
                        </Row>
                    </div>
                    <Divider />
                </div>

                {
                    loaded ?
                        <Form layout="vertical" form={form} onFinish={onFinish}>
                            <Form.Item name="sequence" hidden>
                                <Input />
                            </Form.Item>
                            <Form.Item name="document_number_id" hidden>
                                <Input />
                            </Form.Item>
                            <Row justify="space-between">
                                <Col span={11}>
                                    <Form.Item label="Document Number" name="document_number" required rules={[{ required: true }]}>
                                        <Input disabled={true} placeholder='Document Number' />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Row justify="space-between">
                                        <Col span={11}>
                                            <Form.Item label="Outgoing Letter Date" name="outgoing_letter_date" required rules={[{ required: true }]}>
                                                <DatePicker allowClear disabled={disableDate} onChange={onChangeDate} style={{ width: "100%" }} />
                                            </Form.Item>
                                        </Col>
                                        {
                                            parseInt(id) === 0 ?
                                                <Col span={11}>
                                                    <Form.Item label="Backdate">
                                                        <Checkbox value={disableDate ? false : true} onChange={onChangeBackdate}>{disableDate ? "No" : "Yes"}</Checkbox>
                                                    </Form.Item>
                                                </Col>
                                                : null
                                        }
                                    </Row>
                                </Col>
                            </Row>
                            <Row justify="space-between">
                                <Col span={11}>
                                    <Form.Item label="Recipent" name="recipent" required rules={[{ required: true }]}>
                                        <Input placeholder="Recipent" />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Row justify="space-between">
                                        <Col span={11}>
                                            <Form.Item label="Recipent Job Title" name="recipent_job_title" required rules={[{ required: true }]}>
                                                <Input placeholder="Recipent Job Title" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={11}>
                                            <Form.Item label="Recipent Company" name="recipent_company" required rules={[{ required: true }]}>
                                                <Input placeholder="Recipent Company" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row justify="space-between">
                                <Col span={11}>
                                    <Form.Item label="Subject" name="subject" required rules={[{ required: true }]}>
                                        <Input.TextArea placeholder="Subject" rows={3} />
                                    </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item label="Recipent Company Address" name="recipent_company_address" required rules={[{ required: true }]}>
                                        <Input.TextArea placeholder="Recipent Company Address" rows={3} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify="space-between">
                                <Col span={11}>
                                    <Row justify="space-between">
                                        <Col span={11}>
                                            <Form.Item label="Event Date" name="event_date" required rules={[{ required: true }]}>
                                                <DatePicker style={{ width: "100%" }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={11}>
                                            <Form.Item label="Event Time" name="event_time" required rules={[{ required: true }]}>
                                                <RangePicker format="HH:mm" allowClear />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={11}>
                                    <Row justify="space-between">
                                        <Col span={11}>
                                            <Form.Item label="Event Location" name="event_location" required rules={[{ required: true }]}>
                                                <Input placeholder="Event Location" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={11}>
                                            <Form.Item label="Event Agenda" name="event_agenda" required rules={[{ required: true }]}>
                                                <Input placeholder="Event Agenda" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item label="Body Template">
                                        <SunEditor setOptions={{
                                            showPathLabel: false,
                                            minHeight: "100vh",
                                            maxHeight: "100vh",
                                            placeholder: "Enter your text here!!!",
                                            plugins: [
                                                align,
                                                font,
                                                fontColor,
                                                fontSize,
                                                formatBlock,
                                                hiliteColor,
                                                horizontalRule,
                                                lineHeight,
                                                list,
                                                paragraphStyle,
                                                table,
                                                template,
                                                textStyle,
                                                image,
                                                link
                                            ],
                                            buttonList: [
                                                ["undo", "redo"],
                                                ["font", "fontSize", "formatBlock"],
                                                ["paragraphStyle"],
                                                [
                                                    "bold",
                                                    "underline",
                                                    "italic",
                                                    "strike",
                                                    "subscript",
                                                    "superscript"
                                                ],
                                                ["fontColor", "hiliteColor"],
                                                ["removeFormat"],
                                                "/", // Line break
                                                ["outdent", "indent"],
                                                ["align", "horizontalRule", "list", "lineHeight"],
                                                ["table", "link", "image"]
                                            ],
                                            formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                                            font: [
                                                "Arial",
                                                "Calibri",
                                                "Comic Sans",
                                                "Courier",
                                                "Garamond",
                                                "Georgia",
                                                "Impact",
                                                "Lucida Console",
                                                "Palatino Linotype",
                                                "Segoe UI",
                                                "Tahoma",
                                                "Times New Roman",
                                                "Trebuchet MS"
                                            ]
                                        }} height={500} setContents={content} onChange={(value) => setContent(value)} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">Save</Button>
                            </Form.Item>
                        </Form>
                        : <Spinner />
                }
            </Card>
        </div>
    )
}

export default Create