import React, { useState, useEffect } from 'react';
import { Card, Form, Typography, Tabs, InputNumber, Button, message } from 'antd';
import { PageHeader, Spinner } from '../../components';
import { ApiService } from '../../services/ApiService';
import { currentUser } from '../../utils/authData';

const { TabPane } = Tabs;
const { Text } = Typography;

const Profile = () => {
    const [form] = Form.useForm()
    const [isSaving, setIsSaving] = useState(false)

    const onFinish = (el) => {
        setIsSaving(true)
        if (el.pin !== el.confirm_pin) {
            message.warning('Your pin is not match')
        } else {
            ApiService.update(`change-pin`, currentUser.employee_code, { pin: el.pin })
                .then((res) => {
                    message.success('Success Update Pin')
                    setIsSaving(false)
                    form.setFieldsValue({
                        pin: '',
                        confirm_pin: ''
                    })
                }).catch((err) => {
                    message.warning(err.response.data.message)
                    console.log(err)
                })
        }
    }

    useEffect(() => {
        console.log(currentUser)
    }, []);

    return (
        <div>
            <PageHeader title="My Profile" breadcrumbs={[['Profile']]} />

            <Card style={{ borderRadius: 10 }}>
                <Tabs>
                    <TabPane tab="Change Pin For Printer" key="profile">
                        <div style={{ marginBottom: 10 }}>
                            <Form form={form} layout='vertical' onFinish={onFinish}>
                                <Form.Item label="PIN" name="pin">
                                    <InputNumber placeholder='PIN' style={{ width: '50%' }} />
                                </Form.Item>
                                <Form.Item label="Confirm PIN" name="confirm_pin">
                                    <InputNumber placeholder='Confirm PIN' style={{ width: '50%' }} />
                                </Form.Item>
                                <Button type='primary' htmlType='submit'>
                                    {
                                        isSaving ? <Spinner />
                                            :
                                            "Submit"
                                    }
                                </Button>
                            </Form>
                        </div>
                        <div>
                            <Text>(<b style={{ color: 'red' }}>*</b>) Pin ini digunakan untuk login ke device printer</Text>
                        </div>
                    </TabPane>
                </Tabs>
            </Card>
        </div>
    );
};

export default Profile;
