import { Card, Tabs } from 'antd'
import { PageHeader } from '../../components'
// import MyFolder from './tabs/my-folder'
import MyFolder from './tabs/my-folder-new/index'
import SharingDocument from './tabs/sharing'
import FolderShare from './tabs/folder-share'

const FileManager = () => {
    return (
        <div>
            <PageHeader title=" File Manager" breadcrumbs={[['File Manager']]} />
            <Card style={{ borderRadius: 10 }}>
                <Tabs type='card' defaultActiveKey="my_folder"
                    items={[
                        {
                            label: "My Folder",
                            key: "my_folder",
                            children: <MyFolder />
                        },
                        {
                            label: "Document Sharing",
                            key: "sharing_document",
                            children: <SharingDocument />
                        },
                        {
                            label: "Folder Sharing",
                            key: "folder_sharing",
                            children: <FolderShare />
                        }
                    ]} />
            </Card>
        </div>
    )
}

export default FileManager