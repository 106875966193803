import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ApiService } from '../../../services/ApiService'
import { AddButton, BasicModal, DataTable, DeleteButton, EditButton, FormModal, PageHeader, Spinner } from '../../../components'
import { Card, Descriptions, Space, message, Form, Input, Select, Button, Row } from 'antd'
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons'

const { Option } = Select

const Detail = () => {
    const { id } = useParams()
    const [document, setDocument] = useState([])
    const [divisions, setDivision] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [selectedDivision, setSelectedDivision] = useState(0)
    const [approvers, setApprovers] = useState([])
    const [approverLength, setApproverLength] = useState(0)
    const [formModal, setFormModal] = useState(false)
    const [saving, setSaving] = useState(false)
    const [modalState, setModalState] = useState('add')
    const [defaultValues, setDefaultValues] = useState({})
    const [users, setUsers] = useState([])

    const fetchApproval = (id) => {
        ApiService.getList(`document-scheme/${id}`).then((res) => {
            setDocument(res.data.document)
            setDivision(res.data.division)
            setLoaded(true)
        })
    }

    const fetchApprover = () => {
        ApiService.getList(`approval-user`).then((res) => {
            console.log(res.data)
            setUsers(res.data)
            setLoaded(true)
        })
    }

    const fetchApprovalApprover = (documentTypeId, divisionId) => {
        ApiService.getList(`document-scheme/${documentTypeId}/${divisionId}`).then((res) => {
            setApprovers(res.data)
            setApproverLength(res.data.length)
        })
    }

    useEffect(() => {
        fetchApproval(id)
        fetchApprover()
    }, [id])

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Division',
            dataIndex: 'division_name',
            key: 'division_name',
            width: '35%'
        },
        {
            title: 'Approver',
            render: (row) => (
                <ol style={{ paddingLeft: '1em', marginBottom: 0 }}>
                    {
                        row.approver.map((el, key) => (
                            <li key={'approver_' + key}>{el.user ? el.user.name : null}</li>
                        ))
                    }
                </ol>
            )
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editApprover(row)} />
                    </Space>
                </div>
            ),
            key: 'action',
            align: 'center'
        },
    ];

    const approverColumns = [
        {
            title: 'Level',
            width: '15%',
            render: (row) => (
                <div>
                    <Row justify='space-around'>
                        <Button type="link" size='small' shape="circle" icon={<CaretUpOutlined />} style={{ visibility: (row.level === 1) ? 'hidden' : 'visible' }} onClick={() => levelUp(row.approval_approver_id, row.level)} />
                        {row.level}
                        <Button type="link" size='small' shape="circle" icon={<CaretDownOutlined />} style={{ visibility: (row.level === approverLength) ? 'hidden' : 'visible' }} onClick={() => levelDown(row.approval_approver_id, row.level)} />
                    </Row>
                </div>
            )
        },
        {
            title: 'Approver Name',
            render: (row) => (
                <div>
                    {row.user ? row.user.name : ''}
                </div>
            )
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => updateApprover(row)} />
                        <DeleteButton onConfirm={() => removeApprover(row.approval_approver_id)} />
                    </Space>
                </div>
            )
        },
    ];

    const editApprover = (v) => {
        setShowModal(true)
        setSelectedDivision(v.division_id)
        setApprovers(v.approver)
        setApproverLength(v.approver.length)
        fetchApprovalApprover(id, v.division_id)
    }

    const addApprover = () => {
        setModalState('add')
        setDefaultValues({
            approval_approver_id: 0,
            // level : approvers.length+1, 
            approver: null
        })
        setFormModal(true)
    }

    const updateApprover = (v) => {
        setModalState('edit')
        setDefaultValues({
            approval_approver_id: v.approval_approver_id,
            // level : v.level,
            approver: v.user.id
        })
        setFormModal(true)
    }

    const saveApprover = (el) => {
        let payload = {
            document_type_id: id,
            division_id: selectedDivision,
            user_id: el.approver,
        }

        if (el.approval_approver_id === 0) {
            setSaving(true)
            ApiService.store(`approval-approver`, payload)
                .then((res) => {
                    message.success("Success create approver", 2)
                    setFormModal(false)
                    setSaving(false)
                    fetchApprovalApprover(id, selectedDivision)
                    fetchApproval(id)
                }).catch((err) => {
                    message.warning("Failed create approver")
                    setSaving(false)
                })
        } else {
            setSaving(true)
            ApiService.update(`approval-approver`, el.approval_approver_id, payload)
                .then((res) => {
                    message.success("Success update approver", 2)
                    setFormModal(false)
                    setSaving(false)
                    fetchApprovalApprover(id, selectedDivision)
                    fetchApproval(id)
                }).catch((err) => {
                    message.warning("Failed update approver")
                    setSaving(false)
                })
        }
    }

    const removeApprover = (v) => {
        ApiService.destroy("approval-approver", v).then((res) => {
            message.success("Success delete approver", 2)
            fetchApprovalApprover(id, selectedDivision)
            fetchApproval(id)
        }).catch((err) => {
            message.warning("Failed delete approver", 2)
        })
    }

    const levelUp = (approverId, level) => {
        let payload = {
            level: level
        }

        setSaving(true)
        ApiService.update(`approval-approver-up`, approverId, payload)
            .then((res) => {
                // message.success("Success update approver", 2)
                setSaving(false)
                fetchApprovalApprover(id, selectedDivision)
                fetchApproval(id)
            }).catch((err) => {
                // message.warning("Failed update approver")
                setSaving(false)
            })
    }

    const levelDown = (approverId, level) => {
        let payload = {
            level: level
        }

        setSaving(true)
        ApiService.update(`approval-approver-down`, approverId, payload)
            .then((res) => {
                // message.success("Success update approver", 2)
                setSaving(false)
                fetchApprovalApprover(id, selectedDivision)
                fetchApproval(id)
            }).catch((err) => {
                // message.warning("Failed update approver")
                setSaving(false)
            })
    }

    return (
        <div>
            <PageHeader title={"Document Type Scheme"} breadcrumbs={[['Master'], ['Document Type', '/master/document-type'], ["Scheme"]]} />
            <Card style={{ borderRadius: 10 }}>
                {
                    loaded ?
                        <>
                            <Descriptions layout="horizontal" column={1} bordered>
                                <Descriptions.Item label={<span className="text-bold">Document Type</span>}>{(document) ? document.document_type_name : null}</Descriptions.Item>
                                <Descriptions.Item label={<span className="text-bold">Code</span>}>{(document) ? document.code : null}</Descriptions.Item>
                            </Descriptions>

                            <br />
                            <DataTable sources={divisions} columns={columns} bordered size="small" />
                        </>
                        : <Spinner />
                }

                {
                    <BasicModal title="Approver List" width={800} showModal={showModal} onCloseModal={() => setShowModal(false)}>
                        <AddButton right onAdd={() => addApprover()} title="Add Approver" /><br /><br />
                        <DataTable sources={approvers} columns={approverColumns} bordered size="small" search={false} />
                    </BasicModal>
                }

                {
                    (formModal) ?
                        <FormModal defaultValues={defaultValues} title={(modalState === 'add') ? 'Add Approver' : 'Edit Approver'} submitForm={(v) => saveApprover(v)} width={500} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                            <Form.Item name="approval_approver_id" hidden>
                                <Input />
                            </Form.Item>
                            {/* <Form.Item label="Level" name="level" rules={[{required:true}]}>
                            <InputNumber min={1} max={approvers.length+1} placeholder="input placeholder" readOnly={modalState==='add'} style={{width:'100%'}}/>
                        </Form.Item> */}
                            <Form.Item label="Approver Name" name="approver" rules={[{ required: true }]}>
                                <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {
                                        users.map((el, key) => {
                                            if (!approvers.some(appr => appr.user.id === el.id) || (defaultValues.approval_approver_id !== 0 && defaultValues.approver === el.id)) {
                                                return <Option key={key} value={el.id}>{el.name}</Option>
                                            }
                                            return null
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </FormModal>
                        : null
                }
            </Card>
        </div>
    )
}

export default Detail