import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import AppProvider from '../components/shared/AppProvider';
import AppContainer from './app';

// Home
import Home from './Home/index'

// Approval
import ApprovalSchema from './Approval/ApprovalScheme/index'
import ApprovalApprover from './Approval/ApprovalApprover/index'
import ApprovalApproverDetail from './Approval/ApprovalApprover/detail'
import ApprovalPool from './Approval/ApprovalPool/index'
import ApprovalPoolMemo from './Approval/ApprovalPool/detail-internal-memo'
import ApprovalPoolOutgoing from './Approval/ApprovalPool/detail-outgoing-letter'

// InternalMemo
import InternalMemo from './DocumentWorkflow/InternalMemo/index';
import InternalMemoDetail from './DocumentWorkflow/InternalMemo/detail';
import CreateInternalMemo from './DocumentWorkflow/InternalMemo/create';

// Outgoing Letter
import OutgoingLetter from './DocumentWorkflow/OutgoingLetter/index';
import OutgoingLetterDetail from './DocumentWorkflow/OutgoingLetter/detail'
import CreateOutgoingLetter from './DocumentWorkflow/OutgoingLetter/create';

// Document Audit Trail
import DocumentAuditTrail from './DocumentAuditLogs/index';

// Master
import DocumentType from './Master/document-type/index'
import DocumentTypeScheme from './Master/document-type/scheme'
import ArchiveLocation from './Master/archive-location'
import TemplateDocument from './Master/document-template/index'
import CreateTemplateDocument from './Master/document-template/create'
import DocumentNumber from './Master/document-number/index'

// DMS
import DocumentManagementSystem from './dms'

// File Manager
import FileManager from './FileManager'

// Organisasi
import Division from './organisasi/division'
import Position from './organisasi/position'
import Pegawai from './organisasi/pegawai'

// Master
import SetupFileManager from './Master/file-manager'
import DetailSetupFileManager from './Master/file-manager/detail'
import Folder from './Master/folder'

// Profile
import Profile from './profile'

// Auth
import SSO from './sso'

// Not Found
import NotFound from './404';

// File Log
import FileLog from './file-log'
import ManageShared from './manage-shared';

const AppContent = () => {
    const location = useLocation();
    return (
        <AppProvider>
            <AppContainer router={location}>
                {
                    <Switch>
                        <Route exact path="/sso" component={SSO} />

                        {/* Home */}
                        < Route exact path="/" component={Home} />

                        {/* Approval Schema */}
                        <Route exact path="/approval-schema" component={ApprovalSchema} />
                        <Route exact path="/approval-approver" component={ApprovalApprover} />
                        <Route exact path="/approval-approver/detail/:schemeId/:directorateId" component={ApprovalApproverDetail} />
                        <Route exact path="/approval-center" component={ApprovalPool} />
                        <Route exact path="/approval-center-memo/:requestId" component={ApprovalPoolMemo} />
                        <Route exact path="/approval-center-outgoing/:requestId" component={ApprovalPoolOutgoing} />

                        {/* InternalMemo */}
                        <Route exact path="/document-workflow/internal-memo" component={InternalMemo} />
                        <Route exact path="/document-workflow/internal-memo/detail/:id" component={InternalMemoDetail} />
                        <Route exact path="/document-workflow/internal-memo/add/:id" component={CreateInternalMemo} />

                        {/* Outgoing Letter */}
                        <Route exact path="/document-workflow/outgoing-letter" component={OutgoingLetter} />
                        <Route exact path="/document-workflow/outgoing-letter/detail/:id" component={OutgoingLetterDetail} />
                        <Route exact path="/document-workflow/outgoing-letter/add/:id" component={CreateOutgoingLetter} />

                        {/* Document Audit Trail */}
                        <Route exact path="/document-audit-trail" component={DocumentAuditTrail} />

                        {/* Master */}
                        <Route exact path="/master/document-type" component={DocumentType} />
                        <Route exact path="/master/document-type-scheme/:id" component={DocumentTypeScheme} />
                        <Route exact path="/master/archive-location" component={ArchiveLocation} />
                        <Route exact path="/master/template-document" component={TemplateDocument} />
                        <Route exact path="/master/template-document/add/:id" component={CreateTemplateDocument} />

                        {/* Document Management System */}
                        <Route exact path="/document-management-system" component={DocumentManagementSystem} />

                        <Route exact path="/shared-document" component={ManageShared} />

                        {/* File Manager */}
                        <Route exact path="/file-manager" component={FileManager} />

                        {/* Organization */}
                        <Route exact path="/organisasi/division" component={Division} />
                        <Route exact path="/organisasi/position" component={Position} />
                        <Route exact path="/organisasi/pegawai" component={Pegawai} />

                        {/* Master */}
                        <Route exact path="/master/folder" component={Folder} />
                        <Route exact path="/master/file-manager" component={SetupFileManager} />
                        <Route exact path="/master/file-manager/detail/:fileManagerId" component={DetailSetupFileManager} />
                        <Route exact path="/master/document-number" component={DocumentNumber} />

                        {/* Profile */}
                        <Route exact path="/profile" component={Profile} />

                        {/* File Log */}
                        <Route exact path="/file-log" component={FileLog} />

                        {/* Not Found */}
                        <Route component={NotFound} />
                    </Switch>
                }
            </AppContainer>
        </AppProvider>
    );
}

export default AppContent;


