import React, { useState, useEffect } from 'react';
import { Upload, Button, Modal, message } from 'antd'
import { UploadOutlined, InboxOutlined } from '@ant-design/icons'
import { decrypt } from '../utils/helpers';

const { Dragger } = Upload

const host = process.env.REACT_APP_API_URL
const version = process.env.REACT_APP_API_VERSION
const clientKey = process.env.REACT_APP_CLIENT_KEY
const token = localStorage.getItem('accessToken') ? JSON.parse(decrypt(localStorage.getItem('accessToken'))) : []

export const FileUpload = (props) => {
    const [files, setFiles] = useState([])

    const { title = 'Click to Upload', accept = '.png,.jpg,.jpeg,.pdf,.xlsx,.xls,.doc,.docx', dragger = false, path = "sample" } = props

    // const removeFile = () => {
    //     if (files.length > 0) {
    //         const previuosFile = files[0].response.message
    //         ApiService.destroy(previuosFile.replace('storage/', ''))
    //     }
    // }

    const handleChange = info => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        props.isUploading(true)
        if (info.file.status !== 'uploading') {
        }

        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            props.isUploading(false)
            props.onUploaded(info.file.response)
            // removeFile()
        } else if (info.file.status === 'error') {
            console.log('error')
            message.error(`${info.file.name} file upload failed.`);
            props.isUploading(false)
        }
        setFiles(fileList)
    }

    const uploadHandler = {
        name: 'files',
        multiple: props.multiple,
        action: `${host}/${version}/upload/${path}`,
        headers: {
            'authorization': `Bearer ${token}`,
            'client-key': clientKey
        },
        accept: accept,
        onChange: handleChange
    };


    return (
        <div>
            {
                dragger ?
                    <Dragger {...uploadHandler}>
                        <p className="text-center">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    </Dragger>
                    :
                    <Upload {...uploadHandler} fileList={files}>
                        <Button icon={<UploadOutlined />}>{title}</Button>
                    </Upload>
            }

        </div>
    );
}

export const ImageUploader = (props) => {
    const { title = 'Click to Upload', accept = '.png,.jpg,.jpeg', defaultFile = null, path = "sample" } = props
    const [files, setFiles] = useState([])
    const [previewVisible, setPreviewVisible] = useState(false)
    useEffect(() => {
        if (defaultFile) {
            setFiles([{
                uid: 1,
                name: defaultFile,
                url: process.env.REACT_APP_API_FILE + defaultFile,
                status: 'done',
                isInitial: true
            }])
        }
    }, [defaultFile]);

    const removeFile = () => {
        if (files.length > 0 && files[0].isInitial) {
            console.log(files[0])
            // const previuosFile=files[0].name.replace('storage/','')
            // SentralModel.remove(previuosFile.replace('/',''))
        }
    }

    const handleChange = info => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);

        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            props.onUploaded(info.file.response)
            removeFile()
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
        setFiles(fileList)
    }

    const uploadHandler = {
        name: 'files',
        multiple: false,
        action: `${process.env.REACT_APP_API_URL}/upload/${path}`,
        headers: {
            'authorization': `Bearer ${token}`,
            'client-key': clientKey
        },
        accept: accept,
        onChange: handleChange,
        onRemove: removeFile,
        onPreview: () => setPreviewVisible(true)
    };


    return (
        <div>
            {
                (files.length > 1) ? null :
                    <Upload listType="picture-card" {...uploadHandler} fileList={files} >
                        <div>
                            <UploadOutlined />
                            <div style={{ marginTop: 8 }}>{title}</div>
                        </div>
                    </Upload>
            }
            {
                files.length > 0 ?
                    <Modal
                        visible={previewVisible}
                        title={null}
                        footer={null}
                        onCancel={() => setPreviewVisible(false)}
                    >
                        <img alt="example" style={{ width: '100%' }} src={files[0].url} />
                    </Modal> : null
            }
        </div>
    );
}

export const DmsUpload = (props) => {
    const [files, setFiles] = useState([])
    const { title = 'Click to Upload', accept = '.pdf', dragger = false, path = "sample" } = props

    const handleChange = info => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        props.isUploading(true);
        if (info.file.status !== 'uploading') { }

        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            props.isUploading(false)
            props.onUploaded(info.file.response)
        } else if (info.file.status === 'error') {
            console.log('error')
            message.error(`${info.file.name} file upload failed.`);
            props.isUploading(false)
        }
        setFiles(fileList)
    }

    const uploadHandler = {
        name: 'files',
        multiple: false,
        action: `${host}/${version}/upload-dms/${path}`,
        headers: {
            'authorization': `Bearer ${token}`,
            'client-key': clientKey
        },
        accept: accept,
        onChange: handleChange
    };

    return (
        <div>
            {
                dragger ?
                    <Dragger {...uploadHandler}>
                        <p className="text-center">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    </Dragger>
                    :
                    <Upload {...uploadHandler} fileList={files}>
                        <Button icon={<UploadOutlined />}>{title}</Button>
                    </Upload>
            }

        </div>
    );
}

export const SmartUpload = (props) => {
    const [files, setFiles] = useState([])

    const { title = 'Click to Upload', accept = '.pdf', dragger = false, path = "sample" } = props

    const handleChange = info => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        props.isUploading(true)
        if (info.file.status !== 'uploading') {
        }

        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            props.isUploading(false)
            props.onUploaded(info.file.response)
            // removeFile()
        } else if (info.file.status === 'error') {
            console.log('error')
            message.error(`${info.file.name} file upload failed.`);
            props.isUploading(false)
        }
        setFiles(fileList)
    }

    const uploadHandler = {
        name: 'files',
        multiple: false,
        action: `${host}/${version}/upload-smart/${path}`,
        headers: {
            'authorization': `Bearer ${token}`,
            'client-key': clientKey
        },
        accept: accept,
        onChange: handleChange
    };


    return (
        <div>
            {
                dragger ?
                    <Dragger {...uploadHandler}>
                        <p className="text-center">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    </Dragger>
                    :
                    <Upload {...uploadHandler} fileList={files}>
                        <Button icon={<UploadOutlined />}>{title}</Button>
                    </Upload>
            }

        </div>
    );
}