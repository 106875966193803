import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom'

export const Breadcrumbs = (props) => {
    const { items } = props

    const itemList = [
        {
            title:
                <Link to="/">Home</Link>
        },
    ]

    if (items) {
        items.map((el, key) => {
            if (el.length === 2) {
                return itemList.push(
                    {
                        title: <Link to={el[1]}>{el[0]}</Link>
                    }
                )
            } else {
                return itemList.push(
                    {
                        title: <Link to="#!">{el[0]}</Link>
                    }
                )
            }
        })
    }
    return (
        <>
            <Breadcrumb separator="/" items={itemList} />
        </>
    );
}