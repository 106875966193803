import React from 'react'
import { Row, Card, Button, Typography } from 'antd'
import notFound from '../../assets/images/404.png'
import { useHistory } from 'react-router-dom'

const { Text } = Typography

const NotFound = () => {
    const history = useHistory()

    return (
        <div>
            <Card style={{ borderRadius: 15 }}>
                <Row justify='center'>
                    <img style={{ border: 'none', width: 530 }} src={notFound} alt="not found" />
                </Row>
                <Row justify='center' style={{ marginBottom: 15 }}>
                    <Text style={{ fontWeight: 'bold', fontSize: 20 }}>
                        Maaf Halaman yang Anda Cari Tidak Ada!
                    </Text>
                </Row>
                <Row justify='center'>
                    <Button type='primary' style={{ borderRadius: 10 }} onClick={() => history.push('/')}>Kembali ke Dashboard</Button>
                </Row>
            </Card>
        </div>
    )
}

export default NotFound