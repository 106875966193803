import { Image } from 'antd'
import IcFolder from '../assets/images/ic_folder.png'

export const getNestedChildren = (folder, file) => {
    let folderChild = folder
    folderChild.forEach((e) => {
        let children = []
        file.forEach((i) => {
            if (e.parent === 4 && e.type === "FOLDER") {
                if (e.root_id === i.parent) {
                    children.push(i)
                }
            }
        })

        Object.assign(e, { children: children })
    })

    return [
        Object.assign(
            {
                title: "My Folder",
                icon: <Image preview={false} src={IcFolder} width={20} />,
                // key: `My Folder ${moment(Date.now()).format('YYYYMMDD-hhmmss')}`
            },
            { children: folderChild }
        )
    ]
}

export const generateTreeArr = (v) => {
    const { res } = v.reduce((acc, curr) => {
        if (acc.parentMap[curr.parent]) {
            (acc.parentMap[curr.parent].children =
                acc.parentMap[curr.parent].children || []).push(curr);
        } else {
            acc.res.push(curr);
        }
        acc.parentMap[curr.root_id] = curr;
        return acc;
    }, { parentMap: {}, res: [] });

    return res
}