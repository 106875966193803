import { useState, useEffect } from 'react';
import { Card, Col, Row, Breadcrumb, Table, Calendar } from 'antd';
import ProfileBox from './Profile';
// import DataSurat from './DataSurat';
import { ApiService } from '../../services/ApiService';
import moment from 'moment';
import "react-toastify/dist//ReactToastify.css";
// import 'moment/locale/id';
// import { currentUser } from '../../utils/authData';

const Beranda = () => {
    const [monthlyIndeksColumns, setMonthlyIndeksColumns] = useState([])
    const [monthlyIndeksData, setMonthlyIndeksData] = useState([])

    const onPanelChange = (v) => {
        console.log(v)
    }

    const fetchIndeksDigital = () => {
        ApiService.getList('dashboard/indeks-digital').then((res) => {

            let months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
            let childMonths = []
            months.map((item, i) => {
                return childMonths.push({
                    title: moment(item, 'M').locale('id').format('MMM'),
                    dataIndex: `month_` + item,
                    key: `month_` + item,
                })
            })

            let columns = [{
                title: `INDEKS DIGITALISASI DOKUMEN`,
                children: [
                    {
                        title: 'Department',
                        dataIndex: 'department',
                        key: 'department',
                        width: 200
                    },
                    ...childMonths,
                    {
                        title: 'Total',
                        dataIndex: 'total',
                        key: 'total',
                        width: 50
                    },
                    {
                        title: 'Persentase',
                        dataIndex: 'percentage',
                        key: 'percentage',
                        width: 50
                    },
                ]
            }]

            let data = []
            res.data?.map((item, i) => {
                return data.push({
                    department: item.department,
                    month_1: item.months?.find(e => e.month === 1)?.total ? item.months?.find(e => e.month === 1)?.total : '-',
                    month_2: item.months?.find(e => e.month === 2)?.total ? item.months?.find(e => e.month === 2)?.total : '-',
                    month_3: item.months?.find(e => e.month === 3)?.total ? item.months?.find(e => e.month === 3)?.total : '-',
                    month_4: item.months?.find(e => e.month === 4)?.total ? item.months?.find(e => e.month === 4)?.total : '-',
                    month_5: item.months?.find(e => e.month === 5)?.total ? item.months?.find(e => e.month === 5)?.total : '-',
                    month_6: item.months?.find(e => e.month === 6)?.total ? item.months?.find(e => e.month === 6)?.total : '-',
                    month_7: item.months?.find(e => e.month === 7)?.total ? item.months?.find(e => e.month === 7)?.total : '-',
                    month_8: item.months?.find(e => e.month === 8)?.total ? item.months?.find(e => e.month === 8)?.total : '-',
                    month_9: item.months?.find(e => e.month === 9)?.total ? item.months?.find(e => e.month === 9)?.total : '-',
                    month_10: item.months?.find(e => e.month === 10)?.total ? item.months?.find(e => e.month === 10)?.total : '-',
                    month_11: item.months?.find(e => e.month === 11)?.total ? item.months?.find(e => e.month === 11)?.total : '-',
                    month_12: item.months?.find(e => e.month === 12)?.total ? item.months?.find(e => e.month === 12)?.total : '-',
                    total: item.total,
                    percentage: `${item.percentage} %`
                })
            })

            data.sort(function (a, b) {
                return parseFloat(b.percentage) - parseFloat(a.percentage)
            });

            setMonthlyIndeksColumns(columns)
            setMonthlyIndeksData(data)
        }).catch((err) => {
        })
    }

    useEffect(() => {
        fetchIndeksDigital()

    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div>
            <Breadcrumb style={{ margin: '0 0 16px 0' }}>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
            </Breadcrumb>

            <Row justify='space-between'>
                <Col span={7}>
                    <ProfileBox />
                </Col>

                <Col span={16}>
                    <Card style={{ padding: 5, borderRadius: 10 }}>
                        <Calendar onPanelChange={onPanelChange} />
                    </Card>
                    {/* <DataSurat /> */}
                </Col>
            </Row>

            <Card style={{ borderRadius: 10, marginTop: 30 }}>
                <div>
                    <Table
                        columns={monthlyIndeksColumns}
                        dataSource={monthlyIndeksData}
                        bordered
                        pagination={false}
                        size="middle" />
                </div>
            </Card>
        </div >
    );
};

export default Beranda;
