import { useEffect, useState } from 'react';
import { Container, Inner } from './../components/styles/Page';
import { Layout, Spin } from 'antd';
import Header from './../components/Header';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';
import { ThemeProvider } from 'styled-components';
import { theme } from './../components/styles/GlobalStyles';
import { useAppState } from './../components/shared/AppProvider';

const { Content } = Layout;

const NonDashboardRoutes = [
    '/sso',
    '/login',
    '/forgot-password',
];

const AppContainer = ({ router, children }) => {
    const [loading, setLoading] = useState(true);
    const [state] = useAppState();
    const isNotDashboard = NonDashboardRoutes.includes(router.pathname);

    useEffect(() => {
        setLoading(false);
    }, []);

    return (
        <Spin tip="Loading..." size="large" spinning={loading}>
            <ThemeProvider theme={theme}>
                <Container>
                    <Layout className="workspace">
                        {!isNotDashboard && (
                            <Sidebar
                                sidebarTheme={state.darkSidebar ? 'dark' : 'light'}
                                sidebarMode={state.sidebarPopup ? 'vertical' : 'inline'}
                                sidebarIcons={true}
                                collapsed={state.collapsed}
                                router={router}
                            />
                        )}
                        <Layout className='sidebar-scroll'>
                            {!isNotDashboard && <Header />}
                            <Content>
                                {!isNotDashboard ? <Inner>{children}</Inner> : children}
                                <Footer />
                            </Content>
                        </Layout>
                    </Layout>
                </Container>
            </ThemeProvider>
        </Spin>
    );
};

export default AppContainer;
