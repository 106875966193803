import React, { useState, useEffect } from 'react'
import { Card, Space, message } from 'antd'
import { DeleteButton, DataTable, PageHeader, Spinner } from '../../components'
import { ApiService } from '../../services/ApiService'

const ManageShared = () => {
    const [loaded, setLoaded] = useState(true)
    const [documentShared, setDocumentShared] = useState([])

    const fetchDocumentShared = () => {
        setLoaded(false)
        ApiService.getList('manage-document-sharing').then((res) => {
            setDocumentShared(res.data)
            setLoaded(true)
        }).catch(() => {
        })
    }

    const destroy = (id) => {
        ApiService.destroy('document-sharing', id).then((res) => {
            message.success('Success Delete Shared')
            fetchDocumentShared()
        }).catch((err) => {
            message.warning('Failed Delete Shared')
        })
    }

    useEffect(() => {
        fetchDocumentShared()
    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Document Title',
            key: 'name',
            render: (row) => (
                row.document?.title
            )
        },
        {
            title: 'Share To Name',
            key: 'share_name',
            render: (row) => (
                row.share_to?.name
            )
        },
        {
            title: 'Share To Division',
            key: 'share_division',
            render: (row) => (
                row.share_to?.department_name
            )
        },
        {
            title: 'Aksi',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <DeleteButton onConfirm={() => destroy(row.document_sharing_id)} />
                    </Space>
                </div>
            )
        },
    ];

    return (
        <div>
            <PageHeader title="Manage Shared Document" breadcrumbs={[['Manage Shared Document']]} />
            <Card style={{ borderRadius: 10 }}>
                {
                    loaded ?
                        <DataTable size="small" columns={columns} sources={documentShared} bordered />
                        : <Spinner />
                }
            </Card>
        </div>
    )
}

export default ManageShared
