
export const setSessionStorage = (v, data) => {
    return sessionStorage.setItem(v, data)
}

export const getSessionStorage = (v) => {
    if (sessionStorage.getItem(v)) {
        return sessionStorage.getItem(v)
    }
    return false
}