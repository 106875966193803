import { message } from 'antd'
import axios from 'axios';
import { encrypt } from '../utils/helpers'

let host = process.env.REACT_APP_API_URL
let version = process.env.REACT_APP_API_VERSION
let clientKey = process.env.REACT_APP_CLIENT_KEY

const auth = async (username, password) => {
    const payload = {
        username: username,
        password: password
    };

    return await axios.post(`${host}/${version}/auth/login`, payload, {
        headers: {
            'client-key': clientKey
        }
    }).then(handleResponse)
        .catch((err) => {
            message.response('Invalid Credentials', 2)
        })
}

const authSSO = async (token) => {
    const payload = {
        sso_token: token
    };

    return await axios.post(`${host}/${version}/auth/sso`, payload, {
        headers: {
            'client-key': clientKey
        }
    }).then(handleResponse)
        .catch((err) => {
            message.response('Invalid Credentials', 2)
        })
}

const handleResponse = (response) => {
    if (response.status !== 200) {
        if ([401, 403].indexOf(response.status) !== -1) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            logout();
        }
        const error = (response && response.message) || response.statusText;
        return Promise.reject(error);
    }

    localStorage.setItem('accessToken', encrypt(JSON.stringify(response.data.token)));
    localStorage.setItem('currentUser', encrypt(JSON.stringify(response.data.data)));
    localStorage.setItem('role', encrypt(JSON.stringify(response.data.data.role)))
    message.destroy()
    message.success('Successfully authenticated', 2)
    setTimeout(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const redirectUri = urlParams.get('redirect_to');
        if (redirectUri) {
            window.location.href = redirectUri
        } else {
            window.location.href = '/'
        }
    }, 1000);

    return;
}

export const logout = (redirect = '') => {
    localStorage.clear();
    if (redirect) window.location.href = "/login?redirect_to=" + redirect
    else window.location.href = '/login'
    // window.location.href = 'https://portal.sentralapps.com'
}

export const AuthService = { auth, authSSO };