import React, { useState, useEffect } from 'react'
import { Card, Col, Form, Image, Input, message, Row, Tree } from 'antd'
import { PageHeader } from '../../../components'
import { ApiService } from '../../../services/ApiService'
import { useParams } from 'react-router-dom'
import IcFolder from '../../../assets/images/ic_folder.png'
import IcPdf from '../../../assets/images/ic_pdf.png'
import IcWord from '../../../assets/images/ic_word.png'
import IcExcel from '../../../assets/images/ic_xls.png'
import IcPpt from '../../../assets/images/ic_ppt.png'

const { DirectoryTree } = Tree;

const SetupFileManagerDetail = () => {
    const [form] = Form.useForm()
    const { fileManagerId } = useParams()
    const [loaded, setLoaded] = useState(false)
    // eslint-disable-next-line
    const [fileManager, setFileManager] = useState([])
    const [nestedTree, setNestedTree] = useState([])
    const [onChangeFile, setOnChangeFile] = useState(0)
    const [newName, setNewName] = useState('')

    function getNestedChildren(arr, parent) {
        var out = []
        for (var i in arr) {
            // eslint-disable-next-line
            if (arr[i].parent == parent) {
                var children = getNestedChildren(arr, arr[i].root_id)

                if (children.length) {
                    arr[i].children = children
                }
                out.push(arr[i])
            }
        }
        return out
    }

    const fetchSetupFileManager = () => {
        setLoaded(false)
        ApiService.getObject('list-file-manager', fileManagerId).then((res) => {
            setFileManager(res.data)
            setLoaded(true)
        })
    }

    const fetchNestedTree = () => {
        setLoaded(false)
        ApiService.getObject('nested-tree', fileManagerId).then((res) => {
            const tree = res.data

            for (let i = 0; i < tree.length; i++) {
                if (tree[i].type === 'FOLDER') {
                    Object.assign(tree[i], {
                        icon: <Image preview={false} src={IcFolder} width={20} />,
                        title: tree[i].name
                    })
                }

                if (tree[i].type === 'FILE') {
                    if (tree[i].name.split('.')[1] === 'pdf') {
                        Object.assign(tree[i], {
                            icon: <Image preview={false} src={IcPdf} width={20} />,
                            title: tree[i].name
                        })
                    }
                    if (tree[i].name.split('.')[1] === 'pptx' || tree[i].name.split('.')[1] === 'ppt') {
                        Object.assign(tree[i], {
                            icon: <Image preview={false} src={IcPpt} width={20} />,
                            title: tree[i].name
                        })
                    }
                    if (tree[i].name.split('.')[1] === 'docx' || tree[i].name.split('.')[1] === 'doc') {
                        Object.assign(tree[i], {
                            icon: <Image preview={false} src={IcWord} width={20} />,
                            title: tree[i].name
                        })
                    }
                    if (tree[i].name.split('.')[1] === 'xlsx' || tree[i].name.split('.')[1] === 'xls') {
                        Object.assign(tree[i], {
                            icon: <Image preview={false} src={IcExcel} width={20} />,
                            title: tree[i].name
                        })
                    }
                }
            }

            if (tree.length !== 0) {
                const generateTree = getNestedChildren(tree, tree[0].id)
                setNestedTree(generateTree)
                setLoaded(true)
            }
        })
    }

    const generateFolder = () => {
        setLoaded(false)
        let data = {
            id: fileManagerId
        }
        ApiService.store('generate-file-manager', data).then((res) => {
            if (res.data === null) {
                message.warning(res.message)
                setLoaded(true)
            } else {
                message.success(res.message)
                fetchNestedTree()
                setLoaded(true)
            }
        }).catch((err) => {
            message.warning(err.response.data.message)
        })
    }

    const onSelect = (selectedKeys, info) => {
        const v = info.node
        form.setFieldsValue({ name: v.name })
        setOnChangeFile(1)
    }

    const changeName = (v) => {
        setNewName(v)
    }

    const submitName = (v) => {
        console.log(newName)
    }

    useEffect(() => {
        fetchSetupFileManager()
        fetchNestedTree()

        // eslint-disable-next-line
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {
                nestedTree ?
                    <PageHeader title="Setup File Manager" breadcrumbs={[['Mater'], ['Setup File Manager'], ['Detail']]} titleButton={"Generate File Manager"} />
                    :
                    <PageHeader title="Setup File Manager" breadcrumbs={[['Mater'], ['Setup File Manager'], ['Detail']]} onGenerate={generateFolder} titleButton={"Generate File Manager"} />
            }
            <Card style={{ borderRadius: 10 }}>
                <Row justify='space-between'>
                    <Col span={9}>
                        <Card>
                            <Form form={form} onKeyDown={(v) => submitName(v)}>
                                <Form.Item name="name">
                                    {
                                        onChangeFile === 0 ?
                                            <Input disabled onChange={(v) => changeName(v.target.value)} style={{ width: '100%', borderRadius: 5 }} />
                                            :
                                            <Input disabled onChange={(v) => changeName(v.target.value)} style={{ width: '100%', borderRadius: 5 }} />
                                    }
                                </Form.Item>
                            </Form>

                            {
                                loaded ?
                                    <DirectoryTree
                                        onSelect={onSelect}
                                        treeData={nestedTree}
                                    />
                                    : null
                            }
                        </Card>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export default SetupFileManagerDetail