import React, { useState, useEffect } from 'react'
import { Typography, Card, Form, Input, Switch, message, Space, Tag } from 'antd'
import { EditButton, DeleteButton, DataTable, PageHeader, Spinner, FormModal } from '../../../components'
import { ApiService } from '../../../services/ApiService'
import { currentUser } from '../../../utils/authData'

const { Text } = Typography

const Folder = () => {
    const [loaded, setLoaded] = useState(true)
    const [folder, setFolder] = useState([])
    const [modalTitle, setModalTitle] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [isSaving, setIsSaving] = useState(false)
    const [status, setStatus] = useState(false)
    const user = currentUser

    const fetchFolder = () => {
        setLoaded(false);
        ApiService.getList('folder-master').then((res) => {
            setFolder(res.data)
            setLoaded(true)
        }).catch((err) => {
        })
    }

    const add = () => {
        setModalTitle('Tambah Folder')
        setDefaultValues({
            id: 0,
            name: '',
            status: true
        })
        setIsModalVisible(true)
        setStatus(true)
    }

    const edit = (v) => {
        setModalTitle('Edit Folder')
        setDefaultValues({
            id: v.id,
            name: v.name,
            status: parseInt(v.status)
        })
        setIsModalVisible(true)
        setStatus(v.status)
    }

    const destroy = (id) => {
        ApiService.destroy('folder', id, { user: user.id }).then((res) => {
            message.success(res.message, 2)
            fetchFolder()
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const onFinish = (el) => {
        let payload = {
            name: el.name,
            status: status ? 1 : 0
        }

        if (el.id === 0) {
            Object.assign(payload, { created_by: user.id, updated_by: user.id })
            setIsSaving(true)
            ApiService.store(`folder`, payload)
                .then((res) => {
                    message.success(res.message, 2)
                    setIsModalVisible(false)
                    setIsSaving(false)
                    fetchFolder()
                }).catch((err) => {
                    message.warning('Failed add new Folder')
                })
        } else {
            Object.assign(payload, { updated_by: user.id })
            setIsSaving(true)
            ApiService.update(`folder`, el.id, payload)
                .then((res) => {
                    message.success(res.message, 2)
                    setIsModalVisible(false)
                    setIsSaving(false)
                    fetchFolder()
                }).catch((err) => {
                    message.warning('Failed add new Folder')
                })
        }
    }

    useEffect(() => {
        fetchFolder()
    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Folder',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            align: 'center',
            key: 'status',
            render: (text) => (
                <Tag color={text === 1 ? "green" : "red"}>{(text === 1) ? 'Active' : 'InActive'}</Tag>
            )
        },
        {
            title: 'Aksi',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => edit(row)} />
                        <DeleteButton onConfirm={() => destroy(row.id)} />
                    </Space>
                </div>
            )
        },
    ];

    return (
        <div>
            <PageHeader title="OCR Folder" breadcrumbs={[['Master'], ['OCR Folder']]} onAdd={add} />

            <Card style={{ borderRadius: 10 }}>

                {
                    (loaded) ?
                        <DataTable size="small" columns={columns} sources={folder} bordered />
                        : <Spinner />
                }

                {
                    (isModalVisible) ?
                        <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => onFinish(v)} width={600} showModal={isModalVisible} onCloseModal={() => setIsModalVisible(false)} saving={isSaving}>
                            <Form.Item name="id" hidden>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Nama" name="name" required rules={[{ required: true }]}>
                                <Input placeholder="Nama" />
                            </Form.Item>
                            <Form.Item label="Status" name="status" rules={[{ required: true }]}>
                                <Switch checkedChildren="Ya" unCheckedChildren="Tidak" onChange={(v) => setStatus(v)} defaultChecked={defaultValues.status} />
                            </Form.Item>
                            <Text>Inputan dengan tanda (<span style={{ color: 'red' }}>*</span>) wajib diisi</Text>
                        </FormModal> :
                        null
                }
            </Card>
        </div>
    )
}

export default Folder;
