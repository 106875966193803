import React, { useState, useEffect } from 'react'
import { Card, Form, Input, Space, message } from 'antd'
import { EditButton, DeleteButton, DataTable, PageHeader, Spinner, FormModal, CustomButton } from './../../../components'
import { ApiService } from '../../../services/ApiService'
import { useHistory } from 'react-router-dom'
import { OrderedListOutlined } from '@ant-design/icons'

const DocumentType = () => {
    const history = useHistory()
    const [loaded, setLoaded] = useState(true)
    const [documentType, setDocumentType] = useState([])
    const [modalTitle, setModalTitle] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [isSaving, setIsSaving] = useState(false)

    const fetchDocumentType = () => {
        setLoaded(false);
        ApiService.getList('document-type').then((res) => {
            setDocumentType(res.data)
            setLoaded(true)
        })
    }

    const add = () => {
        setModalTitle('Add Document Type')
        setDefaultValues({
            document_type_id: 0,
            document_type_name: '',
            code: '',
        })
        setIsModalVisible(true)
    }

    const edit = (v) => {
        setModalTitle('Edit Document Type')
        setDefaultValues({
            document_type_id: v.document_type_id,
            document_type_name: v.document_type_name,
            code: v.code,
        })
        setIsModalVisible(true)
    }

    const destroy = (id) => {
        ApiService.destroy('document-type', id).then((res) => {
            message.success("Success Delete Document Type", 2)
            setTimeout(() => {
                fetchDocumentType()
            }, 500);
        }).catch((err) => {
            message.warning("Failed Delete Document Type", 2)
        })
    }

    const onFinish = (el) => {
        let payload = {
            document_type_name: el.document_type_name.toUpperCase(),
            code: el.code.toUpperCase(),
        }

        if (el.document_type_id === 0) {
            setIsSaving(true)
            ApiService.store(`document-type`, payload)
                .then((res) => {
                    message.success("Success Create Document Type", 2)
                    setIsModalVisible(false)
                    setIsSaving(false)
                    fetchDocumentType()
                }).catch((err) => {
                    message.warning("Failed Create Document Type")
                    setIsSaving(false)
                })
        } else {
            setIsSaving(true)
            ApiService.update(`document-type`, el.document_type_id, payload)
                .then((res) => {
                    message.success("Success Update Document Type", 2)
                    setIsModalVisible(false)
                    setIsSaving(false)
                    fetchDocumentType()
                }).catch((err) => {
                    message.warning("Failed Update Document Type")
                    setIsSaving(false)
                })
        }
    }

    useEffect(() => {
        fetchDocumentType()

    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Document Type',
            dataIndex: 'document_type_name',
            key: 'document_type_name',
        },
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => edit(row)} />
                        <DeleteButton onConfirm={() => destroy(row.document_type_id)} />
                        <CustomButton icon={<OrderedListOutlined />} onEdit={() => history.push(`/master/document-type-scheme/${row.document_type_id}`)} />
                    </Space>
                </div>
            )
        },
    ];

    return (
        <div>
            <PageHeader title="Document Type" breadcrumbs={[['Master'], ['Document Type']]} onAdd={add} />
            <Card style={{ borderRadius: 10 }}>

                {
                    loaded ?
                        <DataTable size="small" columns={columns} sources={documentType} bordered />
                        : <Spinner />
                }

                {
                    (isModalVisible) ?
                        <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => onFinish(v)} width={600} showModal={isModalVisible} onCloseModal={() => setIsModalVisible(false)} saving={isSaving}>
                            <Form.Item name="document_type_id" hidden>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Document Type Name" name="document_type_name" required rules={[{ required: true }]}>
                                <Input placeholder="Document Type Name" />
                            </Form.Item>
                            <Form.Item label="Code" name="code" required rules={[{ required: true }]}>
                                <Input placeholder="Code" />
                            </Form.Item>
                        </FormModal> :
                        null
                }
            </Card>
        </div>
    )
}

export default DocumentType
