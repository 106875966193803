import { useEffect, useState } from 'react'
import { BasicModal, DataTable, FormModal, PageHeader, ShowButton, Spinner } from '../../components'
import { Button, Card, Col, Form, Input, InputNumber, Row, Select, Space, Tag, Descriptions, message } from 'antd'
import { ApiService } from '../../services/ApiService'
import dayjs from 'dayjs'
import { SendOutlined } from '@ant-design/icons'

const DocumentAuditLogs = () => {
    const [documentData, setDocumentData] = useState([])
    const [folder, setFolder] = useState([])
    const [archiveLocation, setArchiveLocation] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showModalDetail, setShowModalDetail] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [data, setData] = useState({})
    const [defaultValues, setDefaultValues] = useState({})
    const [payloads, setPayloads] = useState({})

    const fetchDocumentAuditLogs = () => {
        setLoaded(false)
        ApiService.getList("audit-trail").then((res) => {
            setDocumentData(res.data)
            setLoaded(true)
        })
    }

    const fetchFolder = () => {
        ApiService.getList('folder').then((res) => {
            const v = res.data
            let arr = []

            v?.forEach((el) => {
                arr.push({ value: el.id, label: el.name })
            })

            setFolder(arr)
        })
    }

    const fetchArchiveLocation = () => {
        ApiService.getList('archive-location').then((res) => {
            const v = res.data
            let arr = []
            v?.forEach((el) => {
                arr.push({ value: el.id, label: el.name })
            })

            setArchiveLocation(arr)
        })
    }

    useEffect(() => {
        fetchDocumentAuditLogs()
        fetchFolder()
        fetchArchiveLocation()

    }, [])

    const onShowModalDetail = (v) => {
        setShowModalDetail(true)
        setModalTitle('Detail Log')
        setData(v)
    }

    const onShowModalReupload = (v) => {
        setShowModal(true)
        setModalTitle('Reupload Document')

        setDefaultValues({
            title: v.title,
            pdf_split: v.pdf_split,
            folder_id: v.folder_id,
            archive_location_id: v.archive_location_id
        })

        setPayloads({
            document_audit_trail_id: v.document_audit_trail_id,
            filename: v.document_entity,
            url: v.url,
            document_origin: v.document_origin,
            user_id: v.user_id,
            code: v.code,
        })
    }

    const onFinish = (v) => {
        let payload = {
            ...payloads,
            title: v.title,
            pdf_split: v.pdf_split,
            folder_id: v.folder_id,
            archive_location_id: v.archive_location_id
        }

        ApiService.store('reupload-manual', payload).then((res) => {
            message.success("Success Reupload Document, Please wait notification", 3)
            fetchDocumentAuditLogs()
            setShowModal(false)
        }).catch((err) => {
            message.warning("Failed Reupload Document")
            setShowModal(false)
        })
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Document Entity',
            dataIndex: 'document_entity',
            key: 'document_entity',
        },
        {
            title: 'Document Origin',
            dataIndex: 'document_origin',
            key: 'document_origin',
        },
        {
            title: 'Action',
            render: (row) => (
                <Tag color={
                    row?.action.includes("RENAME") || row?.action.includes("UPDATE") ? "blue" : row?.action.includes("CANCEL") || row?.action.includes("REJECT") || row?.action.includes("DELETE") || row?.action.includes("EXPIRED") ? "red" : "green"
                }>
                    {row?.action}
                </Tag>
            ),
            key: 'action',
            align: 'center'
        },
        {
            title: 'Status',
            render: (row) => (
                <Tag color={
                    row?.status === "SUCCESS" ? "green" : row?.status === "IN PROGRESS" ? "blue" : "red"
                }>
                    {row?.status ?? "-"}
                </Tag>
            ),
            key: 'status',
            align: 'center'
        },
        {
            title: 'User',
            render: (row) => (
                row?.created?.name ?? "-"
            ),
            key: 'user',
            align: 'center'
        },
        {
            title: 'Timestamp',
            render: (row) => (
                dayjs(row?.date).format("DD/MM/YYYY HH:mm")
            ),
            key: 'timestamp'
        },
        {
            title: 'CTA',
            render: (row) => (
                <Space>
                    <ShowButton onShow={() => onShowModalDetail(row)} />
                    {
                        row?.document_origin === "DMS" && row?.action === "UPLOAD" ?
                            <Button type='link' onClick={() => onShowModalReupload(row)}><SendOutlined /></Button>
                            : null
                    }
                </Space>
            ),
            key: 'cta',
            align: 'center'
        }
    ];

    return (
        <div>
            <PageHeader title="Document Audit Logs" breadcrumbs={[['Document Audit Logs']]} />
            <Card style={{ borderRadius: 10 }}>
                {
                    loaded ?
                        <DataTable sources={documentData} columns={columns} bordered size="small" />
                        : <Spinner />
                }
            </Card>

            {
                showModalDetail ?
                    <BasicModal title={modalTitle} width={1000} showModal={showModalDetail} onCloseModal={() => setShowModalDetail(false)}>
                        <div style={{ margin: "10px 0" }}>
                            <Descriptions bordered>
                                <Descriptions.Item label="Document Entity">{data?.document_entity ?? "-"}</Descriptions.Item>
                                <Descriptions.Item label="Document Origin">{data?.document_origin ?? "-"}</Descriptions.Item>
                                <Descriptions.Item label="Action">
                                    {
                                        <Tag color={
                                            data?.action === "CREATE" || data?.action === "UPDATE" ? "blue" : "green"
                                        }>
                                            {data?.action}
                                        </Tag>
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item label="Status">
                                    <Tag color={
                                        data?.status === "SUCCESS" ? "green" : data?.status === "IN PROGRESS" ? "blue" : "red"
                                    }>
                                        {data?.status ?? "-"}
                                    </Tag>
                                </Descriptions.Item>
                                <Descriptions.Item label="User">{data?.user?.name ?? "-"}</Descriptions.Item>
                                <Descriptions.Item label="Timestamp">{dayjs(data?.date).format("DD/MM/YYYY HH:mm")}</Descriptions.Item>
                                <Descriptions.Item span={3} label="Description">{<div dangerouslySetInnerHTML={{ __html: data.description }}></div>}</Descriptions.Item>
                            </Descriptions>
                        </div>
                    </BasicModal>
                    : null
            }

            {
                showModal ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => onFinish(v)} width={800} showModal={showModal} onCloseModal={() => setShowModal(false)}>
                        <Row justify="space-between">
                            <Col span={11}>
                                <Form.Item label="Title" name="title">
                                    <Input disabled placeholder="Title" />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item label="PDF Split" name="pdf_split">
                                    <InputNumber disabled placeholder="PDF Split" style={{ width: '100%' }} min={0} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Destination Folder" name="folder_id">
                            <Select
                                disabled
                                showSearch
                                placeholder="Select Destinaton Folder"
                                options={folder}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                        <Form.Item label="Archive Location" name="archive_location_id">
                            <Select
                                disabled
                                showSearch
                                placeholder="Select Archive Location"
                                options={archiveLocation}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </FormModal>
                    : null
            }
        </div>
    )
}

export default DocumentAuditLogs