import { Layout, Card } from 'antd';
const { Footer } = Layout;

const Home = () => {
    return (
        <>
            <Footer style={{ textAlign: 'center', height: '8vh', padding: 0 }}>
                <Card className="box-shadow-4 full-width">
                    © Copyright {new Date().getFullYear()} SMAIL Sentral All Rights Reserved.
                </Card>
            </Footer>
        </>
    );
}

export default Home;
