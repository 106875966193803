import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ApiService } from '../../../services/ApiService'
import { BasicModal, DataTable, PageHeader, Spinner } from '../../../components'
import { Button, Card, Col, Descriptions, message, Row, Tag } from 'antd'
import dayjs from 'dayjs'
import ApprovalLog from '../approval-log'

const apiUrl = process.env.REACT_APP_API_URL;
const version = process.env.REACT_APP_API_VERSION;

const Detail = () => {
    const { id } = useParams()
    const [data, setData] = useState({})
    const [auditTrails, setAuditTrails] = useState([])
    const [workflow, setWorkflow] = useState({})
    const [loaded, setLoaded] = useState(false)
    const [showModalDocument, setShowModalDocument] = useState(false)
    const [url, setUrl] = useState("")

    const fetchOutgoingLetter = () => {
        setLoaded(false)
        ApiService.getObject("outgoing-letter", id).then((res) => {
            setData(res.data.doc)
            setWorkflow(res.data.document_workflow)
            setAuditTrails(res.data.document_audit_trails)
            setLoaded(true)
        }).catch((err) => {
            message.warning("Error")
        })
    }

    useEffect(() => {
        fetchOutgoingLetter()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Document Number',
            dataIndex: 'document_entity',
            key: 'document_number'
        },
        {
            title: 'Action',
            render: (row) => (
                <Tag color={
                    row?.action.includes("RENAME") || row?.action.includes("UPDATE") ? "blue" : row?.action.includes("CANCEL") || row?.action.includes("REJECT") || row?.action.includes("DELETE") ? "red" : "green"
                }>
                    {row?.action}
                </Tag>
            ),
            key: 'action',
            align: 'center'
        },
        {
            title: 'Description',
            render: (row) => (
                <div dangerouslySetInnerHTML={{ __html: row.description }}></div>
            ),
            key: 'description'
        },
        {
            title: 'Timestamp',
            render: (row) => (
                dayjs(row?.date).format("DD/MM/YYYY HH:mm")
            ),
            key: 'date'
        }
    ]

    const onShowModalDoc = (v) => {
        setShowModalDocument(true)
        setUrl(v)
    }

    return (
        <div>
            <PageHeader title={"Outgoing Letter Detail"} breadcrumbs={[['Outgoing Letter', '/document-workflow/outgoing-letter'], ["Detail"]]} />
            <Card style={{ borderRadius: 10 }}>
                {
                    loaded ?
                        data ?
                            <>
                                <div style={{ marginBottom: 35 }}>
                                    <Descriptions title={
                                        <Row justify="space-between">
                                            <Col>
                                                Outgoing Letter Info
                                            </Col>
                                            <Col>
                                                {
                                                    workflow.status === "APPROVED" && workflow.url !== null ?
                                                        <Button type='primary' onClick={() => onShowModalDoc(`${apiUrl}/${version}/access-doc/${workflow.url.replace(/\//g, ",")}`)}>Show Document</Button>
                                                        : null
                                                }
                                            </Col>
                                        </Row>
                                    } bordered>
                                        <Descriptions.Item label="Document Number">{data?.document_number ?? "-"}</Descriptions.Item>
                                        <Descriptions.Item label="Document Type">{data?.document_type?.document_type_name ?? "-"}</Descriptions.Item>
                                        <Descriptions.Item label="Outgoing Letter Date">{dayjs(data?.outgoind_letter_date).format("dddd, DD MMMM YYYY") ?? "-"}</Descriptions.Item>
                                        <Descriptions.Item span={2} label="Subject">{data?.subject ?? "-"}</Descriptions.Item>
                                        <Descriptions.Item label="Approval Status">
                                            <Tag color={workflow.status === "WAITING" ? "blue" : workflow.status === "APPROVED" ? "green" : "red"}>
                                                {workflow?.status ?? "-"}
                                            </Tag>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Recipent">{data?.recipent ?? "-"}</Descriptions.Item>
                                        <Descriptions.Item label="Job Title">{data?.job_title ?? "-"}</Descriptions.Item>
                                        <Descriptions.Item label="Company">{data?.company ?? "-"}</Descriptions.Item>
                                        <Descriptions.Item label="Address">{data?.address ?? "-"}</Descriptions.Item>
                                        <Descriptions.Item label="Event Agenda">{data?.event_agenda ?? "-"}</Descriptions.Item>
                                        <Descriptions.Item label="Event Location">{data?.event_location ?? "-"}</Descriptions.Item>
                                        <Descriptions.Item label="Event Date">{dayjs(data?.event_date).format("dddd. DD MMMM YYYY") ?? "-"}</Descriptions.Item>
                                        <Descriptions.Item label="Time">{`${data.time_start} - ${data.time_end}`}</Descriptions.Item>
                                    </Descriptions>
                                </div>
                                <div>
                                    <Card title="Audit Trails">
                                        <DataTable sources={auditTrails} columns={columns} bordered size="small" search={false} />
                                    </Card>
                                </div>
                                <div>
                                    <Card bordered>
                                        <ApprovalLog approvers={data?.approver} />
                                    </Card>
                                </div>
                            </>
                            :
                            <div style={{ margin: "30px auto" }}>
                                Data Not Found
                            </div>
                        :
                        <Spinner />
                }
            </Card>

            {
                showModalDocument ?
                    <BasicModal title="Document" showModal={showModalDocument} onCloseModal={() => setShowModalDocument(false)} width={900}>
                        <Row justify="center">
                            <iframe src={`${url}#toolbar=0`} width="730" height="950" title="Document Review" />
                        </Row>
                    </BasicModal>
                    : null
            }
        </div>
    )
}

export default Detail