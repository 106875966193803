import React, { useState, useEffect } from 'react'
import { Card, Tag } from 'antd'
import { DataTable, PageHeader, Spinner } from '../../../components'
import { ApiService } from '../../../services/ApiService'

const Position = () => {
    const [loaded, setLoaded] = useState(true)
    const [position, setPosition] = useState([])

    const fetchPosition = () => {
        setLoaded(false);
        ApiService.getList('position').then((res) => {
            setPosition(res.data)
            setLoaded(true)
        }).catch((err) => {
        })
    }

    useEffect(() => {
        fetchPosition()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Position Name',
            dataIndex: 'name',
            key: 'name',
            align: 'center'
        },
        {
            title: 'Status',
            key: 'status',
            render: (row) => (
                parseInt(row.status) === 1 ? <Tag color="green">Active</Tag> : <Tag color="red">Not Active</Tag>
            ),
            align: 'center'
        },
    ];

    return (
        <div>
            <PageHeader title="Posisi" breadcrumbs={[['Organisasi'], ['Posisi']]} />
            <Card style={{ borderRadius: 10 }}>
                {
                    (loaded) ?
                        <DataTable size="small" columns={columns} sources={position} bordered />
                        : <Spinner />
                }
            </Card>
        </div>
    )
}

export default Position
