import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ApiService } from '../../../services/ApiService'
import { Button, Card, Form, Input, Select, message } from 'antd'
import { PageHeader, Spinner } from '../../../components'
import SunEditor from 'suneditor-react'
import {
    align,
    font,
    fontColor,
    fontSize,
    formatBlock,
    hiliteColor,
    horizontalRule,
    lineHeight,
    list,
    paragraphStyle,
    table,
    template,
    textStyle,
    image,
    link,
} from 'suneditor/src/plugins'
import { useHistory } from 'react-router-dom'

const Create = () => {
    const { id } = useParams()
    const [form] = Form.useForm()
    const history = useHistory()
    const [documentType, setDocumentType] = useState([])
    const [loaded, setLoaded] = useState(true)
    const [content, setContent] = useState('')

    const fetchDataTemplate = () => {
        setLoaded(false)
        ApiService.getObject('document-template', id).then((res) => {
            form.setFieldsValue({
                document_type_id: res.data.document_type_id,
                template_name: res.data.template_name
            })
            setContent(res.data.content)
            setLoaded(true)
        })
    }

    const fetchDocumentType = () => {
        ApiService.getList('document-type').then((res) => {
            const v = res.data
            let arr = []

            v?.forEach((el) => {
                arr.push({ value: el.document_type_id, label: el.document_type_name })
            })

            setDocumentType(arr)
        })
    }

    useEffect(() => {
        if (parseInt(id) !== 0) {
            fetchDataTemplate()
        }

        fetchDocumentType()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const onSubmit = (v) => {
        let payload = {
            ...v,
            content: content
        }

        if (parseInt(id) === 0) {
            ApiService.store("document-template", payload)
                .then((res) => {
                    message.success("Success Save Template")
                    setTimeout(() => {
                        history.goBack()
                    }, 500)
                }).catch((err) => {
                    message.warning("Failed Save Template")
                })
        } else {
            ApiService.update("document-template", id, payload)
                .then((res) => {
                    message.success("Success Update Template")
                    setTimeout(() => {
                        history.goBack()
                    }, [])
                }).catch((err) => {
                    console.log(err)
                    message.warning("Failed Update Template")
                })
        }
    }

    return (
        <div>
            <PageHeader title={`${parseInt(id) === 0 ? "Create" : "Update"} Template Document`} breadcrumbs={[['Master'], ['Template Document', '/master/template-document'], [`${parseInt(id) === 0 ? "Create" : "Update"}`]]} />
            <Card style={{ borderRadius: 10 }}>
                {
                    loaded ?
                        <Form form={form} layout='vertical' onFinish={onSubmit}>
                            <Form.Item label="Document Type" name="document_type_id" required rules={[{ required: true }]}>
                                <Select
                                    showSearch
                                    placeholder="Select Document Type"
                                    options={documentType}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                />
                            </Form.Item>
                            <Form.Item label="Template Name" name="template_name" required rules={[{ required: true }]}>
                                <Input placeholder="Template Name" />
                            </Form.Item>
                            <Form.Item label="Body Template">
                                <SunEditor setOptions={{
                                    showPathLabel: false,
                                    minHeight: "100vh",
                                    maxHeight: "100vh",
                                    placeholder: "Enter your text here!!!",
                                    plugins: [
                                        align,
                                        font,
                                        fontColor,
                                        fontSize,
                                        formatBlock,
                                        hiliteColor,
                                        horizontalRule,
                                        lineHeight,
                                        list,
                                        paragraphStyle,
                                        table,
                                        template,
                                        textStyle,
                                        image,
                                        link
                                    ],
                                    buttonList: [
                                        ["undo", "redo"],
                                        ["font", "fontSize", "formatBlock"],
                                        ["paragraphStyle"],
                                        [
                                            "bold",
                                            "underline",
                                            "italic",
                                            "strike",
                                            "subscript",
                                            "superscript"
                                        ],
                                        ["fontColor", "hiliteColor"],
                                        ["removeFormat"],
                                        "/", // Line break
                                        ["outdent", "indent"],
                                        ["align", "horizontalRule", "list", "lineHeight"],
                                        ["table", "link", "image"]
                                    ],
                                    formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                                    font: [
                                        "Arial",
                                        "Calibri",
                                        "Comic Sans",
                                        "Courier",
                                        "Garamond",
                                        "Georgia",
                                        "Impact",
                                        "Lucida Console",
                                        "Palatino Linotype",
                                        "Segoe UI",
                                        "Tahoma",
                                        "Times New Roman",
                                        "Trebuchet MS"
                                    ]
                                }} height={500} setContents={content} onChange={(value) => setContent(value)} />
                            </Form.Item>
                            <Form.Item>
                                <Button type='primary' htmlType='submit'>Save</Button>
                            </Form.Item>
                        </Form>
                        : <Spinner />
                }
            </Card>
        </div>
    )
}

export default Create