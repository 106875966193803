import { useEffect, useState } from 'react'
import { PageHeader, Spinner } from '../../../components'
import { Badge, Card, Tabs } from 'antd'
import { ApiService } from '../../../services/ApiService'
import { setSessionStorage, getSessionStorage } from '../../../utils/sessionStorage'
import TabData from './tab-data'
import { ReconciliationOutlined } from '@ant-design/icons'

const { TabPane } = Tabs;

const Index = () => {
    const [approvalData, setApprovalData] = useState([])
    const [approvalBadgeData, setApprovalBadgeData] = useState([])
    const [documentTypes, setDocumentTypes] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [loadedApproval, setLoadedApproval] = useState(false)
    const defaultSelectedTab = getSessionStorage('selectedApprovalTab')

    const fetchDocumentType = () => {
        // setLoaded(false)
        ApiService.getList(`document-type`).then((res) => {
            setDocumentTypes(res.data)
            setLoaded(true)
        })
    }

    const fetchApproval = (documentTypeId) => {
        setLoadedApproval(false)
        ApiService.getList(`approval-list/${documentTypeId}`).then((res) => {
            setApprovalData(res.data)
            setLoadedApproval(true)
        })
    }

    const fetchApprovalBadge = () => {
        ApiService.getList(`approval-badge-list`).then((res) => {
            setApprovalBadgeData(res.data)
        })
    }

    useEffect(() => {
        fetchDocumentType()
        fetchApprovalBadge()
        if (defaultSelectedTab) {
            fetchApproval(defaultSelectedTab)
        } else {
            fetchApproval(1)
        }
    }, [defaultSelectedTab])

    const changeTab = (v) => {
        setSessionStorage('selectedApprovalTab', v)
        fetchApproval(v)
    }

    return (
        <div>
            <PageHeader title="Approval Center" breadcrumbs={[['Approval Center']]} />
            <Card>
                {
                    loaded ?
                        <Tabs defaultActiveKey={defaultSelectedTab} type="card" tabPosition="left" onChange={changeTab} /* className="submission-tab" */>
                            {
                                documentTypes.map((el, key) => (
                                    <TabPane tab={
                                        <span>
                                            <ReconciliationOutlined /> {el.document_type_name}{(approvalBadgeData.some(badge => badge.document_type_id === el.document_type_id)) ? <Badge dot className="blinking-badge"><div style={{ visibility: 'hidden' }}>I</div></Badge> : null}
                                        </span>
                                    } key={el.document_type_id}>
                                        {
                                            (loadedApproval) ?
                                                <TabData data={approvalData} />
                                                : <Spinner />
                                        }
                                    </TabPane>
                                ))
                            }
                        </Tabs>
                        : <Spinner />
                }
            </Card>
        </div>
    )
}

export default Index