import { useState, useEffect } from 'react'
import { DataTable, DeleteButton, EditButton, PageHeader, Spinner } from '../../../components'
import { Card, Space, message } from 'antd'
import { useHistory } from 'react-router-dom'
import { ApiService } from '../../../services/ApiService'

const TemplateDocument = () => {
    const history = useHistory()
    const [templateData, setTemplateData] = useState([])
    const [loaded, setLoaded] = useState(false)

    const fetchDocumentTemplate = (v) => {
        if (v) {
            setLoaded(false)
        } else {
            setLoaded(true)
        }
        ApiService.getList("document-template").then((res) => {
            setTemplateData(res.data)
            if (v) {
                setLoaded(true)
            }
        })
    }

    useEffect(() => {
        fetchDocumentTemplate(true)

    }, [])

    const onDelete = (v) => {
        ApiService.destroy("document-template", v).then((res) => {
            message.success("Success Delete Template")
            fetchDocumentTemplate(false)
        }).catch((err) => {
            message.warning("Failed Delete Template")
        })
    }


    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Document Type',
            render: (row) => (
                row?.document_type?.document_type_name
            ),
            key: 'document-type'
        },
        {
            title: 'Template Name',
            dataIndex: 'template_name',
            key: 'template_name'
        },
        {
            title: 'Action',
            render: (row) => (
                <Space>
                    <EditButton onEdit={() => history.push(`/master/template-document/add/${row.template_document_id}`)} />
                    <DeleteButton onConfirm={() => onDelete(row.template_document_id)} />
                </Space>
            ),
            key: 'action',
            align: 'center',
            width: '8%'
        }
    ]

    return (
        <div>
            <PageHeader title="Template Document" breadcrumbs={[['Master'], ['Template Document']]} onAdd={() =>
                history.push("/master/template-document/add/0")} />
            <Card style={{ borderRadius: 10 }}>
                {
                    loaded ?
                        <DataTable columns={columns} sources={templateData} bordered size="small" />
                        : <Spinner />
                }
            </Card>
        </div>
    )
}

export default TemplateDocument