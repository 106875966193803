import React, { useEffect, useState } from 'react'
import { Card, Row, Col, Typography, Button, Space, message, Form, Select } from 'antd'
import { ApiService } from '../../../../services/ApiService'
import { Docs, Excel, Images, Pdf, Ppt } from '../../../../components/Folder';
import { decrypt } from '../../../../utils/helpers';
import { FormModal } from '../../../../components';
import { Spinner } from 'react-bootstrap';

const { Text } = Typography
const { Option } = Select
const fileUrl = process.env.REACT_APP_FILE_URL;
const user = localStorage.getItem('currentUser') ? JSON.parse(decrypt(localStorage.getItem('currentUser'))) : []

const SharingDocument = () => {
    const [loaded, setLoaded] = useState(false)
    const [isFile, setIsFile] = useState(false)
    const [showModalSharing, setShowModalSharing] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [sharingDocument, setSharingDocument] = useState([])
    const [listDocument, setListDocument] = useState([])
    const [listUser, setListUser] = useState([])
    const [fileNameUrl, setFileNameUrl] = useState('')
    const [fileId, setFileId] = useState(0)

    const fetchSharingDocument = () => {
        ApiService.getList("document-sharing").then((res) => {
            setSharingDocument(res.data)
            setLoaded(true)
        })
    }

    const fetchDocument = () => {
        ApiService.getList('document-user').then((res) => {
            setListDocument(res.data)
        })
    }

    const fetchUser = () => {
        ApiService.getList('user').then((res) => {
            setListUser(res.data)
        })
    }

    useEffect(() => {
        fetchSharingDocument()
        fetchDocument()
        fetchUser()

    }, []);

    const onSelectFile = (name, id) => {
        setIsFile(true)
        setFileNameUrl(name)
        setFileId(id)
    }

    const downloadFile = () => {
        let payload = {
            user_id: user.id,
            file_id: fileId,
            description: 'Access File',
            filename: fileNameUrl,
            document_from: 'WEB'
        }

        ApiService.store('file-log', payload).then((res) => {
            window.open(`${fileUrl}/${fileNameUrl}`)
        }).catch(() => {
            message.warning('Failed download data')
        })
    }

    const showSharing = () => {
        setShowModalSharing(true)
        setModalTitle('Sharing Document')
    }

    const submitSharingDoc = (v) => {
        let payload = {
            ...v
        }

        ApiService.store('document-sharing', payload).then((res) => {
            message.success("Success Share Document")
            setShowModalSharing(false)
        }).catch((err) => {
            message.warning("Failed Share Document")
            setShowModalSharing(false)
        })
    }

    return (
        <Card title={
            <Row justify='space-between'>
                <Col>
                    <Text>Sharing Document</Text>
                </Col>
                {
                    isFile ?
                        <Col>
                            <Button type='primary' onClick={() => setIsFile(false)}>Back</Button>
                        </Col>
                        :
                        <Col>
                            <Button type='primary' onClick={showSharing}>Sharing Document</Button>
                        </Col>
                }
            </Row>
        }>
            {
                loaded ?
                    sharingDocument.length !== 0 ?
                        <Row>
                            {
                                !isFile ?
                                    sharingDocument.map((el, key) => (
                                        el.document.filename.split('.')[1] === 'pdf' ?
                                            <Col key={key} style={{ marginRight: 10 }} span={4}>
                                                <Card style={{ width: '100%' }}>
                                                    <Row justify='space-between'>
                                                        <Col>
                                                            <Pdf width={50} buttonHeight={70} onClick={() => onSelectFile(el.document.filename)} />
                                                        </Col>
                                                    </Row>
                                                    <div >
                                                        <Text strong style={{ fontSize: 16 }}>
                                                            {el.document.title}
                                                        </Text><br />
                                                        <Text style={{ fontSize: 12 }}>
                                                            Shared By: {el.share_by.name}
                                                        </Text> <br />
                                                    </div>
                                                </Card>
                                            </Col>
                                            :
                                            el.document.filename.split('.')[1] === 'doc' || el.document.filename.split('.')[1] === 'docx' ?
                                                <Col key={key} style={{ marginRight: 10 }} span={4}>
                                                    <Card style={{ width: '100%' }}>
                                                        <Row justify='space-between'>
                                                            <Col>
                                                                <Docs width={50} buttonHeight={70} onClick={() => onSelectFile(el.document.filename)} />
                                                            </Col>
                                                        </Row>
                                                        <div >
                                                            <Text strong style={{ fontSize: 16 }}>
                                                                {el.document.title}
                                                            </Text><br />
                                                            <Text style={{ fontSize: 12 }}>
                                                                Shared By: {el.share_by.name}
                                                            </Text> <br />
                                                        </div>
                                                    </Card>
                                                </Col>
                                                : el.document.filename.split('.')[1] === 'xls' || el.document.filename.split('.')[1] === 'xlsx' ?
                                                    <Col key={key} style={{ marginRight: 10 }} span={4}>
                                                        <Card style={{ width: '100%' }}>
                                                            <Row justify='space-between'>
                                                                <Col>
                                                                    <Excel width={50} buttonHeight={70} onClick={() => onSelectFile(el.document.filename)} />
                                                                </Col>
                                                            </Row>
                                                            <div >
                                                                <Text strong style={{ fontSize: 16 }}>
                                                                    {el.document.title}
                                                                </Text><br />
                                                                <Text style={{ fontSize: 12 }}>
                                                                    Shared By: {el.share_by.name}
                                                                </Text> <br />
                                                            </div>
                                                        </Card>
                                                    </Col>
                                                    : el.document.filename.split('.')[1] === 'ppt' || el.document.filename.split('.')[1] === 'pptx' ?
                                                        <Col key={key} style={{ marginRight: 10 }} span={4}>
                                                            <Card style={{ width: '100%' }}>
                                                                <Row justify='space-between'>
                                                                    <Col>
                                                                        <Ppt width={50} buttonHeight={70} onClick={() => onSelectFile(el.document.filename)} />
                                                                    </Col>
                                                                </Row>
                                                                <div >
                                                                    <Text strong style={{ fontSize: 16 }}>
                                                                        {el.document.title}
                                                                    </Text><br />
                                                                    <Text style={{ fontSize: 12 }}>
                                                                        Shared By: {el.share_by.name}
                                                                    </Text> <br />
                                                                </div>
                                                            </Card>
                                                        </Col> :
                                                        <Col key={key} style={{ marginRight: 10 }} span={4}>
                                                            <Card style={{ width: '100%' }}>
                                                                <Row justify='space-between'>
                                                                    <Col>
                                                                        <Images width={50} buttonHeight={70} onClick={() => onSelectFile(el.document.filename)} />
                                                                    </Col>
                                                                </Row>
                                                                <div >
                                                                    <Text strong style={{ fontSize: 16 }}>
                                                                        {el.document.title}
                                                                    </Text><br />
                                                                    <Text style={{ fontSize: 12 }}>
                                                                        Shared By: {el.share_by.name}
                                                                    </Text> <br />
                                                                </div>
                                                            </Card>
                                                        </Col>

                                    ))
                                    :
                                    <div>
                                        <Row justify='start' style={{ marginBottom: 20 }}>
                                            <Space>
                                                {
                                                    fileNameUrl.split('.')[1] === 'docx' || fileNameUrl.split('.')[1] === 'doc' || fileNameUrl.split('.')[1] === 'xlsx' || fileNameUrl.split('.')[1] === 'xls' || fileNameUrl.split('.')[1] === 'pptx' || fileNameUrl.split('.')[1] === 'ppt' ?
                                                        null :
                                                        <Button type="primary" onClick={downloadFile}>Download</Button>
                                                }
                                            </Space>
                                        </Row>

                                        {
                                            fileNameUrl.split('.')[1] === 'pdf' || fileNameUrl.split('.')[1] === 'jpg' || fileNameUrl.split('.')[1] === 'jpeg' || fileNameUrl.split('.')[1] === 'png' ?
                                                <Row justify='center'>
                                                    <iframe src={`${fileUrl}/${fileNameUrl}`} width="1100px" height="950" title="Document Review" />
                                                </Row>
                                                :
                                                <Card style={{ borderRadius: 10 }}>
                                                    <Row justify='center' className='mb-3'>
                                                        <div className='text-center'>
                                                            <h1 style={{ fontSize: 22, fontWeight: 600 }}>Dokumen Tidak dapat Ditampilkan</h1>
                                                            <p>Download file untuk melihat dokumen</p>
                                                        </div>
                                                    </Row>
                                                    <Row justify='center'>
                                                        <div>
                                                            <Button type='primary' onClick={downloadFile}>Download</Button>
                                                        </div>
                                                    </Row>
                                                </Card>
                                        }

                                    </div>
                            }
                        </Row>
                        :
                        <div className='text-center' style={{ marginTop: 20 }}>
                            <Text>Data not Found</Text>
                        </div>
                    : <Spinner />

            }

            {
                showModalSharing ?
                    <FormModal title={modalTitle} submitForm={(v) => submitSharingDoc(v)} width={600} showModal={showModalSharing} onCloseModal={() => setShowModalSharing(false)}>
                        <Form.Item name="document_id" label="Document">
                            <Select placeholder="Select Document">
                                {
                                    listDocument.map((el, key) => (
                                        <Option value={el.id} key={key}>{el.title}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item name="user_id" label="User">
                            <Select placeholder="Select User">
                                {
                                    listUser.map((el, key) => (
                                        <Option value={el.id} key={key}>{el.name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </FormModal>
                    : null
            }
        </Card>
    )
}

export default SharingDocument