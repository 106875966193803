import { AuthService } from '../services/AuthService'
import { message } from 'antd'
import { decrypt } from './helpers'

export function authToken() {
    // return authorization header with jwt token
    const userToken = localStorage.getItem('userToken');
    if (userToken) {
        return {
            'Authorization': `Bearer ${decrypt(userToken)}`,
            'Content-Type': 'multipart/form-data',
        };
    } else {
        return {};
    }
}

export function uploadHeader() {
    // return authorization header with jwt token
    const userToken = localStorage.getItem('accessToken');
    if (userToken) {
        return {
            'Authorization': `Bearer ${decrypt(userToken)}`,
            'client-key': process.env.REACT_APP_CLIENT_KEY
        };
    } else {
        return {};
    }
}

export function handleResponse(response) {
    if (response.status && response.status < 300) {
        return response.data
    } else {
        if (response.status === 401 || response.status === 403) {
            AuthService.logout();
            window.location.reload(true);
        } else {
            const error = (response && response.message) || response.statusText;
            message.error(error, 2)
            return Promise.reject();
        }
    }
    return;
}



