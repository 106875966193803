import React, { useState, useEffect } from 'react'
import { Card } from 'antd'
import { DataTable, PageHeader, Spinner } from './../../components'
import { ApiService } from '../../services/ApiService'
import { decrypt } from '../../utils/helpers'
import moment from 'moment/moment'

const FileLog = () => {
    const [loaded, setLoaded] = useState(false)
    const [fileLog, setFileLog] = useState([])

    const role = localStorage.getItem('role') ? JSON.parse(decrypt(localStorage.getItem('role'))) : []
    const user = localStorage.getItem('currentUser') ? JSON.parse(decrypt(localStorage.getItem('currentUser'))) : []

    const fetchFileLog = () => {
        let params = {
            user_id: user.id,
            role: role
        }

        ApiService.getList('file-log', params).then((res) => {
            setFileLog(res.data)
            setLoaded(true)
        }).catch(() => {
        })
    }

    useEffect(() => {
        fetchFileLog()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Filename',
            dataIndex: 'filename',
            key: 'filename'
        },
        {
            title: 'User Access',
            key: 'user_access',
            render: (row) => (
                row.user_access.name
            ),
            align: 'center'
        },
        {
            title: 'Access Date',
            key: 'access_date',
            render: (row) => (
                row.access_date === null ? '-' :
                    moment(row.access_date).format('DD MMMM YYYY')
            )
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description'
        },
    ];

    return (
        <div>
            <PageHeader title="File Log" breadcrumbs={[['File Log']]} />
            <Card style={{ borderRadius: 10 }}>
                {
                    loaded ?
                        <DataTable size="small" columns={columns} sources={fileLog} bordered />
                        : <Spinner />
                }
            </Card>
        </div>
    )
}

export default FileLog
