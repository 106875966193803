import { Card, Avatar, Row, Col, Typography, Space } from 'antd';
import { currentUser } from '../../utils/authData';

const { Text } = Typography

const ProfileBox = () => {
    return (
        <div style={{ marginBottom: 10 }}>
            <Card style={{ width: '100%', minHeight: 100, padding: 0, color: 'white', borderRadius: 10 }} className="box-shadow-2">
                <Row className='mb-4' justify="space-between">
                    <Col span={12}>
                        <Text className="m-0">
                            <b>Welcome</b> <br /> SMAIL Dashboard
                        </Text>
                    </Col>
                    <Col>
                        <Avatar size={50}>
                            {(currentUser?.name.substring(0, 2)).toUpperCase()}
                        </Avatar>
                    </Col>
                </Row>

                <Row justify="start">
                    <Col>
                        <Text style={{ fontWeight: 'bold' }}>{currentUser?.name}</Text>
                    </Col>
                </Row>
                <Row justify="space-between">
                    <Space>
                        <Text style={{ color: "#999999", fontWeight: 'bold' }}>{currentUser.position_name}</Text>
                        <Text style={{ color: "#999999", fontWeight: 'bold' }}>-</Text>
                        <Text style={{ color: "#999999", fontWeight: 'bold' }}>{currentUser.division_name}</Text>
                    </Space>
                </Row>
            </Card>
            <br />
        </div>
    );
}

export default ProfileBox;
