import { useDispatch, useSelector } from 'react-redux'
import {
    closeFolderModal,
    closeParentFolderModal,
    closeLocationdModal,
    closeRenameModal,
    closeShareFolderModal,
} from "../../../../../redux/slice/fileManagerSlice";
import { FormModal } from '../../../../../components';
import { Form, Input, Select } from 'antd';

export const ModalFolder = (props) => {
    const dispatch = useDispatch()
    const isModalVisibleFormFolder = useSelector((state) => state.fileManager.isModalVisibleFormFolder)
    const modalTitleFormFolder = useSelector((state) => state.fileManager.modalTitleFormFolder)
    const defaultValues = useSelector((state) => state.fileManager.defaultValues)
    const isSaving = useSelector((state) => state.fileManager.isSaving)

    return (
        <FormModal defaultValues={defaultValues} title={modalTitleFormFolder} submitForm={(v) => props.onFinishFolder(v)} width={600} showModal={isModalVisibleFormFolder} onCloseModal={() => dispatch(closeFolderModal())} saving={isSaving}>
            <Form.Item name="id" hidden>
                <Input />
            </Form.Item>
            <Form.Item label="Folder Name" name="name" required rules={[{ required: true }]}>
                <Input placeholder="Folder Name" />
            </Form.Item>
            <Form.Item label="Share To Division" name="share_to_division">
                <Select
                    mode='multiple'
                    options={props.division_list}
                    placeholder="Select Division for Share"
                />
            </Form.Item>
        </FormModal>
    )
}

export const ModalParentFolder = (props) => {
    const dispatch = useDispatch()
    const parentFolderValues = useSelector((state) => state.fileManager.parentFolderValues)
    const isModalParentFolder = useSelector((state) => state.fileManager.isModalParentFolder)
    const isSaving = useSelector((state) => state.fileManager.isSaving)

    return (
        <FormModal defaultValues={parentFolderValues} title={"Add Parent Folder"} submitForm={(v) => props.onFinishFolderParent(v)} width={600} showModal={isModalParentFolder} onCloseModal={() => dispatch(closeParentFolderModal())} saving={isSaving}>
            <Form.Item name="root_id" hidden>
                <Input />
            </Form.Item>
            <Form.Item label="Folder Name" name="name" required rules={[{ required: true }]}>
                <Input placeholder="Folder Name" />
            </Form.Item>
            <Form.Item label="Share To Division" name="share_to_division">
                <Select
                    mode='multiple'
                    options={props.division_list}
                    placeholder="Select Division for Share"
                />
            </Form.Item>
        </FormModal>
    )
}

export const ModalAddLocation = (props) => {
    const dispatch = useDispatch()
    const isModalAddLocationDocument = useSelector((state) => state.fileManager.isModalAddLocationDocument)
    const modalTitleAddLocationDocument = useSelector((state) => state.fileManager.modalTitleAddLocationDocument)

    return (
        <FormModal title={modalTitleAddLocationDocument} submitForm={(v) => props.onFinishLocation(v)} width={600} showModal={isModalAddLocationDocument} onCloseModal={() => dispatch(closeLocationdModal())}>
            <Form.Item label="Location Name" name="name" required rules={[{ required: true }]}>
                <Input placeholder="Location Name" />
            </Form.Item>
        </FormModal>
    )
}

export const ModalRename = (props) => {
    const dispatch = useDispatch()
    const isModalRename = useSelector((state) => state.fileManager.isModalRename)
    const modalRenameTitle = useSelector((state) => state.fileManager.modalRenameTitle)
    const renameValues = useSelector((state) => state.fileManager.renameValues)
    const isSavingFile = useSelector((state) => state.fileManager.isSavingFile)

    return (
        <FormModal defaultValues={renameValues} title={modalRenameTitle} submitForm={(v) => props.onFinishRename(v)} width={400} showModal={isModalRename} onCloseModal={() => dispatch(closeRenameModal())} saving={isSavingFile}>
            <Form.Item name="id" hidden>
                <Input />
            </Form.Item>
            <Form.Item label="Name" name="original_filename">
                <Input placeholder='Name' />
            </Form.Item>
        </FormModal>
    )
}

export const ModalShareFolder = (props) => {
    const dispatch = useDispatch()
    const isModalShareFolder = useSelector((state) => state.fileManager.isModalShareFolder)
    const shareFolderValues = useSelector((state) => state.fileManager.shareFolderValues)

    return (
        <FormModal defaultValues={shareFolderValues} title="Share Folder" submitForm={(v) => props.onFinishShare(v)} width={600} showModal={isModalShareFolder} onCloseModal={() => dispatch(closeShareFolderModal())}>
            <Form.Item name="file_manager_id" hidden>
                <Input />
            </Form.Item>
            <Form.Item name="folder_id" hidden>
                <Input />
            </Form.Item>
            <Form.Item label="Share To Division" name="share_to_division">
                <Select
                    mode='multiple'
                    options={props.division_list}
                    placeholder="Select Division for Share"
                />
            </Form.Item>
        </FormModal>
    )
}