import { useState, useEffect } from 'react'
import { Card, Form, Input, message, Select } from 'antd'
import { DataTable, PageHeader, Spinner, FormModal } from '../../../components'
import { ApiService } from '../../../services/ApiService'

const DocumentNumberFormat = () => {
    const [loaded, setLoaded] = useState(true)
    const [documentNumberFormat, setDocumentNumberFormat] = useState([])
    const [documentType, setDocumentType] = useState([])
    const [modalTitle, setModalTitle] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [isSaving, setIsSaving] = useState(false)

    const fetchDocumentNumberFormat = () => {
        setLoaded(false);
        ApiService.getList('document-number').then((res) => {
            setDocumentNumberFormat(res.data)
            setLoaded(true)
        })
    }

    const fetchDocumentType = () => {
        ApiService.getList('document-type').then((res) => {
            const v = res.data
            let arr = []

            v?.forEach((el) => {
                arr.push({ value: el.document_type_id, label: el.document_type_name })
            })
            setDocumentType(arr)
        })
    }

    const add = () => {
        setModalTitle('Add Document Number')
        setDefaultValues({
            document_number_id: 0,
            document_type_id: '',
            document_number: '',
        })
        setIsModalVisible(true)
    }

    const onFinish = (el) => {
        let payload = {
            document_type_id: el.document_type_id,
            document_number: el.document_number.toUpperCase(),
        }
        setIsSaving(true)
        ApiService.store(`document-number`, payload)
            .then((res) => {
                message.success("Success Add Document Number", 2)
                setIsModalVisible(false)
                setIsSaving(false)
                fetchDocumentNumberFormat()
            }).catch((err) => {
                message.warning("Failed Add Document Number")
            })
    }

    useEffect(() => {
        fetchDocumentNumberFormat()
        fetchDocumentType()

    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Document Type',
            render: (row) => (
                row?.document_type?.document_type_name
            ),
            key: 'document_type',
            align: 'center'
        },
        {
            title: 'Document Number Format',
            dataIndex: 'document_number',
            key: 'document_number',
        },
    ];

    return (
        <div>
            <PageHeader title="Document Number Format" breadcrumbs={[['Master'], ['Document Number Format']]} onAdd={add} />
            <Card style={{ borderRadius: 10 }}>

                {
                    (loaded) ?
                        <DataTable size="small" columns={columns} sources={documentNumberFormat} bordered />
                        : <Spinner />
                }

                {
                    (isModalVisible) ?
                        <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => onFinish(v)} width={600} showModal={isModalVisible} onCloseModal={() => setIsModalVisible(false)} saving={isSaving}>
                            <Form.Item name="document_number_id" hidden>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Document Type" name="document_type_id" required rules={[{ required: true }]}>
                                <Select
                                    showSearch
                                    options={documentType}
                                    placeholder="Select Document Type"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                />
                            </Form.Item>
                            <Form.Item label="Document Number" name="document_number">
                                <Input placeholder="Document Number" />
                            </Form.Item>
                        </FormModal> :
                        null
                }
            </Card>
        </div>
    )
}

export default DocumentNumberFormat;
