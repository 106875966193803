import React, { useState, useEffect } from 'react'
import { Typography, Card, Form, Input, Switch, Select, Space, message, Tag } from 'antd'
import { EditButton, DeleteButton, DataTable, PageHeader, Spinner, FormModal } from '../../../components'
import { ApiService } from '../../../services/ApiService'
import { currentUser } from '../../../utils/authData'

const { Option } = Select
const { Text } = Typography

const Pegawai = () => {
    const [loaded, setLoaded] = useState(true)
    // eslint-disable-next-line
    const [employee, setEmployee] = useState([])
    const [directorate, setDirectorate] = useState([])
    const [position, setPosition] = useState([])
    const [role, setRole] = useState([])
    const [modalTitle, setModalTitle] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [isSaving, setIsSaving] = useState(false)
    const [status, setStatus] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const user = currentUser

    // const fetchEmployee = () => {
    //     setLoaded(false);
    //     ApiService.getList('employee').then((res) => {
    //         setEmployee(res?.data)
    //         setLoaded(true)
    //     }).catch((err) => {
    //     })
    // }

    const fetchPosition = () => {
        setLoaded(false);
        ApiService.getList('position').then((res) => {
            setPosition(res.data)
            setLoaded(true)
        }).catch((err) => {
        })
    }

    const fetchDirectorate = () => {
        setLoaded(false);
        ApiService.getList('department').then((res) => {
            setDirectorate(res.data)
            setLoaded(true)
        }).catch((err) => {
        })
    }

    const fetchRole = () => {
        setLoaded(false);
        ApiService.getList('role').then((res) => {
            setRole(res.data)
            setLoaded(true)
        }).catch((err) => {
        })
    }

    const add = () => {
        setModalTitle('Tambah Pegawai')
        setDefaultValues({
            id: 0,
            user_number: '',
            name: '',
            username: '',
            department_id: '',
            position_id: '',
            email: '',
            phone: '',
            address: '',
            role_id: '',
            password: '',
            status: true
        })
        setIsModalVisible(true)
        setStatus(true)
        setDisabled(false)
    }

    const edit = (v) => {
        setModalTitle('Edit Pegawai')
        setDefaultValues({
            id: v.id,
            user_number: v.user_number,
            name: v.name,
            username: v.username,
            department_id: v.department_id,
            position_id: v.position_id,
            email: v.email,
            phone: v.phone,
            address: v.address,
            role_id: v.role_id,
            password: v.password,
            status: parseInt(v.status)
        })
        setIsModalVisible(true)
        setDisabled(true)
        setStatus(v.status)
    }

    const destroy = (id) => {
        ApiService.destroy('employee', id, { user: user.id }).then((res) => {
            message.success(res.message, 2)
            // fetchEmployee()
        }).catch((err) => {
            message.warning(err.message, 2)
        })
    }

    const onFinish = (el) => {
        let payload = {
            ...el,
            status: status ? 1 : 0,
        }

        if (el.id === 0) {
            Object.assign(payload, { created_by: user.id, updated_by: user.id })
            setIsSaving(true)
            ApiService.store(`employee`, payload)
                .then((res) => {
                    message.success(res.message, 2)
                    setIsModalVisible(false)
                    setIsSaving(false)
                    // fetchEmployee()
                }).catch((err) => {
                    if (err.response.data.message === 'err email') {
                        message.warning('Email sudah digunakan, mohon input ulang!')
                        setIsSaving(false)
                    } else if (err.response.data.message === 'err user number') {
                        message.warning('NIP sudah digunakan, mohon input ulang!')
                        setIsSaving(false)
                    } else {
                        message.warning('Username sudah digunakan, mohon input ulang!')
                        setIsSaving(false)
                    }
                })
        } else {
            Object.assign(payload, { updated_by: user.id })
            setIsSaving(true)
            ApiService.update(`employee`, el.id, payload)
                .then((res) => {
                    message.success(res.message, 2)
                    setIsModalVisible(false)
                    setIsSaving(false)
                    // fetchEmployee()
                }).catch((err) => {
                    if (err.response.data.message === 'err email') {
                        message.warning('Email sudah digunakan, mohon input ulang!')
                        setIsSaving(false)
                    } else if (err.response.data.message === 'err user number') {
                        message.warning('NIP sudah digunakan, mohon input ulang!')
                        setIsSaving(false)
                    } else {
                        message.warning('Username sudah digunakan, mohon input ulang!')
                        setIsSaving(false)
                    }
                })
        }
    }

    useEffect(() => {
        // fetchEmployee()
        fetchDirectorate()
        fetchPosition()
        fetchRole()
    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Nama Pegawai',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Departemen',
            render: (row) => (
                row.department?.name
            ),
            key: 'department_name'
        },
        {
            title: 'Jabatan/Position',
            render: (row) => (
                row.position?.name
            ),
            key: 'position_name'
        },
        {
            title: 'Role',
            render: (row) => (
                row.role?.name
            ),
            key: 'role_name'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            align: 'center',
            key: 'status',
            render: (text) => (
                <Tag color={text === 1 ? "green" : "red"}>{(text === 1) ? 'Active' : 'InActive'}</Tag>
            )
        },
        {
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => edit(row)} />
                        <DeleteButton onConfirm={() => destroy(row.id)} />
                    </Space>
                </div>
            )
        },
    ];

    return (
        <div>
            <PageHeader title="Data Pegawai" breadcrumbs={[['Organisasi'], ['Pegawai']]} onAdd={add} />
            <Card style={{ borderRadius: 10 }}>

                {
                    (loaded) ?
                        <DataTable size="small" columns={columns} sources={employee} bordered />
                        : <Spinner />
                }

                {
                    (isModalVisible) ?
                        <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => onFinish(v)} width={600} showModal={isModalVisible} onCloseModal={() => setIsModalVisible(false)} saving={isSaving}>
                            <Form.Item name="id" hidden>
                                <Input />
                            </Form.Item>
                            <Form.Item label="NIP" name="user_number" required rules={[{ required: true }]}>
                                <Input placeholder="NIP" />
                            </Form.Item>
                            <Form.Item label="Nama" name="name" required rules={[{ required: true }]}>
                                <Input placeholder="Nama" />
                            </Form.Item>
                            <Form.Item label="Username" name="username" required rules={[{ required: true }]}>
                                <Input placeholder="Username" />
                            </Form.Item>
                            <Form.Item label="Induk Direktorat" name="department_id" required rules={[{ required: true }]}>
                                <Select placeholder="Induk Direktorat">
                                    {
                                        directorate.map((e, key) => (
                                            <Option key={key} value={e.id}>{e.name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label="Induk Jabatan" name="position_id" required rules={[{ required: true }]}>
                                <Select placeholder="Induk Jabatan">
                                    {
                                        position.map((e, key) => (
                                            <Option key={key} value={e.id}>{e.name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item label="Email" name="email" required rules={[{ required: true }]}>
                                <Input placeholder="Email" />
                            </Form.Item>
                            <Form.Item label="No Hp" name="phone">
                                <Input placeholder="No Hp" rules={[{ type: "number" }]} />
                            </Form.Item>
                            <Form.Item label="Alamat" name="address">
                                <Input.TextArea placeholder='Alamat' />
                            </Form.Item>
                            <Form.Item label="Role" name="role_id" required rules={[{ required: true }]}>
                                <Select placeholder="Role">
                                    {
                                        role.map((e, key) => (
                                            <Option key={key} value={e.id}>{e.name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>

                            {
                                disabled ?
                                    <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
                                        <Input.Password disabled />
                                    </Form.Item> :

                                    <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
                                        <Input.Password />
                                    </Form.Item>
                            }

                            <Form.Item label="Status" name="status" rules={[{ required: true }]}>
                                <Switch checkedChildren="Ya" unCheckedChildren="Tidak" onChange={(v) => setStatus(v)} defaultChecked={defaultValues.status} />
                            </Form.Item>
                            <Text>Inputan dengan tanda (<span style={{ color: 'red' }}>*</span>) wajib diisi</Text>
                        </FormModal> :
                        null
                }
            </Card>
        </div>
    )
}

export default Pegawai
