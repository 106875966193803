import React from 'react'
import { Image } from 'antd'
import IcFolder from '../../assets/images/ic_folder.png'
import IcPdf from '../../assets/images/ic_pdf.png'
import IcWord from '../../assets/images/ic_word.png'
import IcExcel from '../../assets/images/ic_xls.png'
import IcPpt from '../../assets/images/ic_ppt.png'
import IcImage from '../../assets/images/ic_images.png'
// import dayjs from 'dayjs'

export const GenerateFolder = (data) => {
    const treeData = data

    treeData.forEach((el, key) => {
        if (el.type === 'FOLDER') {
            Object.assign(el, {
                icon: <Image preview={false} src={IcFolder} width={20} />,
                title: el.name,
                // key: el.parent === null ? `${dayjs(el.created_at).format('YYYYMMDD-hhmmss')}${key}` : `${el.parent}-${key}${dayjs(el.created_at).format('YYYYMMDD-hhmmss')}`
            })
        }

        if (el.type === 'FILE') {
            if (el.name.split('.')[1] === 'pdf') {
                Object.assign(el, {
                    icon: <Image preview={false} src={IcPdf} width={20} />,
                    title: el.original_filename === null ? `${el.name.split('/')[1].substring(0, 10)}...` : el.original_filename,
                    // key: el.parent === null ? `${dayjs(el.created_at).format('YYYYMMDD-hhmmss')}${key}` : `${el.parent}-${key}${dayjs(el.created_at).format('YYYYMMDD-hhmmss')}`
                })
            }
            if (el.name.split('.')[1] === 'pptx' || el.name.split('.')[1] === 'ppt') {
                Object.assign(el, {
                    icon: <Image preview={false} src={IcPpt} width={20} />,
                    title: el.original_filename === null ? `${el.name.split('/')[1].substring(0, 10)}...` : el.original_filename,
                    // key: el.parent === null ? `${dayjs(el.created_at).format('YYYYMMDD-hhmmss')}${key}` : `${el.parent}-${key}${dayjs(el.created_at).format('YYYYMMDD-hhmmss')}`
                })
            }
            if (el.name.split('.')[1] === 'docx' || el.name.split('.')[1] === 'doc') {
                Object.assign(el, {
                    icon: <Image preview={false} src={IcWord} width={20} />,
                    title: el.original_filename === null ? `${el.name.split('/')[1].substring(0, 10)}...` : el.original_filename,
                    // key: el.parent === null ? `${dayjs(el.created_at).format('YYYYMMDD-hhmmss')}${key}` : `${el.parent}-${key}${dayjs(el.created_at).format('YYYYMMDD-hhmmss')}`
                })
            }
            if (el.name.split('.')[1] === 'xlsx' || el.name.split('.')[1] === 'xls') {
                Object.assign(el, {
                    icon: <Image preview={false} src={IcExcel} width={20} />,
                    title: el.original_filename === null ? `${el.name.split('/')[1].substring(0, 10)}...` : el.original_filename,
                    // key: el.parent === null ? `${dayjs(el.created_at).format('YYYYMMDD-hhmmss')}${key}` : `${el.parent}-${key}${dayjs(el.created_at).format('YYYYMMDD-hhmmss')}`
                })
            }
            if (el.name.split('.')[1] === 'jpg' || el.name.split('.')[1] === 'jpeg' || el.name.split('.')[1] === 'png') {
                Object.assign(el, {
                    icon: <Image preview={false} src={IcImage} width={20} />,
                    title: el.original_filename === null ? `${el.name.split('/')[1].substring(0, 10)}...` : el.original_filename,
                    // key: el.parent === null ? `${dayjs(el.created_at).format('YYYYMMDD-hhmmss')}${key}` : `${el.parent}-${key}${dayjs(el.created_at).format('YYYYMMDD-hhmmss')}`
                })
            }
        }
    });

    return treeData;
}