import {
    CheckCircle,
    Circle,
    Database,
    FileText,
    Home,
    User,
    Server,
    Sliders,
    File
} from 'react-feather';

export const homeMenu = [
    {
        name: 'Home',
        icon: <Home strokeWidth={2} size={18} style={{ color: '#fff' }} />,
        path: '/',
        role: ["USER", "SUPERADMIN"]
    },
]

export const documentWorkflowMenu = [
    {
        name: 'Document Workflow',
        icon: <FileText strokeWidth={2} size={18} style={{ color: '#fff' }} />,
        children: [
            {
                icon: <Circle strokeWidth={2} size={12} style={{ color: '#fff' }} />,
                path: '/document-workflow/internal-memo',
                name: 'Memo Internal',
                role: ['SUPERADMIN', 'ADMIN', 'USER']
            },
            {
                icon: <Circle strokeWidth={2} size={12} style={{ color: '#fff' }} />,
                path: '/document-workflow/outgoing-letter',
                name: 'Outgoing Letter',
                role: ['SUPERADMIN', 'ADMIN', 'USER']
            },
        ],
        key: 'menu-document-workflow'
    }
]

export const documentManagementMenu = [
    {
        name: 'Document Management',
        icon: <Server strokeWidth={2} size={18} style={{ color: '#fff' }} />,
        path: '/file-manager',
        role: ["USER", "SUPERADMIN"]
    },
]

export const manageDocumentSharedMenu = [
    {
        name: 'Manage Document Shared',
        icon: <Sliders strokeWidth={2} size={18} style={{ color: '#fff' }} />,
        path: '/shared-document',
        role: ["USER", "SUPERADMIN"]
    },
]

export const organizationMenu = [
    {
        name: 'Organisasi',
        icon: <User strokeWidth={2} size={18} style={{ color: '#fff' }} />,
        children: [
            {
                icon: <Circle strokeWidth={2} size={14} style={{ color: '#fff' }} />,
                path: '/organisasi/division',
                name: 'Divisi',
                role: ['SUPERADMIN']
            },
            {
                icon: <Circle strokeWidth={2} size={14} style={{ color: '#fff' }} />,
                path: '/organisasi/position',
                name: 'Posisi',
                role: ['SUPERADMIN']
            },
            {
                icon: <Circle strokeWidth={2} size={14} style={{ color: '#fff' }} />,
                path: '/organisasi/pegawai',
                name: 'Pegawai',
                role: ['SUPERADMIN']
            },
        ]
    },
]

export const masterMenu = [
    {
        name: 'Master',
        icon: <Database strokeWidth={2} size={18} style={{ color: '#fff' }} />,
        children: [
            {
                icon: <Circle strokeWidth={2} size={14} style={{ color: '#fff' }} />,
                path: '/master/archive-location',
                name: 'Archive Location',
                role: ['SUPERADMIN']
            },
            {
                icon: <Circle strokeWidth={2} size={14} style={{ color: '#fff' }} />,
                path: '/master/document-type',
                name: 'Document Type',
                role: ['SUPERADMIN']
            },
            {
                icon: <Circle strokeWidth={2} size={14} style={{ color: '#fff' }} />,
                path: '/master/template-document',
                name: 'Document Template',
                role: ['SUPERADMIN']
            },
            {
                icon: <Circle strokeWidth={2} size={14} style={{ color: '#fff' }} />,
                path: '/master/document-number',
                name: 'Document Number',
                role: ['SUPERADMIN']
            },
            // {
            //     icon: <Circle strokeWidth={2} size={14} style={{ color: '#fff' }} />,
            //     path: '/master/folder',
            //     name: 'OCR Folder',
            //     role: ['SUPERADMIN']
            // },
            // {
            //     icon: <Circle strokeWidth={2} size={14} style={{ color: '#fff' }} />,
            //     path: '/master/file-manager',
            //     name: 'File Manager',
            //     role: ['SUPERADMIN']
            // },
        ]
    },
]

export const approvalCenterMenu = [
    {
        name: 'Approval Center',
        icon: <CheckCircle strokeWidth={2} size={12} style={{ color: '#fff' }} />,
        path: '/approval-center',
        role: ['SUPERADMIN', 'ADMIN', 'USER'],
        key: 'menu-approval-center'
    }
]

export const documentAuditTrailMenu = [
    {
        name: 'Document Audit Logs',
        icon: <File strokeWidth={2} size={18} style={{ color: '#fff' }} />,
        path: '/document-audit-trail',
        role: ['SUPERADMIN', 'ADMIN', 'USER'],
        key: 'menu-document-audit-trail'
    }
]