import { Button, Form, Input, message } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { AuthService } from '../../services/AuthService'
import Illustration from '../../assets/images/doc-cuate.png'
import SmailLogo from '../../assets/icon/logo-smail.svg'
import './style/login.css'

const Login = () => {
    const onFinish = (v) => {
        message.loading('authenticating', 0)
        AuthService.auth(v.username, v.password)
            .then()
            .catch((err) => {
                message.destroy()
                message.error('Invalid credentials', 2)
            })
    }

    return (
        <div className="login-body">
            <div className="login-body-container">
                <div className="section login-side">
                    <img className="login-image-ilustration" src={Illustration} alt="ilustration" />
                </div>
                <div className="section login-main">
                    <div className="login-container">
                        <img className="login-image-logo" src={SmailLogo} alt="dms-logo" />
                        <p className="login-title">Welcome</p>
                        <div className="login-separator"></div>
                        <p className="login-welcome-message">SMAIL makes it easy for you directly connected to documents.</p>

                        <Form className="login-form" onFinish={onFinish}>
                            <Form.Item name="username" rules={[{ required: true, message: 'Please input your username' }]}>
                                <Input prefix={
                                    <UserOutlined />
                                } placeholder="Username" style={{ borderRadius: 15 }} size="large" />
                            </Form.Item>
                            <Form.Item name="password" rules={[{ required: true, message: 'Please input your password' }]} >
                                <Input.Password prefix={
                                    <LockOutlined />
                                } type="password" placeholder="Password" style={{ borderRadius: 15 }} size="large" />
                            </Form.Item>
                            <Button className="button-login" htmlType='submit'>Login</Button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login